import "./StartEndDate.scss";
import { useEffect, useState, useContext } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import { DataContext } from "../../../context/DataContext";

export default function StartEndDate({ setstartDate, setendDate }) {
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [primaryColor] = useContext(DataContext);
  const [currentDate, setCurrentDate] = useState(moment(new Date()).format("MM-DD-YYYY"));
  const range = {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
    "Last 7 Days": [moment().subtract(6, "days"), moment()],
    "Last 30 Days": [moment().subtract(29, "days"), moment()],
    "This Month": [moment().startOf("month"), moment().endOf("month")],
    "Last Month": [
      moment().subtract(1, "month").startOf("month"),
      moment().subtract(1, "month").endOf("month"),
    ],
    "Last Year": [
      moment().subtract(1, "year").startOf("year"),
      moment().subtract(1, "year").endOf("year"),
    ],
  };

  useEffect(() => {
    var startDateFormat = moment(fromDate).format("YYYY/MM/DD");
    var endDateFormat = moment(toDate).format("YYYY/MM/DD");
    setstartDate(startDateFormat);
    setendDate(endDateFormat);
  }, [fromDate, toDate]);

  const handleEvent = (event, picker) => {
    setFromDate(picker.startDate._d.toISOString());
    setToDate(picker.endDate._d.toISOString());
  };
  // let current = new Date()

  useEffect(() => {
    document.body.style.setProperty("--themeColorStartDate", primaryColor);
  }, [primaryColor]);

  return (
    <div>
      <DateRangePicker
        ranges={range}
        onEvent={handleEvent}
        autoApply={true}
        alwaysShowCalendars={true}
        maxDate = {currentDate}
        initialSettings= {{}}
      >
        <span className="datapicker__buttonData">
          {moment(fromDate).format("LL")} - {moment(toDate).format("LL")}
        </span>
      </DateRangePicker>
    </div>
  );
}
