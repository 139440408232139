import { combineReducers } from "redux";
import UpadateDashboard from "./UpadateDashboard";
import GetSingleProject from "./GetSingleProject";
import ProjectSlice from "./ProjectSlice";
import UserSlice from "./UserSlice";
import ClientSlice from "./ClientSlice";
import holidaySlice from "./holidaySlice";
import dashboardSlice from "./dashboardSlice";
import logoutSlice from "./logoutSlice";
const RootReducer = combineReducers({
  UpadateDashboard: UpadateDashboard,
  GetSingleProject: GetSingleProject,
  projects : ProjectSlice,
  users : UserSlice,
  clients : ClientSlice,
  holidays : holidaySlice,
  dashboards : dashboardSlice,
  logout : logoutSlice
  
});

export default RootReducer;
