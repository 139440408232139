import React, { useEffect, useState, useContext } from "react";
import "./Holiday.scss";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
import useApi from "../services/api";
import LoadingBar from "react-top-loading-bar";
import { useNavigate } from "react-router-dom";
import { ColorContext } from "../../context/ColorContext";
import { DataContext } from "../../context/DataContext";
import { useDispatch, useSelector } from "react-redux";
import { getAllHoliday } from "../../Reducers/holidaySlice";

function Holiday() {
  const [mark, setMark] = useState([]);
  const api = useApi();
  const [holidayData, setHolidayData] = useState([]);
  const [progress, setProgress] = useState(0);
  const history = useNavigate();
  const [secondaryColor] = useContext(ColorContext);
  const [primaryColor] = useContext(DataContext);
  const dispatch = useDispatch()
  
  const holidayStateData = useSelector((state)=>{
    return state.holidays.holidays
  })

  useEffect(()=>{
    dispatch(getAllHoliday())
  }, [])
  useEffect(() => {
    // console.log(holidayStateData, "holiday state")
    setProgress(80);
    let holidays = holidayStateData.map((data) => {
      return moment(data.holidayDate).format("DD-MM-YYYY");
    });
    holidayStateData.map((data) => {
      const date = data.holidayDate;
      const name = data.holidayName;
      const restricted = data.restricted;
      const yearly = data.isYearly
      const obj = {
        name : name,
        date : date,
        restricted : restricted,
        yearly : yearly
      }
      // console.log(obj, "obj")
      const xyz = holidayData;
      holidayData.push(obj)
      setHolidayData(holidayData)
      // return data.holidayName
    });
    // setHolidayData({
    //   holidayDate : holidays,
    //   holidaysNames : holidaysName
    // })
    // console.log(holidaysName, "name")
    setMark(holidays);
    setProgress(100);
    // const workspaceId = localStorage.getItem("workspace_id")
    // api.get("v1/holiday/").then(
    // api.get(`https://api.trackify.ai/holiday/getHoliday/${workspaceId}`).then(
    //   (response) => {
    //     // setHolidayData(response)
    //     // console.log(response, "response")
    //     let holidays = holidayStateData.map((data) => {
    //       return moment(data.holidayDate).format("DD-MM-YYYY");
    //     });
    //     holidayStateData.map((data) => {
    //       const date = data.holidayDate;
    //       const name = data.holidayName;
    //       const restricted = data.restricted;
    //       const yearly = data.isYearly
    //       const obj = {
    //         name : name,
    //         date : date,
    //         restricted : restricted,
    //         yearly : yearly
    //       }
    //       // console.log(obj, "obj")
    //       const xyz = holidayData;
    //       holidayData.push(obj)
    //       setHolidayData(holidayData)
    //       // return data.holidayName
    //     });
    //     // setHolidayData({
    //     //   holidayDate : holidays,
    //     //   holidaysNames : holidaysName
    //     // })
    //     // console.log(holidaysName, "name")
    //     setMark(holidays);
    //     setProgress(100);
    //   },
    //   (error) => {
    //     console.log(error, "error");
    //     setProgress(100);
    //     if (error.response.status === 401) {
    //       window.localStorage.clear();
    //       history("/");
    //     }
    //   }
    // );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [holidayStateData]);
  let selectedMonth = null; // December (months are zero-based)
  let selectedDay = null; 
  const getHolidayName = (data, date)=>{
    // console.log(data,date.getDate(),date.getMonth(), "hey")
    // console.log(holidayData, "newData");
    // let isYearly = false;
    // let hey = moment(data).format();
    // holidayData.map((el)=>{
    //   // console.log(moment(el.date).format("DD-MM-YYYY"), "el")
      
    //   const newnew = moment(el.date).format("DD-MM-YYYY")
    //   if(newnew == data && el.yearly == 1 ){
    //     isYearly = true
    //     console.log(data)
    //     const newArr = data.split("-")
    //     selectedMonth = newArr[1]-1
    //     selectedDay = Number(newArr[0])
    //     console.log(selectedDay, selectedMonth)
    //   }
      
    // })
    // // console.log(isYearly, holidayData, data)

    // // const newArray = holidayData.map((el)=>{
      

    // // })
    // // console.log(newArray, "heyonew")

    // const isSelected = date.getMonth() === selectedMonth && date.getDate() === selectedDay;

    let HOname = ""
    let restrict = false
    holidayData.map((el)=>{
      // console.log(el, data, "heyyyyyyeyeye")
      // console.log(moment(el.date).getFullYear(), "helo el")
      if( moment(el.date).format("DD-MM-YYYY") === data && el.restricted == "1"){
        restrict = true;
        HOname = el.name
        return String(el.name)
      }else if( moment(el.date).format("DD-MM-YYYY") === data && el.restricted == "0" ){
        // console.log("first did it")
        // console.log(el.name, "nameee")
        HOname = el.name
        return String(el.name)
      }
      // else if(isSelected && el.restricted == "0"){
      //   HOname = el.name
      //   return String(el.name)
      // }else if (isSelected && el.restricted == "1"){
      //   restrict = true;
      //   HOname = el.name
      //   return String(el.name)
      // }
    })
    // console.log(holidayData, "this is")
    return HOname != "" && !restrict ? <div>
      <p style={{marginTop:"10px", marginBottom:"-15px"}} >{HOname}</p>
      {/* <span>ResTricted</span> */}
    </div> : HOname != "" && restrict ? <div>
    <p style={{marginTop:"10px", marginBottom:"-15px"}} >{HOname}</p>
    <p style={{marginTop:"15px", marginBottom:"-15px"}}>(Restricted)</p>
    </div> : "yes"
  }

  useEffect(() => {
    document.body.style.setProperty("--themeColor", primaryColor);
    // console.log(holidayData, "data holi")
  }, [primaryColor]);

  return (
    <div className="holiday">
      <h1 className="holiday__heading" style={{ color: `${secondaryColor}` }}>
        Holiday Calendar
      </h1>
      <Calendar

      // tileContent = {({date})=>{
      //   const isSelected = mark.some((d) => d.getTime() === date.getTime());
      //   if (isSelected) {
      //     // Display a custom content for selected holiday dates
      //     return <span style={{ color: 'red' }}>Holiday</span>;
      //   }

      //   return null

      // }}
        
        tileClassName={({ date, view }) => {
          if (mark.find((x) => x === moment(date).format("DD-MM-YYYY"))) {
            // tileContent = "hey"
            return "highlight";
          }else if(mark.find((x) => x === moment(date).format("DD-MM-YYYY"))){
            return "highlight";
          }
        }}
        
        tileContent = {({ activeStartDate, date, view }) => 
        // mark?.find((x) => x === moment(date).format("DD-MM-YYYY"))? view : null 
        mark.map((el)=>el == moment(date).format("DD-MM-YYYY") ? getHolidayName(moment(date).format("DD-MM-YYYY"),date) : null)
      }
        className="calender_onHoliday"
      />

      <LoadingBar
        color="black"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
        loaderSpeed={3000}
        waitingTime={1000}
      />
    </div>
  );
}

export default Holiday;
