import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// const workspaceID = localStorage.getItem("workspace_id")
const token = localStorage.getItem("access_token");
// const userId = JSON.parse(localStorage.getItem("tt-userinfo"))
// const {_id} = userId
export const timerEditor = createAsyncThunk(
  "dashboard/timerEditor",
  async (name, thunkapi) => {
    try {
      const res = await axios.post(
        "https://api.trackify.ai/setting/timeEditorStatus",
        {
          workspaceId: localStorage.getItem("workspace_id"),
        }
      );
      // console.log(res, " res in redux timer");
      return res.data;
    } catch (e) {
      return thunkapi.rejectWithValue(e.message);
    }
  }
);

export const activateTimerEditor = createAsyncThunk(
  "dashboard/activateTimeEditor",
  async (name, thunkapi) => {
    try {
      const res = await axios.post(
        "https://api.trackify.ai/setting/activateTimeEditor",
        {
          workspaceId: localStorage.getItem("workspace_id"),
        }
      );
      return res.data;
    } catch (error) {
      return thunkapi.rejectWithValue(error.message);
    }
  }
);
export const deactivateTimerEditor = createAsyncThunk(
  "dashboard/deactivateTimeEditor",
  async (name, thunkapi) => {
    try {
      const res = await axios.post(
        "https://api.trackify.ai/setting/deactivateTimeEditor",
        {
          workspaceId: localStorage.getItem("workspace_id"),
        }
      );
      return res.data;
    } catch (error) {
      return thunkapi.rejectWithValue(error.message);
    }
  }
);

export const getTheme = createAsyncThunk(
  "dashboard/getTheme",
  async (name, thunkapi) => {
    try {
      const workspace_id = localStorage.getItem("workspace_id");
      // console.log(workspace_id, "here i am stuck");
      const res = await axios.get(
        `https://api.trackify.ai/color/colorsetting/${workspace_id}`
      );
      // console.log(res, "resInGetTheme");
      const data = { data : res.data[0], id:workspace_id }
      return data;
    } catch (error) {
      return thunkapi.rejectWithValue(error.message);
    }
  }
);
export const updateTheme = createAsyncThunk(
  "dashboard/updateTheme",
  async (id, thunkapi) => {
    try {
      const workspace_id = localStorage.getItem("workspace_id");
      const res = await axios.put("https://api.trackify.ai/color/colorsetting", {
        id: id,
        // id: window.localStorage.getItem("themeIDApi"),
        wkspcID: workspace_id,
      });
      // console.log(res.data, "tap");
      return {data : res.data, id:workspace_id}
      // return res.data;
    } catch (error) {
      return thunkapi.rejectWithValue(error.message);
    }
  }
);

export const getEntries = createAsyncThunk(
  "dashboard/getEntries",
  async (name, thunkapi) => {
    try {
      const workspaceIdFromLocal = localStorage.getItem("workspace_id");
      const user = JSON.parse(localStorage.getItem("tt-userinfo"));
      const { _id } = user;
      const token = localStorage.getItem("access_token");
      // console.log(_id, "user");
      // console.log(workspaceIdFromLocal, "IDD");
       const res = await axios.get(
        `https://api.trackify.ai/timer/${workspaceIdFromLocal}/${_id}/${token}`
      );
      return res.data
    } catch (error) {
      return thunkapi.rejectWithValue(error.message);
    }
  }
);

const initialState = {
  timerStatus: null,
  theme: [],
  timerEntries : [],
  test : {}
};

const dashboardSlice = createSlice({
  name: "dashboardSlice",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(timerEditor.fulfilled, (state, action) => {
      state.timerStatus = action.payload.editable;
    });
    builder.addCase(activateTimerEditor.fulfilled, (state, action) => {
      state.timerStatus = action.payload.editable;
    });
    builder.addCase(deactivateTimerEditor.fulfilled, (state, action) => {
      state.timerStatus = action.payload.editable;
    });
    builder.addCase(getTheme.fulfilled, (state, action) => {
      // state.theme = action.payload;
      const workspace_id = action.payload.id
            let existing = false;
            state.theme.map((el)=>{
              if(el.id == workspace_id){
                existing = true
              }
            })
                if(!existing){
                  const {id, data} = action.payload
                  state.theme = [...state.theme, action.payload]
              state.test[id] = data
            }
    });
    builder.addCase(updateTheme.fulfilled, (state, action) => {
      // state.theme = action.payload;
      const {data, id} = action.payload
      state.test[id] = data
    });
    builder.addCase(getEntries.fulfilled, (state, action)=>{
        state.timerEntries = action.payload
    })

    // builder.addCase(getAllUser.fulfilled, (state, action)=>{
    //     state.users = action.payload
    //     state.allUsers = [{name: "Select All",
    //     userID: "all selected!"}, ...action.payload]
    // })
  },
});

// console.log(dashboardSlice.actions);
// export {projects}
export default dashboardSlice.reducer;

// export const {addProject} = projectSlice.actions
