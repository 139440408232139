import React, { useState, useEffect, useContext } from "react";
import "./Clients.scss";
import AddClient from "../../Views/clients/AddClients/AddClient";
import UpdateClient from "../../Views/clients/UpdateClient/UpdateClient";
import useApi from "../services/api";
import LoadingBar from "react-top-loading-bar";
// import { clientType } from "./Clients.types";
import { useNavigate } from "react-router-dom";
import { ColorContext } from "../../context/ColorContext";
import { useDispatch, useSelector } from "react-redux";
import { getAllClient } from "../../Reducers/ClientSlice";

const Clients = () => {
  const [clients, setClients] = useState([]);
  const [progress, setProgress] = useState(0);
  const [secondaryColor, setSecondaryColor] = useContext(ColorContext);
  const [wkspc_id, setworkspaceID] = useState("");
  const [applyClientCss, setApplyClientCss] = useState(false);
  const [isHeader, setIsHeader] = useState(false);
  const api = useApi();
  const history = useNavigate();
  const dispatch = useDispatch();

  // console.log(wkspc_id, "wkkk")
  let clientStateData = useSelector((state) => {
    // return state.clients.clients
    return state.clients.test[localStorage.getItem("workspace_id")];
  });

  const ID = localStorage.getItem("workspace_id");
  // console.log(ID, "ID")

  const getAllClients = (data) => {
    setProgress(80);
    setClients([]);
    const token = localStorage.getItem("access_token");
    // console.log(token, "token in clients")
    api
      .post("https://api.trackify.ai/client/clientsdata", {
        wkspc_id: data,
        token,
      })
      .then(
        (response) => {
          // console.log(response, "response")
          if (response.data) {
            setClients(response.data);
          }
          setProgress(100);
        },
        (error) => {
          if (error.response.status === 401) {
            window.localStorage.clear();
            history("/");
          }
          // console.log(error, "error")
          setProgress(100);
        }
      );
  };

  useEffect(() => {
    if (clientStateData && clientStateData.length !== 0) {
      setClients(clientStateData);
      // console.log("came here as data")
    } else {
      // setworkspaceID(localStorage.getItem("workspace_id"))
      // getAllClients(localStorage.getItem("workspace_id"));
      dispatch(getAllClient());
      // console.log(clientStateData, "clientStateData")
    }
  }, [clientStateData]);
  const setValueAsEmpty = ()=>{
    // let data =   document.getElementById("demo-multiple-checkbox")
    // if(selectedClient === "ADD CLIENT"){
    //   data.innerText = ""
      // console.log("Select Client")
    }

  return (
    <div className="clients">
      <div className="d-flex justify-content-between">
        <div className="box-header">
          <div className="page-title" style={{ color: `${secondaryColor}` }}>
            Clients
          </div>
        </div>
        <AddClient
          setProgress={setProgress}
          isHeader={isHeader}
          applyClientCss={applyClientCss}
          clients={clients}
          getAllClients={getAllClients}
          setValueAsEmpty={setValueAsEmpty}
        />
      </div>

      <div className="table-content">
        <table className="table">
          <tbody>
            <tr>
              <th scope="col">Client Name</th>
              <th scope="col" className="text-center">
                Created On
              </th>
              <th scope="col" className="text-center">
                Action
              </th>
            </tr>
            {clients &&
              clients.map((client, index) => (
                <tr key={index}>
                  <td>{client.clientName}</td>
                  <td className="text-center">{client.createdAt}</td>
                  <td className="text-center">
                    <UpdateClient
                      client={client}
                      setProgress={setProgress}
                      getAllClients={getAllClients}
                      clients={clients}
                    />
                  </td>
                </tr>
              ))}
            {clients.length === 0 && (
              <tr className="no-record-found">
                <td align="center" colSpan={6}>
                  No Record Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <LoadingBar
        color="black"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
        loaderSpeed={3000}
        waitingTime={1000}
      />
    </div>
  );
};

export default Clients;
