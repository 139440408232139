import React, { useState, useEffect } from "react";
import "./Login.css";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { Email, Lock } from "@mui/icons-material";
import Logo from "../../assets/images/logo/logo.png"
// import { Signup } from "../Signup/Signup";

function Login() {
  const [isLoginFailed, setIsLoginFailed] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const history = useNavigate();
  const [isError, setIsError] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [loading, setLoading] = useState(false)

  const token = window.localStorage.getItem("access_token");

  const item = {
    email,
    password,
  };

  const handleLogin = (e) => {
    e.preventDefault();
    setIsError(false)
    // console.log(item, "item")
    if(item.email && item.password){
      setLoading(true)
      fetch("https://api.trackify.ai/user/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(item),
      })
        .then((response) => response.json())
        .then((result) => {
          // console.log(result, "result");
          if (result.status === "success") {
            localStorage.setItem("workspace", JSON.stringify(result.data.workspace))
            localStorage.setItem("workspace_id",result.data.workspace[0].wkspc_id)
            localStorage.setItem("tt-userinfo", JSON.stringify(result.data));
            window.localStorage.setItem("access_token", result.token);
            window.localStorage.setItem("startTimer", "false")
            setLoading(false)
            history("/dashboard");
            // console.log(result)
          } else {
            // console.log(result, "result")
            setLoading(false)
            setIsError(true)
            seterrorMessage("Invalid credentials.")
            // alert(result.status)
            setIsLoginFailed(true);
          }
        })
        .catch((error) => {
          setLoading(false)
          // console.error("Error:", error);
          // console.log("error came")
        });
    }else{
      setIsError(true);
      seterrorMessage("Email and Password is mandatory.")
      // alert("Email and Password is mandatory")
    }
  };

  useEffect(() => {
    if (token) {
      history("/dashboard");
    } else {
      // console.log("token expired");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="loginPage"  >
      <div className="leftDivLogin"  >
      <div style={{position:"absolute", top:"30px", left:"30px" }} >
        <img src={Logo} style={{width:"10%"}} alt="" />
        </div>
        <div className="craeteHeaderLogin" >Log in</div>

        <div className="errorDivLogin" >
          {isError ? <p>{errorMessage}</p> : <h6>.</h6>}
        </div>  

        <div className="inputDivLogin" >
          <span style={{border:isError ? "3px solid red" : "none"}} className="InputBoxLogin" >
            <div className="Cirlce" ><Email/></div>
            <input placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
            className="InputBox2Login" type="email" name="" id="" />
          </span>
          <span style={{border:isError ? "3px solid red" : "none"}} className="InputBoxLogin" >
            <div className="Cirlce" ><Lock/></div>
            <input placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
            className="InputBox2Login" type="password" name="" id="" />
          </span>
        </div>
        <div className="forgotDivLogin" >
        <a href="/#/forgot" className="forgotAtagLogin" >Forgot Password?</a>
        </div>
        <div className="loginButton" onClick={handleLogin} >
          {loading ? <CircularProgress color="inherit" /> : "Log in"}
        </div>
        <div className="loginOption" >New On Trackify ? <a className="loginTag" href="/#/signup">Sign Up</a></div>
      </div>
      <div className="rightDiv1Login">
        {/* <div>hey</div> */}
        <div className="centerDivLogin" >
        <div className="welcomeHeadingLogin" >Create Account</div>
        <div className="dialogeHeadingLogin" >Track and Manage your time on one platform </div>
        <div className="signinButtonLogin" onClick={()=>{history("/signup")}} ><a className="aTag" href="/#/signup">Sign Up</a></div>
        </div>
      </div>
      {/* hey whatsup */}
    </div>
    // <div className="login">
    //   <div className="login__data">
    //     <h1 className="form-signin-heading heading" >
    //     <span className="pre-head">Time</span>
    //       <span className="login__headingHead">Tracker</span>
    //     </h1>
    //     <h3 className="form-signin-heading">
    //       <span className="login__headingHead">Login</span>
    //       <span className="login__headingHead"> &nbsp;Page</span>
    //     </h3>
    //     {isLoginFailed && (
    //       <>
    //         <div className="alert  alert-danger">Login Failed</div>
    //       </>
    //     )}

    //     <form className="login__form" onSubmit={handleLogin}>
    //       <input
    //         type="email"
    //         className="form-input"
    //         id="email"
    //         placeholder="Email"
    //         onChange={(e) => setEmail(e.target.value)}
    //       />
    //       <input
    //         type="password"
    //         className="form-input"
    //         id="password"
    //         placeholder="Password"
    //         onChange={(e) => setPassword(e.target.value)}
    //       />
    //       <button className="login__button" type="submit">
    //         {loading ? <CircularProgress color="inherit" /> : "Log in"}
    //       </button>
    //       {/* <Signup/> */}
    //     </form>
    //     <div className="already-user" >
    //         <p>Not an user? , </p> <span className="a-tag" >
    //             <a className="a-tag" href="/#/signup">&nbsp; Sign Up</a></span>
    //     </div>
    //     <div className="already-user" >
    //        <span className="a-tag" >
    //             <a className="a-tag" href="/#/forgot">&nbsp;Forgot Password ?  </a></span>
    //     </div>
    //   </div>
    // </div>
  );
}

export default Login;
