import React, { useState, useEffect } from "react";
import Popover from "@mui/material/Popover";
import $ from "jquery";

const UserMenu = ({ userList, setgetAllUser, getAllUser }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [list, setList] = useState([]);
  const [checkList, setcheckList] = useState([]);
  const [selectUser, setselectUser] = useState(false);
  const open = Boolean(anchorEl);
  const [inputValue, setinputValue] = useState("");
  const id = open ? "simple-popover" : undefined;
  const [allChecked, setAllChecked] = useState(false)
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    userList.forEach((element) => {
      element.check = false;
    });
    setcheckList(userList);
    setList(userList);
  }, [userList]);

  const filterData = (value) => {
    const lowercasedValue = value.toLowerCase().trim();

    const filteredData = userList.filter((item) => {
      return Object.keys(item).some((key) =>
        item[key].toString().toLowerCase().includes(lowercasedValue)
      );
    });

    setList(() => {
      return [...filteredData];
    });
  };

  useEffect(() => {
    if (selectUser === true) {
      // $(".userCheck").prop("disabled", true);
      checkList.forEach((element) => {
        element.check = true;
      });

      setcheckList(() => {
        return [...checkList];
      });
      const newList = list.map((client) => {
        return client._id;
      });
      setgetAllUser(newList);
    } else {
      // $(".userCheck").prop("disabled", false);

      let flag = true
      // console.log(checkList,"xyz")
      checkList.forEach((element)=>{
        if(element.check == false){
          flag = false
        }
      })

      if(flag){
        checkList.forEach((element) => {
          element.check = false;
        });
  
        setcheckList(() => {
          return [...checkList];
        });
        setgetAllUser([]);
      }else if(getAllUser.length == 0){
        const newList =  checkList.filter((el)=>{
          return el.check === true
        })
        // console.log(newList, "after")
        const data = newList.map((user) => {
          return user._id;
        });
        setgetAllUser(data);
      }


    }
  }, [selectUser, list, setgetAllUser]);

  useEffect(() => {
    if (getAllUser.length >= 1) {
      setinputValue(`${getAllUser.length} Selected`);
    } else if (getAllUser.length === 0) {
      filterData("");
      setinputValue("");
    }
  }, [getAllUser.length]);

  return (
    <div className="w-100 me-3">
      <div className="inner-Input">
        <input
          type="text"
          className="form-control"
          value={inputValue}
          placeholder={"Select User"}
          aria-describedby={id}
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
          }}
          onChange={(event) => {
            if (getAllUser.length <= 0) {
              filterData(event.target.value);
              setinputValue(event.target.value);
            }
          }}
        />
        <i className="fas fa-caret-down"></i>
      </div>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        disableAutoFocus={true}
        disableEnforceFocus={true}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        className="devSearch"
      >
        <ul className="project-list">
          <li>
            <label className="container">
              Select All
              <input
                type="checkbox"
                onChange={() => {
                  setselectUser(!selectUser);
                  setAllChecked(!allChecked)
                }}
                checked= {allChecked}
              />
              <span className="checkmark"></span>
            </label>
          </li>
          {list.map((list, index) => (
            <li key={index}>
              <label className="container m-0">
                {list.name}
                <input
                  type="checkbox"
                  className="userCheck"
                  checked={checkList[index].check}
                  onChange={(e) => {
                    if (e.target.checked === true) {
                      setgetAllUser((prev) => {
                        return [...prev, list._id];
                      });
                      if(getAllUser.length === userList.length-1){
                        setAllChecked(true)
                        setselectUser(true);
                      }
                      checkList[index].check = !checkList[index].check;
                      setcheckList(() => {
                        return [...checkList];
                      });
                    } else if (e.target.checked === false) {
                      var listArr = [...getAllUser];
                      var indexVal = listArr.indexOf(list._id);
                      listArr.splice(indexVal, 1);
                      setgetAllUser(listArr);
                      if(listArr.length === 0){
                        setAllChecked(false)
                        setselectUser(false);
                      }else{
                        setAllChecked(false)
                        setselectUser(false)
                      }
                      checkList[index].check = !checkList[index].check;
                      setcheckList(() => {
                        return [...checkList];
                      });
                    }
                  }}
                />
                <span className="checkmark"></span>
              </label>
            </li>
          ))}
        </ul>
      </Popover>
    </div>
  );
};

export default UserMenu;
