import React, { useState, useEffect, useContext } from "react";
import "./Profile.css";
import useApi from "../services/api";
import LoadingBar from "react-top-loading-bar";
import { useNavigate } from "react-router-dom";
import { ColorContext } from "../../context/ColorContext";

export default function Profile() {
  const api = useApi();

  const [progress, setProgress] = useState(0);
  const [showProfile, setShowProfile] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showAlert, setAlert] = useState(false);
  const [showPassAlert, setPassAlert] = useState(false);
  const [Name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [oldPass, setOldPassword] = useState("");
  const [newPass, setNewPassword] = useState("");
  const [cnfPass, setConfirmPassword] = useState("");
  const [warnError, setwarnError] = useState("");
  const history = useNavigate();
  const [secondaryColor] = useContext(ColorContext);

  const changePasswordData = {
    oldPass,
    newPass,
    cnfPass,
  };

  useEffect(() => {
    setProgress(80);

    const user = JSON.parse(localStorage.getItem("tt-userinfo"))
    const {_id} = user
    const token  = localStorage.getItem("access_token")
    // console.log(token, "token")

    api.get(`https://api.trackify.ai/profile/getprofile/${_id}/${token}`).then(
      (response) => {
        // console.log(response);
        setName(response.data[0].name);
        setEmail(response.data[0].email);
        setRole(response.data[0].role);
        setProgress(100);
      },
      (error) => {
        console.log(error);
        setProgress(100);
        if (error.response.status === 401) {
          window.localStorage.clear();
          history("/");
        }
      }
    );
  }, []);

  function handleChangePassword(e) {
    e.preventDefault();
    // console.log(e)
    setProgress(80);
    document.querySelector(".one").value = ""
    document.querySelector(".two").value = ""
    document.querySelector(".three").value = ""
    if(oldPass && newPass && cnfPass && newPass === cnfPass && newPass.length >= 6 ){
      const user = JSON.parse(localStorage.getItem("tt-userinfo"))
    const {_id} = user
    const token  = localStorage.getItem("access_token")
    api
      .patch(`https://api.trackify.ai/profile/changePassword/${token}`, {
        oldPass: oldPass,
        newPass: newPass,
        cnfPass: cnfPass,
        _id
      })
      .then(
        (response) => {
          if(response.data === "Password changed"){
            // console.log(response, "resolve")
            setwarnError("");
            setPassAlert(true);
            setProgress(100);
          }else{
            setProgress(100)
            setPassAlert(false)
            // console.log(response.data)
            if(response.data == "Password has not been changed"){
              setwarnError("The old password you entered is not correct.");
            }
          }
        },
        (error) => {
          // console.log(error, "error");
          setProgress(100);
          setPassAlert(false);
          // console.log(error.response.data.error)
          setwarnError(error.response.data.error);
          if (error.response.status === 401) {
            window.localStorage.clear();
            history("/");
          }
        }
      );
    }else if(!(newPass && oldPass && cnfPass)){
      setProgress(100)
      setPassAlert(false)
      setwarnError("All fields need to be filled.");
    }
    else if(newPass.length < 6){
      setProgress(100);
      setPassAlert(false)
      setwarnError("New Password length can not be less than 6 digits.")
    }
    else if(newPass !== cnfPass && oldPass && newPass && cnfPass){
      setProgress(100)
      setPassAlert(false)
      setwarnError("New password and Confirm password does not match.")
    }
    // else{
    //   setProgress(100)
    //   setwarnError("All fields need to be filled.");
    // }
    
  }

  function handleClickProfile() {
    setShowProfile(true);
    setShowPassword(false);
    setAlert(false);
    setPassAlert(false);
    setwarnError("");
  }

  function handleClickPassword() {
    setShowProfile(false);
    setShowPassword(true);
    setAlert(false);
    setPassAlert(false);
    setwarnError("");
  }

  const submitProfile = (e) => {
    setProgress(80);
    e.preventDefault();
    const user = JSON.parse(localStorage.getItem("tt-userinfo"))
    const {_id} = user

    api
      .patch("https://api.trackify.ai/profile/submitProfile", {
        name: Name,
        _id
      })
      .then(
        (response) => {
          // console.log(response)
          const data = JSON.parse(localStorage.getItem("tt-userinfo"));
          // let {name} = data;
          data.name  = Name;
          localStorage.setItem("tt-userinfo", JSON.stringify(data))
          setwarnError("");
          setAlert(true);
          setProgress(100);
          window.location.href = "/#/changePassword"
        },
        (error) => {
          // console.log(error.response);
          setProgress(100);
          setAlert(false);
          setwarnError(error.response.data.error);
          if (error.response.status === 401) {
            window.localStorage.clear();
            history("/");
          }
        }
      );
  };

  return (
    <div className="userProfile">
      <h1
        className="userProfile__heading"
        style={{ color: `${secondaryColor}` }}
      >
        Profile
      </h1>
      <div className="profile-margin">
        <div className="userProfile__topHeaders">
          <p
            className={
              showProfile
                ? "userProfile__topHeadersLeft userProfile__topActive mb-0"
                : "userProfile__topHeadersLeft mb-0"
            }
            onClick={handleClickProfile}
          >
            Profile
          </p>
          <p
            className={
              showProfile
                ? "userProfile__topHeadersLeft mb-0"
                : "userProfile__topHeadersLeft userProfile__topActive mb-0"
            }
            onClick={handleClickPassword}
          >
            Change Password
          </p>
        </div>
      </div>
      {showProfile && (
        <>
          <form className="userProfile__mainContent" onSubmit={submitProfile}>
            {warnError && (
              <div
                className="profile-error"
                style={{ width: "32%", fontSize: "14px" }}
              >
                {warnError}
              </div>
            )}
            {showAlert && (
              <div
                className="alert  alert-success"
                style={{
                  padding: "6px",
                  marginBottom: "10px",
                  borderRadius: "3px",
                  width: "32%",
                  fontSize: "14px",
                }}
              >
                <div className="ng-binding"> User Profile updated </div>
              </div>
            )}
            <div className="userProfile__mainContentInput">
              <p className="userProfile__mainContentInputLabel">Name:</p>
              <input
                className="userProfile__mainContentInputData"
                value={Name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div
              className="userProfile__mainContentInput"
              style={{ paddingTop: "14px" }}
            >
              <p className="userProfile__mainContentInputLabel">Email:</p>
              <input
                className="userProfile__mainContentInputData"
                value={email}
                disabled
                style={{
                  cursor: "not-allowed",
                  backgroundColor: "#eee",
                }}
              />
            </div>
            <button
              className="userProfile__mainContentButton"
              style={{ backgroundColor: `${secondaryColor}` }}
            >
              Update
            </button>
            <hr />
          </form>
        </>
      )}
      {showPassword && (
        <>
          <form className="userProfile__mainContent">
            {warnError && (
              <div
                className="profile-error"
                style={{ width: "32%", fontSize: "14px" }}
              >
                {warnError}
              </div>
            )}
            {showPassAlert && (
              <div
                className="alert alert-success"
                style={{
                  padding: "6px",
                  marginBottom: "10px",
                  borderRadius: "3px",
                  width: "32%",
                  fontSize: "14px",
                }}
              >
                <div className="ng-binding">
                  {" "}
                  Password Changed Successfully{" "}
                </div>
              </div>
            )}
            <div className="userProfile__mainContentInput">
              <p className="userProfile__mainContentInputLabel">
                Old Password:
              </p>
              <input
                type="password"
                placeholder="Old Password"
                className="userProfile__mainContentInputData one"
                onChange={(e) => setOldPassword(e.target.value)}
              />
            </div>
            <div
              className="userProfile__mainContentInput"
              style={{ paddingTop: "14px" }}
            >
              <p className="userProfile__mainContentInputLabel">
                New Password:
              </p>
              <input
                type="password"
                placeholder="New Password"
                className="userProfile__mainContentInputData two"
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </div>
            <div
              className="userProfile__mainContentInput"
              style={{ paddingTop: "14px" }}
            >
              <p className="userProfile__mainContentInputLabel">
                Confirm Password:
              </p>
              <input
                type="password"
                placeholder="Confirm Password"
                className="userProfile__mainContentInputData three"
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
            <button
              className="userProfile__mainContentButton"
              onClick={handleChangePassword}
              style={{ backgroundColor: `${secondaryColor}` }}
            >
              Change
            </button>
            <hr />
          </form>
        </>
      )}

      <LoadingBar
        color="black"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
        loaderSpeed={3000}
        waitingTime={1000}
      />
    </div>
  );
}
