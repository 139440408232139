// import { createStore } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import RootReducer from "./Reducers/index";
const Store = configureStore({
  reducer: RootReducer,
});


export const useAppDispatch = () => useDispatch();
export const useAppSelector = useSelector;

export default Store;

