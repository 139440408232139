import React, { useEffect, useState, useContext } from "react";
import { Routes, Route } from "react-router-dom";
import Header from "../Header/Header";
import DevReport from "../Component/dev-report/DevReport";
import Dashboard from "../Component/dashboard/Dashboard";
import Projects from "../Component/projects/Projects";
import Profile from "../Component/profile/Profile";
import Footer from "../Footer/Footer";
import Detailed from "../Component/detailed-report/Detailed";
import Summary from "../Component/summary-report/Summary";
import ApplyLeave from "../Component/apply-leave/ApplyLeave";
import LeaveReport from "../Component/leave-report/LeaveReport";
import Clients from "../Component/clients/Clients";
import Users from "../Component/users/Users";
import LeaveSetting from "../Component/leave-setting/LeaveSetting";
// import Signup from "../Component/signup/Signup";
import Login from "../Component/Login/Login";
import { useLocation, useNavigate } from "react-router-dom";
import Holiday from "../Component/Holiday/Holiday";
import LeaveManagement from "../Component/leave-management/LeaveManagement";
import NewLeaveManagement from "../Component/new-leave-management/NewLeaveManagement";
import HolidaySetting from "../Component/holiday-setting/HolidaySetting";
import useApi from "../Component/services/api";
import { DataContext } from "../context/DataContext";
import { ColorContext } from "../context/ColorContext";
import { TextColorContext } from "../context/TextColorContext";
import { TextHoverContext } from "../context/TextHoverContext";
import { Signup } from "../Component/Signup/Signup";
import { ForgotPassWord } from "../Component/ForgotPassword/ForgotPassword";
// import Signup from "../Component/signup/Signup";

const Router = () => {
  const location = useLocation();
  const token = window.localStorage.getItem("access_token");
  const history = useNavigate();
  const api = useApi();
  const [progress, setProgress] = useState(0);
  const userInfo = window.localStorage.getItem("tt-userinfo");
  const [primaryColor, setPrimaryColor] = useContext(DataContext);
  const [secondaryColor, setSecondaryColor] = useContext(ColorContext);
  const [PrimaryTextColor, setPrimaryTextColor] = useContext(TextColorContext);
  const [PrimaryTextHover, setPrimaryTextHover] = useContext(TextHoverContext);

  const userInfoRole = userInfo && JSON.parse(userInfo).role;

  useEffect(() => {
    if (token) {
      // console.log("token exist");
    } else {
      history("/");
    }
  }, []);

  useEffect(() => {
    if (userInfoRole !== "admin" && location.pathname === "/client") {
      history("/dashboard");
    } else if (userInfoRole !== "admin" && location.pathname === "/user") {
      history("/dashboard");
    } else if (
      userInfoRole !== "admin" &&
      location.pathname === "/leaves/leaveSetting"
    ) {
      history("/dashboard");
    } else if (
      userInfoRole !== "admin" &&
      location.pathname === "/leave/leaveManagement"
    ) {
      history("/dashboard");
    } else if (
      userInfoRole !== "admin" &&
      location.pathname === "/holiday/holiDaySetting"
    ) {
      history("/dashboard");
    } else if (
      userInfoRole !== "admin" &&
      location.pathname === "/reports/summarybydev"
    ) {
      history("/dashboard");
    }
  }, [location]);

  useEffect(() => {
    setPrimaryColor(window.localStorage.getItem("primary_color_theme"));
  }, [primaryColor]);

  useEffect(() => {
    setSecondaryColor(window.localStorage.getItem("secondary_color_theme"));
  }, [secondaryColor]);

  useEffect(() => {
    setPrimaryTextColor(window.localStorage.getItem("text_color_theme"));
  }, [PrimaryTextColor]);

  useEffect(() => {
    setPrimaryTextHover(window.localStorage.getItem("textHover_color_theme"));
  }, [PrimaryTextHover]);

  return (
    <div>
      {location.pathname !== "/" && location.pathname !== "/signup" && 
      location.pathname !== "/forgot" &&
      <Header />}
      {/* {location.pathname !== "/signup" && <Header />} */}
      <div className={location.pathname !== "/" ? "content" : ""}>
        <Routes>
          <Route path="/leaves/leaveSetting" element={<LeaveSetting />}/> //migrated
          <Route path="/user" element={<Users />}/> //migrated
          <Route path="/client" element={<Clients />}/> //migrated
          <Route path="leave/leaveReport" element={<LeaveReport />}/> //migrated
          <Route path="leave/applyLeave" element={<ApplyLeave />}/> //migrated
          <Route path="leave/leaveManagement" element={<NewLeaveManagement />}/> //migrated
          <Route path="/reports/summary" element={<Summary />}/> //migrated
          <Route path="/reports/detail" element={<Detailed />}/> //migrated
          <Route path="/reports/summarybydev"element={<DevReport />}/> //migrated
          <Route path="/dashboard" element={<Dashboard />}/> //migrated
          <Route path="/projects" element={<Projects />} /> //migrated
          {/* <Route path="/signup" element={<Signup/>} /> //migrated */}
          {/* <Route path="/signup" element={<Signup/>} /> //migrated */}
          <Route path="/changePassword" element={<Profile />} /> //migrated
          <Route path="/holiday/holiDaySetting" element={<HolidaySetting />} /> //migrated
          <Route path="/holiday" element={<Holiday />} /> //migrated
          <Route path="/" element={<Login />} /> //migrated
          <Route path="/signup" element={<Signup/>} ></Route>
          <Route path="/forgot" element={<ForgotPassWord/>} ></Route>

        </Routes>
      </div>
      {location.pathname !== "/" && location.pathname !== "/signup" && 
      location.pathname !== "/forgot" &&<Footer />}
      {/* {location.pathname !== "/signup" && <Footer />} */}
    </div>
  );
};

export default Router;
