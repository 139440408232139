import React, { createContext, useState } from "react";

const ProjectContext = createContext();

const ProjectProvider = (props) => {
  const [ProjectDashboard, setProjectDashboard] = useState("");

  return (
    <>
      <ProjectContext.Provider value={[ProjectDashboard, setProjectDashboard]}>
        {props.children}
      </ProjectContext.Provider>
    </>
  );
};

export { ProjectContext };
export { ProjectProvider };
