import React, { useState, ReactNode, SyntheticEvent, useContext } from "react";
import { styled } from "@mui/material/styles";
import { Tabs, Tab, Box } from "@mui/material";
import { ColorContext } from "../../context/ColorContext";


function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1.3, pt: 3.5 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

const tabProps = (index) => {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
};

const CustomTabs = ({
  ariaLabel,
  labels,
  tabsContent,
  filled,
}) => {
  const [tabValue, tabValueSet] = useState(0);
  const [secondaryColor] = useContext(ColorContext);
  const handleChange = (event, newValue) => {
    tabValueSet(newValue);
  };

  const StyledTabs = styled(Tabs)({
    "&.MuiTabs-root": {
      minHeight: "42px",
    },
    "& .MuiTabs-indicator": {
      backgroundColor: `${secondaryColor}`,
    },
  });

  const StyledTab = styled(Tab, {
    shouldForwardProp: (prop) => prop !== "filled",
  })(({ filled }) => ({
    color: "rgba(0, 0, 0, 0.6);",
    "&.MuiTab-root": {
      padding: "0 10px",
      textTransform: "none",
      fontSize: "18px",
      minHeight: "42px",
    },
    "&.Mui-selected": {
      color: filled ? "#fff" : "#111",
      backgroundColor: filled ? `${secondaryColor}` : "transparent",
      border: "1px solid rgba(0, 0, 0, 0.04)",
      borderTop: "0",
      borderBottom: "0",
    },
  }));

  return (
    <Box sx={{ width: "100%" }}>
      {labels && tabsContent && (
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <StyledTabs
            value={tabValue}
            onChange={handleChange}
            aria-label={ariaLabel}
          >
            {labels &&
              labels.length > 0 &&
              labels.map((label, idx) => (
                <StyledTab
                  key={`tab-${idx}-${label}`}
                  label={label}
                  {...tabProps(idx)}
                  filled={filled}
                />
              ))}
          </StyledTabs>
        </Box>
      )}
      {tabsContent &&
        tabsContent.length > 0 &&
        tabsContent.map((tabContent, idx) => (
          <TabPanel value={tabValue} index={idx} key={`tab-panel-${idx}`}>
            {tabContent}
          </TabPanel>
        ))}
    </Box>
  );
};

export default CustomTabs;
