import React, { useState, useEffect, useContext } from "react";
import "./LeaveSetting.scss";
import AddLeaveType from "../../Views/leave-setting/AddLeaveType/AddLeaveType";
import UpdateLeaveType from "../../Views/leave-setting/UpdateLeaveType/UpdateLeaveType";
import useApi from "../../Component/services/api";
// import { LeaveType } from "./LeaveSetting.types";
import LoadingBar from "react-top-loading-bar";
import { useNavigate } from "react-router-dom";
import { ColorContext } from "../../context/ColorContext";

const LeaveSetting = () => {
  const api = useApi();
  const [allLeaves, setallLeaves] = useState([]);
  const [progress, setProgress] = useState(0);
  const history = useNavigate();
  const [secondaryColor] = useContext(ColorContext);
  const getAllLeave = () => {
    setProgress(80);
    setallLeaves([]);
    api.get("v1/leaveTypes/").then( 
      (response) => {
        // console.log("leave");
        // console.log(response, "leave");
        setallLeaves(response.data);
        setProgress(100);
      },
      (error) => {
        console.log(error);
        setProgress(100);
        if (error.response.status === 401) {
          window.localStorage.clear();
          history("/");
        }
      }
    );
  };

  useEffect(() => {
    getAllLeave();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="leave-setting">
      <div className="d-flex justify-content-between">
        <div className="box-header">
          <div className="page-title" style={{ color: `${secondaryColor}` }}>
            Leave Types
          </div>
        </div>
        <AddLeaveType getAllLeave={getAllLeave} />
      </div>

      <div className="table-content">
        <table className="table">
          <tbody>
            <tr>
              <th scope="col">Leave type name</th>
              <th scope="col" className="text-center">
                Apply Days
              </th>
              <th scope="col" className="text-center">
                Apply date from
              </th>
              <th scope="col" className="text-center">
                Action
              </th>
            </tr>
            {allLeaves.map((leave, index) => (
              <tr key={index}>
                <td>{leave.leaveTypeName}</td>
                <td className="text-center">{leave.applyDays}</td>
                <td className="text-center">{leave.applyDateFrom}</td>
                <td className="text-center">
                  <UpdateLeaveType leave={leave} getAllLeave={getAllLeave} />
                </td>
              </tr>
            ))}

            {allLeaves.length === 0 && (
              <tr className="no-record-found">
                <td align="center" colSpan={6}>
                  No Record Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <LoadingBar
        color="black"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
        loaderSpeed={3000}
        waitingTime={1000}
      />
    </div>
  );
};

export default LeaveSetting;
