import React, { useState, useEffect, useContext } from "react";
import "./Dashboard.scss";
import DatePicker from "react-datepicker";
import moment from "moment";
import useApi from "../services/api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ShowEditorContext } from "../../context/ShowEditorContext";

export default function DashboardTimer({
  dateOfTimer,
  startTimeRaw,
  endTimeRaw,
  timeridData,
  olddate,
  stateChanger,
  stateChangerProgress,
  stateChangerDuration,
  stateChangerLastDate,
  getTimeEntries,
}) {
  const [showTimerModel, setShowTimerModel] = useState(false);
  const api = useApi();
  const [startDate, setStartDate] = useState(new Date(dateOfTimer));
  const [startTimenow, setStartTimeNow] = useState("");
  const [endTimenow, setEndTimeNow] = useState("");
  const [startTimeApi, setStartTimeApi] = useState(startTimeRaw);
  const [endTimeApi, setEndTimeApi] = useState(endTimeRaw);
  const [showEditor, setShowEditor] = useContext(ShowEditorContext);
  const showTimerEdit = showEditor;

  const history = useNavigate();

  useEffect(() => {
    setStartDate(new Date(dateOfTimer));
  }, []);

  useEffect(() => {
    setStartTimeApi(startTimeRaw);
    setEndTimeApi(endTimeRaw);
    setStartDate(new Date(dateOfTimer));
  }, [startTimeRaw, endTimeRaw]);

  useEffect(() => {
    var startDateImp = moment(startTimeRaw).format("hh:mm a");
    setStartTimeNow(startDateImp);
    window.localStorage.setItem("startTimerModel", startTimeRaw);
  }, [startTimeRaw]);

  useEffect(() => {
    var endDateImp = moment(endTimeRaw).format("hh:mm a");
    setEndTimeNow(endDateImp);
    window.localStorage.setItem("endTimerModel", endTimeRaw);
  }, [endTimeRaw]);

  useEffect(() => {
    var convertedStartTime = moment(`${startTimenow}`, "hh:mm A").format(
      "HH:mm:ss"
    );
    var convertedEndTime = moment(`${endTimenow}`, "hh:mm A").format(
      "HH:mm:ss"
    );
    setStartTimeApi(
      `${getFormattedDate(startDate, true)} ${convertedStartTime}`
    );
    setEndTimeApi(`${getFormattedDate(startDate, true)} ${convertedEndTime}`);
  }, [startDate, startTimenow, endTimenow]);

  // function getTimeEntries() {
  //   stateChangerProgress(80);
  //   api.get("v1/timer/").then(
  //     (response) => {
  //       response.data.timer.forEach((element) => {
  //         element.endTime = element.endTime === null ? "" : element.endTime;
  //       });
  //       stateChanger(response.data.timer);
  //       stateChangerDuration(response.data.totalDuration);
  //       stateChangerProgress(100);
  //       stateChangerLastDate(response.data.last_date);
  //     },
  //     (error) => {
  //       console.log(error);
  //       stateChangerProgress(100);
  //       if (error.response.status === 401) {
  //         window.localStorage.clear();
  //         history("/");
  //       }
  //     }
  //   );
  // }

  const getFormattedDate = (dateInput, rev = false) => {
    let ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(
      dateInput
    );
    let mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(
      dateInput
    );
    let da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(
      dateInput
    );
    if (rev) return `${ye}-${mo}-${da}`;
    return `${da}-${mo}-${ye}`;
  };

  function msToTime(duration) {
    var seconds = Math.floor((duration / 1000) % 60),
      minutes = Math.floor((duration / (1000 * 60)) % 60),
      hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    return hours + ":" + minutes + ":" + seconds;
  }

  const handleTimerClick = () => {
    setShowTimerModel(true);
  };
  const handleCancelModel = () => {
    setShowTimerModel(false);
  };

  function handleStartTimeChange(e) {
    setStartTimeNow(e.target.value);
  }

  function handleEndTimeChange(e) {
    setEndTimeNow(e.target.value);
  }

  const handleUpdateTime = () => {
    if (
      msToTime(
        new Date(endTimeApi.replace(/\s/, "T")) -
          new Date(startTimeApi.replace(/\s/, "T"))
      ).length === 8
    ) {
      let timeridDataApi = parseInt(timeridData, 10);
      stateChangerProgress(80);
      const workspace_id = localStorage.getItem("workspace_id");
      const user = JSON.parse(localStorage.getItem("tt-userinfo"))
      const {_id} = user;
      // console.log(workspace_id, _id, "got IDs")
      // console.log(startTimeApi, endTimeApi,timeridData,getFormattedDate(startDate))
      api
      .patch("https://api.trackify.ai/timer/timerupdate",{
        startTime: startTimeApi,
          endTime: endTimeApi,
          timerID: timeridData,
          date: getFormattedDate(startDate),
          duration: msToTime(
            new Date(endTimeApi.replace(/\s/, "T")) -
              new Date(startTimeApi.replace(/\s/, "T"))
          ),
          oldDate: olddate,
          workspaceId : workspace_id,
          userId : _id
      })
      // api
      //   .put("v1/updateTime", {
      //     startTime: startTimeApi,
      //     endTime: endTimeApi,
      //     timerID: timeridDataApi,
      //     date: getFormattedDate(startDate),
      //     duration: msToTime(
      //       new Date(endTimeApi.replace(/\s/, "T")) -
      //         new Date(startTimeApi.replace(/\s/, "T"))
      //     ),
      //     oldDate: olddate,
      //   })
        .then(
          (response) => {
            if(response.data === "Project is completed."){
              localStorage.setItem("startTimer", false);
              stateChangerProgress(100);
              toast.error("Project in this time entry is marked as completed!", {
                theme: "colored",
                position: "top-left",
              }) 
            }
            else if(response.data === "overlapping"){
              localStorage.setItem("startTimer", false);
              stateChangerProgress(100);
              toast.error("Cannot be updated due to overlapping time entries!", {
                theme: "colored",
                position: "top-left",
              }) 
            }
            else if(response.data === "Future Time Entry"){
              localStorage.setItem("startTimer", false);
              stateChangerProgress(100);
              toast.error("Time entry can not be edited for future!", {
                theme: "colored",
                position: "top-left",
              }) 
            }
            getTimeEntries();
            setShowTimerModel(false);
            stateChangerProgress(100);
          },
          (error) => {
            // getTimeEntries();
            setShowTimerModel(false);
            stateChangerProgress(100);
            if (error.response.status === 401) {
              window.localStorage.clear();
              history("/");
            }
          }
        );
    } else {
      let timeridDataApi = parseInt(timeridData, 10);

      const today = new Date(endTimeApi.replace(/\s/, "T")).toISOString();
      const tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getDate() + 1);

      var newendTime = moment(tomorrow, "MM-DD-YYYY HH:mm:ss", true).format(
        "YYYY-MM-DD HH:mm:ss"
      );

      stateChangerProgress(80);
      api
        .put("v1/updateTime", {
          startTime: startTimeApi,
          endTime: newendTime,
          timerID: timeridDataApi,
          date: getFormattedDate(startDate),
          duration: msToTime(
            new Date(newendTime.replace(/\s/, "T")) -
              new Date(startTimeApi.replace(/\s/, "T"))
          ),
          oldDate: olddate,
        })
        .then(
          (response) => {
            // getTimeEntries();
            setShowTimerModel(false);
            stateChangerProgress(100);
          },
          (error) => {
            // getTimeEntries();
            setShowTimerModel(false);
            stateChangerProgress(100);
            if (error.response.status === 401) {
              window.localStorage.clear();
              history("/");
            }
          }
        );
    }
  };
  const handelChnage = (date)=>{
    if(date > new Date()){
      toast.error("Time entry can not be edited for future!", {
        theme: "colored",
        position: "top-left",
      })
      return 
    }
     setStartDate(date)
  }

  return (
    <div>
      {showTimerEdit ? (
        <div className="input-timer-1" onClick={handleTimerClick}>
          {moment(startTimeRaw).format("hh:mm A")} -{" "}
          {moment(endTimeRaw).format("hh:mm A")}
        </div>
      ) : (
        <div className="input-timer-1">
          {moment(startTimeRaw).format("hh:mm A")} -{" "}
          {moment(endTimeRaw).format("hh:mm A")}
        </div>
      )}

      {showTimerModel && (
        <>
          {" "}
          <div className="updateTime" tabIndex="-1">
            <table className="updateDiv">
              <thead>
                <tr>
                  <th>Start</th>
                  <th>Stop</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input
                      type="text"
                      className="input-timer"
                      value={startTimenow}
                      onChange={handleStartTimeChange}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="input-timer"
                      value={endTimenow}
                      onChange={handleEndTimeChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2} className="pe-0">
                    <DatePicker
                      selected={startDate}
                      dateFormat = {'dd/MM/yyyy'}
                      onChange={handelChnage}
                      className="mt-2"
                      maxDate={new Date()}
                    />
                    <span className="input-group-addon mt-2">
                      <i className="fa fa-calendar"></i>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <button
                      type="button"
                      className="save-time-btn mt-2 mb-0"
                      onClick={handleUpdateTime}
                    >
                      Save
                    </button>
                  </td>
                  <td>
                    <button
                      type="button"
                      className="save-time-btn mt-2 mb-0"
                      onClick={handleCancelModel}
                    >
                      Cancel
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
}
