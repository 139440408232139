import React, { useEffect, useState, useContext } from "react";
import "./Users.scss";
import UpdateUser from "../../Views/Users/UpdateUser/UpdateUser";
import useApi from "../services/api";
import LoadingBar from "react-top-loading-bar";
// import { userType } from "./Users.types";
import { useNavigate } from "react-router-dom";
import { ColorContext } from "../../context/ColorContext";
import { DataContext } from "../../context/DataContext";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const label = { inputProps: { "aria-label": "Switch demo" } };

const Users = () => {
  const api = useApi();
  const [allUser, setallUser] = useState([]);
  const [progress, setProgress] = useState(0);
  const [inviteEmail, setinviteEmail] = useState("");
  const [secondaryColor] = useContext(ColorContext);
  const [primaryColor] = useContext(DataContext);
  const history = useNavigate();
  const [workspace, setWorkspace] = useState("");


  useEffect(() => {
    document.body.style.setProperty("--themeColorUsers", primaryColor);
    const data = localStorage.getItem("workspace_id");
    setWorkspace(data)
  }, [primaryColor]);

  // const {date_of_joining  } = allUser;

  // const DateOfJoingng = String(date_of_joining)
  // console.log(DateOfJoingng)
  // console.log(new Date())
  const checkForAdmin = (xyz)=>{
    const data = JSON.parse(localStorage.getItem("tt-userinfo"))
    let {role} = data
    xyz.map((el)=>{
      if(el._id == data._id){
        data.role = el.role
        // history("/")
        if(el.role === "user"){
          history("/")
        }
      }
    })
    localStorage.setItem("tt-userinfo", JSON.stringify(data));
    // console.log(data, "changed DATA")
  }

  let userStateData = useSelector((state)=>{
    return state.users.test[localStorage.getItem("workspace_id")]
  })

  const getAllUser = () => {
    setProgress(80);
    setallUser([]);
    api.post("https://api.trackify.ai/user/users", {
      wkspcId : localStorage.getItem("workspace_id")
    }).then(
      (response) => {
        // console.log(response, "all users data");
        setallUser(response.data);
        checkForAdmin(response.data)
        setProgress(100);
      },
      (err) => {
        console.log(err);
        setProgress(100);
        if (err.response.status === 401) {
          window.localStorage.clear();
          history("/");
        }
      }
    );
  };
  

  useEffect(() => {
    if( userStateData && userStateData.length !== 0){
      // getAllUser()
      setallUser(userStateData)
      checkForAdmin(userStateData)
    }else{
      getAllUser();
    }
  }, []);

  const updateComponent = (user) => {
    try {
      let data = {
        createdBy: user.createdBy,
        created_at: user.created_at,
        deleted_at: user.deleted_at,
        email: user.email,
        inviteLink: user.inviteLink,
        joinDate: user.date_of_joining,
        name: user.name,
        password: user.password,
        role: user.role,
        status: user.status,
        updated_at: user.updated_at,
        userID: user._id,
        verified: user.verified,
      };
      return <UpdateUser user={data} getAllUser={getAllUser} />;
    } catch (error) {
      console.log(error);
    }
  };

  const InviteUser = () => {
    setProgress(80);
    api
      .post("https://api.trackify.ai/user/invite", {
        email: inviteEmail,
        wkspcId : localStorage.getItem("workspace_id")
      })
      .then(
        (response) => {
          // console.log(response);
          if(response.data === "User does not exist"){
            alert("This email is not registered.")
            setinviteEmail("")
          }else{
            alert("User has been invited.")
            setinviteEmail("")
          }
          setProgress(100);
          getAllUser();
        },
        (error) => {
          console.log(error);
          if (error.response.status === 401) {
            window.localStorage.clear();
            history("/");
          }
        }
      );
  };

  const makeAdmin = (role, userID) => {
    setProgress(80);
    // console.log(userID, role)
    api
      .patch("https://api.trackify.ai/user/makeadmin", {
        role: role,
        userID: userID,
        workspaceId : localStorage.getItem("workspace_id")
      })
      .then(
        (response) => {
          // console.log(response);
          if(response.data=== "Admin access not changed, Atleast one person should be admin."){
            // console.log("yesss")
            toast.error("Admin access not changed, Atleast one person should be admin.", {
              theme: "colored",
              position: "top-left",
            })
          }else{
            toast.success("Admin access changed.", {
              theme: "colored",
              position: "top-left",
            })
          }
          getAllUser();
          setProgress(100);
        },
        (error) => {
          console.log(error);
          setProgress(100);
          if (error.response.status === 401) {
            window.localStorage.clear();
            history("/");
          }
        }
      );
  };

  return (
    <div className="users">
      <div className="d-flex justify-content-between">
        <div className="box-header">
          <div className="page-title" style={{ color: `${secondaryColor}` }}>
            Users
          </div>
        </div>
        <div className="invite-email">
          <input
            type="text"
            className="form-control inputBorder"
            placeholder="Invite Email"
            value={inviteEmail}
            onChange={(e) => setinviteEmail(e.target.value)}
          />
          <button
            className="btn"
            onClick={InviteUser}
            style={{ color: `${secondaryColor}` }}
          >
            Invite
          </button>
        </div>
      </div>

      <div className="table-content">
        <table className="table">
          <tbody>
            <tr>
              <th scope="col">Name</th>
              <th scope="col" className="text-center">
                Email
              </th>
              <th scope="col" className="text-center">
                Role
              </th>
              {/* <th scope="col" className="text-center">
                Added By
              </th> */}
              <th scope="col" className="text-center">
                Join Date
              </th>
              <th scope="col" className="text-center">
                Make Admin
              </th>
              <th scope="col" className="text-center">
                Action
              </th>
            </tr>

            {allUser.map((user, index) => (
              <tr key={index + 1}>
                <td>{user.name}</td>
                <td className="text-center">{user.email}</td>
                <td className="text-center">{user.role}</td>
                {/* <td className="text-center">{user.createdBy}</td> */}
                <td className="text-center">{user.date_of_joining}</td>
                <td className="text-center">
                  <label className="switch">
                    <input
                      type="checkbox"
                      defaultChecked={user.role === "admin" ? true : false}
                      onChange={(e) => {
                        // console.log(e.target.checked);
                        if (e.target.checked === true) {
                          makeAdmin("admin", user._id);
                        } else if (e.target.checked === false) {
                          makeAdmin("user", user._id);
                        }
                      }}
                    />
                    <span className="slider round"></span>
                  </label>
                </td>
                <td className="text-center">
                  {user.role !== "admin" && updateComponent(user)}
                </td>
              </tr>
            ))}

            {allUser.length === 0 && (
              <tr className="no-record-found">
                <td align="center" colSpan={7}>
                  No Record Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <LoadingBar
        color="black"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
        loaderSpeed={3000}
        waitingTime={1000}
      />
    </div>
  );
};

export default Users;
