import React, { useEffect, useState, useContext, useRef } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import "./AddProject.scss";
import { useForm } from "react-hook-form";
import useApi from "../../../Component/services/api";
import LoadingBar from "react-top-loading-bar";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { useLocation, useNavigate } from "react-router-dom";
import { ColorContext } from "../../../context/ColorContext";
import { toast } from "react-toastify";
import AddClient from "../../clients/AddClients/AddClient";
import { useDispatch } from "react-redux";
import { createProject } from "../../../Reducers/ProjectSlice";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          style={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function AddProject({ projects, getAllProjects, setProjectID,isHeader }) {
  const api = useApi();
  const [applyDate, setapplyDate] = useState("");
  const [progress, setProgress] = useState(0);
  const [personName, setPersonName] = useState([]);
  const [clients, setClients] = useState([]);
  const [users, setUsers] = useState([]);
  const [names, setNames] = useState([]);
  const [uniqueName, setUniqueName] = useState([]);
  const [projectCount, setProjectCount] = useState(0);
  const [warnError, setwarnError] = useState("");
  const history = useNavigate();
  const [allSelected, setAllSelected] = useState(false);
  const [secondaryColor, primaryColor ] = useContext(ColorContext);
  const location = useLocation();
  const [clientAdd, setClientsAdd] = useState([]);
  const [applyClientCss, setApplyClientCss] = useState(true);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [showPopUp, setShowPopUp] = useState(false)
  const selectAllRef = useRef(null);
  const [selectedClient, setSelectedClient] = useState(null)
  const [emptyClientError, setEmptyClientError] = useState(false)
  const [open, setOpen] = React.useState(
    isHeader ? true : false
  );
  const [isDashboard, setIsDashboard] = useState(
    location.pathname == "/dashboard"
  );
  const [projectTitle, setProjectTtile] = useState("");
  const dispatch = useDispatch();

  // console.log(projects, "projects")
  useEffect(() => {
    if (projects.clients) {
      setClients(projects.clients);
      // console.log(clients, "clientsss")

      // projects.users.unshift({
      //   name: "Select All",
      //   _id: "all selected!",
      // });
      const uniqueArray = projects.users.filter((value, index) => {
        const _value = JSON.stringify(value);
        return (
          index ===
          projects.users.findIndex((obj) => {
            return JSON.stringify(obj) === _value;
          })
        );
      });
      // console.log(uniqueArray, "unique array")
      setUsers(uniqueArray);
      let userID = uniqueArray.map((user) => {
        return user._id;
      });
      setNames(userID);
      setUniqueName(uniqueArray);
    }
  }, [projects]);

  // useEffect(() => {
  //   // const data = localStorage.getItem("workspace_id")
  //   // setworkspaceID(localStorage.getItem("workspace_id"))
  //   getAllClients(localStorage.getItem("workspace_id"));
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const handelClick = (event) => {
    // console.log(event.target.outerText, "click")

    let isPresentInNamesArray = false;
    uniqueName.map((el) => {
      if (el.name === event.target.outerText) {
        isPresentInNamesArray = true;
      }
    });

    if (event.target.outerText === "Select All" && allSelected === true) {
      // console.log("get it")
      setPersonName([]);
      setAllSelected(false);
    } else if (
      event.target.outerText !== "Select All" &&
      isPresentInNamesArray &&
      allSelected === true
    ) {
      // console.log("Situation 2", personName, event.target.outerText)
      let ID = "";
      uniqueName.map((el) => {
        if (el.name === event.target.outerText) {
          ID = el._id;
        }
      });
      // console.log(ID)
      const newArray = personName.filter((el) => {
        return el != ID && el != "all selected!";
      });
      setPersonName(newArray);
      setAllSelected(false);
      // console.log(newArray, "newArray")
      // console.log(uniqueName, "personName in click")
    }
  };

  const handleChange = (event) => {
    // console.log(event)
    const {
      target: { value },
    } = event;
    // console.log(value, "in event");
    if (value.includes("all selected!")) {
      setAllSelected(true);
      setPersonName((prev) => {
        return [...names, "all selected!"];
      });
    } else if (value.length === uniqueName.length - 1) {
      setAllSelected(true);
      setPersonName((prev) => {
        return [...names, "all selected!"];
      });
    } else {
      setPersonName(typeof value === "string" ? value.split(",") : value);
      // console.log(personName)
    }
    // setPersonName(
    //   // On autofill we get a stringified value.
    //   typeof value === "string" ? value.split(",") : value
    // );
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
    setValue
  } = useForm();

  const handleClickOpen = () => {
    // console.log(projects, "projectsss")
    setProjectCount(0);
    setOpen(true);
  };
  const handleClose = () => {
    setProjectID("");
    setOpen(false);
  };
  const handelRfresh = () => {
    window.location.reload();
  };

  const onSubmit = (data) => {
    // console.log(data);

    let allUserId = personName.filter((data) => {
      return data !== "all selected!";
    });

    if(selectedClient === null || selectedClient === "ADD CLIENT"){
      // return setEmptyClientError(true)
      return toast.error("Clients can not be empty!", {
        theme: "colored",
        position: "top-left",
      })
    }
    // console.log(allUserId, "allusers")
    // console.log(data,"data")
    // console.log(allUserId);
    setProgress(80);
    const token = localStorage.getItem("access_token");

    setProjectCount(projectCount + 1);
    // console.log(projectCount)
    if (projectCount === 0) {
      let alreadyPresent = false;
      if (projects.project.length > 0) {
        projects.project.map((el) => {
          if (el.projectName === data.ProjectTitle) {
            alreadyPresent = true;
          }
        });
      }
      if (!alreadyPresent) {
        dispatch(createProject({ data, selectedUsers,selectedClient }));
        setProgress(100);
        handleClose();
        setwarnError("");
        setPersonName([]);
        reset();
        // handelRfresh()
        // api.post("https://api.trackify.ai/projects/projectcreated", {
        //   clientID: data.Client,
        //   estimatedHours: data.TimeEstimation,
        //   manageBy: data.ProjectManager,
        //   projectDescription: data.Description,
        //   projectName: data.ProjectTitle,
        //   team: selectedUsers,
        //   workspaceId: localStorage.getItem("workspace_id"),
        //   token
        // })
        // .then(
        //   (response) => {
        //     // console.log(response);
        //     setProgress(100);
        //     handleClose();
        //     setwarnError("");
        //     setPersonName([]);
        //     reset();
        //     getAllProjects(localStorage.getItem("workspace_id"));
        //     // window.location.href = "/#/projects"
        // handelRfresh()
        //   },
        //   (error) => {
        //     console.log(error);
        //     setwarnError(error.response.data.error);
        //     setProgress(100);
        //     if (error.response.status === 401) {
        //       window.localStorage.clear();
        //       history("/");
        //     }
        //   }
        // );
      } else {
        setProjectCount(0);
        setProgress(100);
        toast.error("Project with this name already exists!", {
          theme: "colored",
          position: "top-left",
        });
      }
    }
  };
  useEffect(() => {}, [clientAdd.length]);
  const getAllClients = (data) => {
    setProgress(80);
    setClientsAdd([]);
    const token = localStorage.getItem("access_token");
    // console.log(token, "token in clients")
    // console.log("coming")
    api
      .post("https://api.trackify.ai/client/clientsdata", {
        wkspc_id: data,
        token,
      })
      .then(
        (response) => {
          // console.log(response, "response")
          // if(response.data){
          setClientsAdd(response.data);
          setClients(response.data);
          // }
          setProgress(100);
        },
        (error) => {
          if (error.response.status === 401) {
            window.localStorage.clear();
            history("/");
          }
          // console.log(error, "error")
          setProgress(100);
        }
      );
  };

  const handleUserSelection = (event) => {
    // console.log(event.target.value, "handleUserSelection")
    // console.log(selectedUsers, "handel usr select");
    if (!event.target.value.includes("all")) {
      setSelectedUsers(event.target.value);
    }
    // console.log(event.target.value, "value")
    // if(event.target.value.includes("all selected")){
    //   console.log("first")
    //   if(allSelected === true){
    //     setSelectedUsers([])
    // // console.log(allUsersSelected)
    //     setallUsersSelected(!allUsersSelected)

    //   }
    //   if(allSelected === false){
    //     console.log("Not wala case")
    //     console.log(projects.team)
    //     const data = projects.team.map((user) => user._id)
    //     console.log(data, "data")
    //     setSelectedUsers(data);
    //     setallUsersSelected(true)
    //   }
    // }else{
    //   setSelectedUsers(event.target.value);
    //   if(selectedUsers.length === users.length){
    //     setallUsersSelected(true)
    //   }
    // }
    // console.log(allUsersSelected)
  };

  const handleMenuItemClick = (event) => {
    if (event.target.tagName !== "INPUT") {
      // console.log(event.target.tagName, "input");
      // console.log(selectAllRef.current.checked, "selectALLRef");
      const allCheckbox = selectAllRef.current;
      allCheckbox.checked = !selectAllRef.current.checked;
      // console.log(allCheckbox.checked, "ALLCHECK");
      handleSelectAll({ target: allCheckbox });
    }
  };

  const handleSelectAll = (event) => {
    const isChecked = event.target.checked;
    // console.log(isChecked, selectedUsers, "inside handel select all");
    if (isChecked) {
      setSelectedUsers(projects.team.map((user) => user._id));
    } else {
      setSelectedUsers([]);
    }
  };
  // console.log(selectedClient, "selectedClient")

  const setValueAsEmpty = ()=>{
    let data =   document.getElementById("demo-multiple-checkbox")
    if(selectedClient === "ADD CLIENT"){
      data.innerText = ""
      // console.log("Select Client")
    }
    setValue("Client", "")
    setShowPopUp(false)
  }

  const renderPopup = () => {
    // const data = watch("Client")
    // console.log(data, "register")
    // // Conditionally render the popup/dialog based on the selected value
    // if (data === "ADD CLIENT") {
    //   return (
    //     <AddClient
    //       clients={clientAdd}
    //       applyClientCss={applyClientCss}
    //       getAllClients={getAllClients}
    //       setProgress={setProgress}
    //       setValueAsEmpty={setValueAsEmpty}
    //     />
    //   );
    // }

    // return "";
    if(showPopUp){
        return (
        <AddClient
          clients={clientAdd}
          applyClientCss={applyClientCss}
          getAllClients={getAllClients}
          setProgress={setProgress}
          setValueAsEmpty={setValueAsEmpty}
          isHeader={isHeader}
        />
      );
    }else {
      return null
    }
  };

  const handelChangeSelect = (event)=>{
    // console.log(event, "event")
    let data =   document.getElementById("demo-multiple-checkbox")
    // console.log(data.innerHTML, "data")
    if(selectedClient === "ADD CLIENT"){
      data.innerText = "found it"
      // console.log("found it")
    }
    // console.log(event.target.dataset.value)
    // setSelectedClient(event.target.dataset.value)
    setSelectedClient(event.target.value)
  }

  return (
    <div className="Project-type-content">
      <div
        className="box-header"
        onClick={handleClickOpen}
        style={{ cursor: "pointer" }}
      >
        <div
          className="add-client"
          style={{
            color: `${secondaryColor}`,
            marginBottom: isHeader ? "-20px" : "0px",
            padding: isHeader ? "-31px 0 0 0" : "0",
          }}
        >
          {/* + Add Project */}
          { isHeader ? "" : "+ Add Project" }
          {/* {location.pathname === "/projects" ? "+ Add Project" : ""} */}
        </div>
      </div>

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        className="add-Project-leave-type"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => {
            setwarnError("");
            handleClose();
          }}
        >
          <span style={{ color: `${secondaryColor}` }}>Add New Project</span>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <form className="form-width" onSubmit={handleSubmit(onSubmit)}>
            {warnError && <div className="client-error">{warnError}</div>}
            <div className="mb-3">
              <div className="flex-input w-100">
                <div className="w-100">
                  <input
                    type="text"
                    placeholder="Project Title"
                    className="form-control disable-input"
                    {...register("ProjectTitle", { required: true })}
                    // onChange={(e) => {
                    //   // setProjectTtile(e.target.value)
                    //   console.log(e.target.value);
                    //   // You can perform additional logic here if needed
                    // }}
                  />
                </div>
              </div>
              {errors.ProjectTitle &&
                errors.ProjectTitle.type === "required" && (
                  <span style={{ color: "red" }}>
                    Project Title can not be blank
                  </span>
                )}
            </div>

            <div className="mb-3">
              <div className="flex-input w-100">
                <div className="w-100">
                  <input
                    type="number"
                    placeholder="Time Estimation (hours)"
                    className="form-control disable-input"
                    {...register("TimeEstimation", { required: true })}
                  />
                </div>
              </div>
              {errors.TimeEstimation &&
                errors.TimeEstimation.type === "required" && (
                  <span style={{ color: "red" }}>
                    Time estimates can not be blank
                  </span>
                )}
            </div>

            <div className="mb-3">
              <div className="flex-input w-100">
                <div className="w-100">
                  <input
                    type="text"
                    placeholder="Description"
                    className="form-control disable-input"
                    {...register("Description", { required: true })}
                  />
                </div>
              </div>
              {errors.Description && errors.Description.type === "required" && (
                <span style={{ color: "red" }}>
                  Description can not be blank
                </span>
              )}
            </div>

            <div className="mb-3">
              <FormControl sx={{ width: "100%" }}>
                <InputLabel
                  id="demo-multiple-name-label"
                  style={{
                    fontWeight: "normal",
                    color: "#212529",
                    fontFamily: "Source Sans Pro",
                  }}
                >
                  Select Client
                </InputLabel>
                <Select
                  // displayEmpty
                  style={{ border: "none" }}
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  // MenuProps={MenuProps}
                  // className="form-select"
                  // input={<OutlinedInput label="Tag" />}
                  aria-label="Default select example"
                  onChange={handelChangeSelect}
                  // {...register("Client", { required: true })}
                >
                  {/* <MenuItem value="">Select Client</MenuItem> */}
                  {clients.map((client, index) => {
                    if (client.clientName !== "Select All")
                      return (
                        <MenuItem
                          key={index}
                          input={<OutlinedInput label="Tag" />}
                          style={{
                            borderBottom: "1px solid #f5f5f5",
                            padding: "3",
                            margin: "0 23px",
                          }}
                          className="inputOptionList"
                          onClick={handelChangeSelect}
                          value={client._id}
                          name={client.clientName}
                        >
                          {client.clientName}
                        </MenuItem>
                      );
                  })}
                  <MenuItem value="ADD CLIENT" 
                  className="add-client"
                  style={{
                    color: `${secondaryColor}`,
                    marginBottom: "0px",
                    padding: "0",
                    fontSize : "large",
                    fontWeight : 500,
                    padding : "7px 10px"
                  }}
                  placeholder="Hello"
                  onClick = {()=>setShowPopUp(!showPopUp)}
                  // onClick={handelChangeSelect}
                  >+ Add Client</MenuItem>
                  {/* <AddClient
                    clients={clientAdd}
                    applyClientCss={applyClientCss}
                    getAllClients={getAllClients}
                    setProgress={setProgress}
                  /> */}
                  {/* <option value="">Hey</option> */}
                </Select>
                {renderPopup()}
                {errors.Client && errors.Client.type === "required" && (
                  <span style={{ color: "red" }}>Clients can not be blank</span>
                )}
              </FormControl>
            </div>

            {/* <div className="mb-3">
              <select
                className="form-select"
                aria-label="Default select example"
                {...register("ProjectManager")}
              >
                <option value="">Select Project Manager</option>
                {users.map((user, index) => {
                  if (user.name !== "Select All")
                    return (
                      <option key={index} value={user.userID}>
                        {user.name}
                      </option>
                    );
                })}
              </select>
            </div> */}

            <div className="mb-3">
              <FormControl sx={{ width: "100%" }}>
                <InputLabel
                  id="demo-multiple-name-label"
                  style={{
                    fontWeight: "normal",
                    color: "#212529",
                    fontFamily: "Source Sans Pro",
                  }}
                >
                  Select Team
                </InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={selectedUsers}
                  onChange={handleUserSelection}
                  renderValue={(selected) => {
                    if (selected.length === users.length) {
                      return [`All Selected!`];
                    } else if (selected.length > 0) {
                      return [`${selected.length} selected`];
                    } else {
                      return ["Select Team"];
                    }
                  }}
                  // {(selected) => (
                  //   <div>
                  //     {selected.length === users.length
                  //       ? "All users"
                  //       : selected.length < 1 ? "Select" : selected.length + " selected" }
                  //   </div>
                  // )}
                >
                  <MenuItem
                    // className="inputOptionList"
                    style={{
                      borderBottom: "1px solid #f5f5f5",
                      padding: "0",
                      margin: "0 0px",
                    }}
                    key="all"
                    value="all"
                    onClick={handleMenuItemClick}
                    input={<Checkbox inputRef={selectAllRef} />}
                  >
                    <Checkbox
                      inputRef={selectAllRef}
                      checked={selectedUsers.length === projects.team.length}
                      onChange={handleSelectAll}
                    />
                    Select All
                  </MenuItem>
                  {projects.team.map((user) => (
                    <MenuItem
                      // className="inputOptionList"
                      style={{
                        borderBottom: "1px solid #f5f5f5",
                        padding: "0",
                        margin: "0 23px",
                      }}
                      key={user._id}
                      value={user._id}
                    >
                      <Checkbox checked={selectedUsers.includes(user._id)} />
                      {user.name}
                    </MenuItem>
                  ))}
                </Select>
                {/* <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  displayEmpty
                  value={personName}
                  style={{border:"none"}}
                  onChange={handleChange}
                  onClick = {handelClick}
                  // input={<OutlinedInput label="Tag" />}
                  renderValue={(selected) => {
                    console.log(selected, "selected")
                    if (selected.includes("all selected!")) {
                      return [`all selected!`];
                    }
                    else if (selected.length > 0) {
                      return [`${selected.length} selected`];
                    }
                     else {
                      return selected.join(", ");
                    }
                  }}
                  MenuProps={MenuProps}
                >
                  {names.map((name, index) => (
                    <MenuItem
                      className="inputOptionList"
                      key={name}
                      value={name}
                      style={{
                        borderBottom: "1px solid #f5f5f5",
                        padding: "0",
                        margin: name === "all selected!" ? "0 0px" : "0 23px",
                      }}
                    >
                      <Checkbox
                        // checked={personName.indexOf(name) > -1}
                        checked={personName.indexOf(name) > -1}
                        onChange={(e) => {
                          console.log(name, "name")
                          if (name === "all selected!") {
                            // setAllSelected(true)
                            if (e.target.checked === false) {
                              setPersonName([]);
                            }
                          }
                        }}
                      />
                      <ListItemText onClick={(e)=>{
                        console.log(e.target.innerText, "item in list")
                        console.log(allSelected,"all selected")
                      }} primary={uniqueName[index].name} />
                    </MenuItem>
                  ))}
                </Select> */}
              </FormControl>
            </div>

            <button
              type="submit"
              className="btn submit-form"
              style={{ backgroundColor: `${secondaryColor}` }}
            >
              Submit
            </button>
          </form>
        </DialogContent>
      </BootstrapDialog>

      <LoadingBar
        color="black"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
        loaderSpeed={3000}
        waitingTime={1000}
      />
    </div>
  );
}
