import React, { useContext, useEffect, useState } from "react";
import ThemeIcon from "../assets/images/icons/themeicon.jpeg";
import {
  Box,
  Drawer,
  List,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogContentText,
  CircularProgress,
} from "@mui/material";
import { DataContext } from "../context/DataContext";
import { ColorContext } from "../context/ColorContext";
import { TextColorContext } from "../context/TextColorContext";
import { TextHoverContext } from "../context/TextHoverContext";
import useApi from "../Component/services/api";
import DashboardThemeModal from "../Component/dashboard/DashboardThemeModal";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "../assets/images/icons/edit.png";
import updatewkspc from "../Views/workspace/updatewkspc";
import { useDispatch, useSelector } from "react-redux";
import { getTheme, timerEditor } from "../Reducers/dashboardSlice";
import { getAllProject } from "../Reducers/ProjectSlice";
import { getAllUser } from "../Reducers/UserSlice";
import { getAllClient } from "../Reducers/ClientSlice";

function ThemeIcons({ getTimeEntries, TimerEditorStatus }) {
  const [open, setOpen] = React.useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMenuModelOpen, setIsMenuModelOpen] = useState(0);
  const [progress, setProgress] = useState(0);
  const [themeToggel, setThemeToggel] = useState(true);
  const [workspaceToggel, setWorkspaceToggel] = useState(true);
  const [workspaceName, setWorkSpaceName] = useState("");
  const [editWorkspace, setEditWorkspace] = useState("")
  const [editWorkspaceID, setEditWorkspaceId] = useState("")
  const [workspace, setWorkSpace] = useState([]);
  const [workspaceId, setWorkSpaceId] = useState("");
  const [primaryColor, setPrimaryColor] = useContext(DataContext);
  const [secondaryColor, setSecondaryColor] = useContext(ColorContext);
  const [PrimaryTextColor, setPrimaryTextColor] = useContext(TextColorContext);
  const [PrimaryTextHover, setPrimaryTextHover] = useContext(TextHoverContext);
  const [hasWorkspaceData, sethasWorkspaceData] = useState(false)
  const [hasWorkspaceClient, sethasWorkspaceClient] = useState(false)
  const [hasWorkspaceUser, sethasWorkspaceUser ]  = useState(false)
  const [hasWorkspaceTheme, sethasWorkspaceTheme ]  = useState(false)
  const [workspaceCount, setWorkspaceCount] = useState(0);
  const [checkForRole, setCheckForRole] = useState([]);
  const userInfo = window.localStorage.getItem("tt-userinfo");
  const [editOpen, setEditOpen] = useState(false)
  const [editLoading, setEditLoading] = useState(false)
  const dispatch = useDispatch()

  const userInfoRole = userInfo && JSON.parse(userInfo).role;

  const api = useApi();

  useEffect(() => {
    // TimerEditorStatus();
    setTimeout(() => {
      handelSwitchWorkspace(localStorage.getItem("workspace_id"));
      const data = JSON.parse(localStorage.getItem("workspace"));
      setWorkSpace(data);
      const ID = localStorage.getItem("workspace_id");
      setWorkSpaceId(ID);
      // console.log(data, "data");
      // console.log(ID, "ID");
    }, 2000);
    // console.log()
  }, [open, localStorage.getItem("workspace_id")]);

  useEffect(() => {
    getTimeEntries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceId]);
  const handelRfresh = ()=>{
    window.location.reload()
  }
  useEffect(() => {
    if (isMenuModelOpen === 1) {
      setIsMenuOpen(false);
      setIsMenuModelOpen(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMenuModelOpen]);

  const handleClickOpen = () => {
    // console.log("handel open called");
    setWorkspaceCount(0);
    setOpen(true);
  };
  const handleeidtOpen = (data, id) => {
    // console.log(clients)
    // console.log(data, "dataa")
    setEditWorkspace(data)
    setEditWorkspaceId(id)
    setEditOpen(true)
  };
  const handeleditclose = ()=>{
    setEditOpen(false)
  }
  let projectStateData = useSelector((state) => {
    return state.projects.projects;
  });
  let userStateData = useSelector((state) => {
    return state.users.users;
  });
  let clientStateData = useSelector((state) => {
    return state.clients.clients;
  });
  let themeStateData = useSelector((state) => {
    return state.dashboards.theme;
  });
  // let hasWorkspaceData = false
  // useEffect(()=>{
    const checkForStoreData = ()=>{
      projectStateData.map((el)=>{
        // console.log(el)
         if(el.id === localStorage.getItem("workspace_id")){
          sethasWorkspaceData(true)
         }
      })
    }
    const checkForUser = ()=>{
      userStateData.map((el)=>{
        // console.log(el)
         if(el.id === localStorage.getItem("workspace_id")){
          sethasWorkspaceUser(true)
         }
      })
    }
    const checkForClient = ()=>{
      clientStateData.map((el)=>{
        // console.log(el)
         if(el.id === localStorage.getItem("workspace_id")){
          sethasWorkspaceClient(true)
         }
      })
    }
    const checkForTheme = ()=>{
      themeStateData.map((el)=>{
        // console.log(el)
         if(el.id === localStorage.getItem("workspace_id")){
          sethasWorkspaceTheme(true)
         }
      })
    }
    // if(projectStateData){
    // }
  // },[projectStateData])
  
  const handleClose = () => {
    // console.log("handel close called");
    setOpen(false);
  };
  const handelThemeToggel = () => {
    // console.log(themeToggel);
    setThemeToggel(!themeToggel);
    if (!themeToggel) {
      setWorkspaceToggel(false);
    }
    // setWorkspaceToggel(!workspaceToggel)
  };
  
  // function getColorThemeAdmin() {
  //   const workspace_id = localStorage.getItem("workspace_id");
  //   api.get(`https://api.trackify.ai/color/colorsetting/${workspace_id}`).then(
  //     (response) => {
  //       // console.log(response, "res theme");
  //       window.localStorage.setItem("themeIDApi", response.data[0].id);
  //       response.data.map((value) => {
  //         // value.selected === 1 &&
  //         window.localStorage.setItem(
  //           "primary_color_theme",
  //           value.primaryColor
  //         );
  //         setPrimaryColor(value.primaryColor);
  //         // value.selected === 1 &&
  //         window.localStorage.setItem(
  //           "secondary_color_theme",
  //           value.secondaryColor
  //         );
  //         setSecondaryColor(value.secondaryColor);
  //         // value.selected === 1 &&
  //         window.localStorage.setItem("text_color_theme", value.textColor);
  //         setPrimaryTextColor(value.textColor);
  //         // value.selected === 1 &&
  //         window.localStorage.setItem("textHover_color_theme", value.textHover);
  //         setPrimaryTextHover(value.textHover);
  //       });
  //     },
  //     (error) => {
  //       console.log(error);
  //     }
  //   );
  // }

  const handelWorkspaceToggel = () => {
    // console.log(themeToggel)
    setWorkspaceToggel(!workspaceToggel);
    // setThemeToggel(!themeToggel)
    if (!workspaceToggel) {
      setThemeToggel(false);
    }
  };

  const handelSwitchWorkspace = (id) => {
    localStorage.setItem("workspace_id", id);
    setWorkSpaceId(id);
    // console.log(id);
    setProgress(80);
    api
      .post("https://api.trackify.ai/workspace/switch", {
        id,
      })
      .then((response) => {
        // console.log(response, "res of");
        // getColorThemeAdmin()
        // dispatch(getTheme())
        dispatch(timerEditor())
        checkForStoreData()
        checkForUser()
        checkForClient()
        checkForTheme()
        if(!hasWorkspaceData){
          dispatch(getAllProject())
        }
        if(!hasWorkspaceUser){
          dispatch(getAllUser())
        }
        if(!hasWorkspaceClient){
          dispatch(getAllClient())
        }
        if(!hasWorkspaceTheme){
          dispatch(getTheme())
        }
        // TimerEditorStatus()
        const data1 = response.data[0].user_id;
        // getColorThemeAdmin()
        setProgress(100);
        const data = JSON.parse(localStorage.getItem("tt-userinfo"));
        const { _id } = data;
        // console.log(_id, "ID");
        data1.map((el) => {
          if (el.user_id == _id) {
            // console.log(el.role);
            data.role = el.role;
            localStorage.setItem("tt-userinfo", JSON.stringify(data));
          }
        });

        setCheckForRole(response.data[0].user_id);
        window.location.href = "/#/dashboard";
        // handelSwitch()
      });

    // setWorkSpaceId(id);
  };

  const handelEditWorspace= ()=>{
    setEditLoading(true)
    // console.log(editWorkspace, editWorkspaceID, "here get it")
    api.patch("https://api.trackify.ai/workspace/editWorkspace", {
      workspaceName : editWorkspace,
      workspaceId : editWorkspaceID
    }).then((response)=>{
      // console.log(response)
      setEditLoading(false)
      handelRfresh()
      setEditOpen(false)
    //   let workspaces = localStorage.getItem("workspace")
    //   console.log(workspace, "workspace")
    //  const updatedWorkspace= workspace.map((el)=>{
    //     if(el.wkspc_id === response.data.data.workspaceId){
    //       el.wkspc_name = response.data.data.workspaceName
    //     }
    //   })
    //   localStorage.setItem("workspace", updatedWorkspace)
    },
    (err) => {
      setEditLoading(false)
      console.log(err);
    })
  }

  const handelCreateWorkspace = () => {
    // console.log("create workspace req made");
    // handleClose();
    setWorkspaceCount(workspaceCount + 1);
    // console.log(workspaceCount);
    if (workspaceCount === 0) {
      const token = localStorage.getItem("access_token");
      api
        .post("https://api.trackify.ai/workspace/create", {
          token: token,
          name: workspaceName,
          wkspcName: workspaceName,
          workspaceType: "personal",
          roleId: "Admin",
          wkspcId: "1234",
          status: workspaceName,
          theme_id: "64195b83b5e42affc1a86633",
        })
        .then(
          (response) => {
            // console.log(response, "workspaces");
            if (response.data.status === "success") {
              localStorage.setItem(
                "workspace",
                JSON.stringify(response.data.workspace)
              );
              setOpen(false);
            }
          },
          (err) => {
            // console.log(err);
          }
        );
    }
  };

  return (
    <div
      className="dashboard__SettingsIconContainer"
      onClick={() => setIsMenuOpen(true)}
    >
      {isMenuOpen ? (
        <></>
      ) : (
        <img
          src={ThemeIcon}
          alt=""
          className="dashboard__SettingsIcon"
          style={{ cursor: "pointer" }}
        />
      )}
      {isMenuOpen && (
        <div className="dashboard__modal">
          <div style={{ textAlign: "left" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <p className="dashboard__modalHeading">
                {userInfoRole === "admin" ? "Theme/Workspace" : "Workspace"}
              </p>
              <CloseIcon
                className="dashboard__modalCloseIcon"
                onClick={() => setIsMenuModelOpen(1)}
              />
            </div>
            {userInfoRole === "admin" && (
              <div onClick={handelThemeToggel}>Themes</div>
            )}
            {userInfoRole === "admin" && <hr />}
            {userInfoRole === "admin" && (
              <Collapse timeout="auto" in={themeToggel} unmountOnExit>
                <DashboardThemeModal value={1} name="Theme 1" color="#03999E" />
                <DashboardThemeModal value={2} name="Theme 2" color="#545454" />
                <DashboardThemeModal value={3} name="Theme 3" color="#B65A65" />
                <DashboardThemeModal value={4} name="Theme 4" color="#B6935E" />
                <DashboardThemeModal value={5} name="Theme 5" color="#D8959D" />
                <DashboardThemeModal value={6} name="Theme 6" color="#6DC3C6" />
                <DashboardThemeModal
                  value={7}
                  name="Theme 7"
                  color="#4DCCC3"
                  showSlider={true}
                />
              </Collapse>
            )}

            <div onClick={handelWorkspaceToggel}>Workspace</div>
            <hr />
            <Collapse timeout="auto" in={workspaceToggel} unmountOnExit>
              {workspace?.map((el) => {
                return (
                  <div
                    key={el.wkspc_id}
                    style={{
                      backgroundColor:
                        el.wkspc_id == workspaceId
                          ? "rgba(162, 194, 231, 0.737)"
                          : "white",
                          display : "flex",
                          justifyContent : "space-between",
                          padding : "10px "
                    }}
                    className="workspacModel"
                    onClick={() => handelSwitchWorkspace(el.wkspc_id)}
                  >
                    {el.wkspc_name}{" "}
                    { el.role === "admin" ?
                    <div onClick={()=>{handleeidtOpen(el.wkspc_name,el.wkspc_id)}} >
                        <img
                          src={EditIcon}
                          alt="edit"
                          width="20"
                          style={{
                            cursor: "pointer",

                          }}
                        />
                      </div> : ""
                    }
                  </div>
                );
              })}
              <Dialog
                open={editOpen}
                onClose={handeleditclose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth={"xs"}
              >
                <DialogTitle id="alert-dialog-title">
                  <div className=" justify-content-between main-header">
                    <h6
                      className="add-client-title"
                      style={{ color: `${secondaryColor}` }}
                    >
                      Edit Workspace
                    </h6>
                    <IconButton
                      aria-label="close"
                      onClick={() => {
                        handeleditclose()
                        // setclientInput("");
                        // setwarnError("");
                      }}
                      sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                      }}
                      style={{
                        border: "1px solid #9e9e9e",
                        padding: 0,
                        marginTop: "7px",
                        width: "24px",
                        height: "24px",
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                </DialogTitle>
                <DialogContent>
                  <DialogContentText
                    id="alert-dialog-description"
                    className="add-client-middle"
                  >
                    {/* {showErr && (
              <span className="client-error" id="client-error">Client Name can not be blank</span>
            )}
            {warnError && <span className="client-error" id="client-error" >{}</span>} */}
                    <input
                      type="text"
                      className="form-control inputBorder"
                      placeholder="Enter workspace's name"
                      // value={clientInput}
                      onChange={(e) => {
                        setEditWorkspace(e.target.value);
                        // console.log(workspaceName);
                      }}
                      value={editWorkspace}
                    />
                    <button
                      className="btn w-100 submit"
                      style={{ backgroundColor: `${secondaryColor}` }}
                      // onClick={() => {
                      //   if (clientInput !== "") {
                      //     setshowErr(false);
                      //     clientUpdate();
                      //   } else {
                      //     setshowErr(true);
                      //   }
                      // }}
                      onClick={handelEditWorspace}
                    >
                      {editLoading ? <CircularProgress color="inherit" /> : "Submit"}
                    </button>
                  </DialogContentText>
                </DialogContent>
              </Dialog>
              <div onClick={handleClickOpen} style={{ cursor: "pointer" }}>
                {" "}
                + Create New Workspace{" "}
              </div>

              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth={"xs"}
              >
                <DialogTitle id="alert-dialog-title">
                  <div className=" justify-content-between main-header">
                    <h6
                      className="add-client-title"
                      style={{ color: `${secondaryColor}` }}
                    >
                      Create a new Workspace
                    </h6>
                    <IconButton
                      aria-label="close"
                      onClick={() => {
                        handleClose();
                        // setclientInput("");
                        // setwarnError("");
                      }}
                      sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                      }}
                      style={{
                        border: "1px solid #9e9e9e",
                        padding: 0,
                        marginTop: "7px",
                        width: "24px",
                        height: "24px",
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                </DialogTitle>
                <DialogContent>
                  <DialogContentText
                    id="alert-dialog-description"
                    className="add-client-middle"
                  >
                    {/* {showErr && (
              <span className="client-error" id="client-error">Client Name can not be blank</span>
            )}
            {warnError && <span className="client-error" id="client-error" >{}</span>} */}
                    <input
                      type="text"
                      className="form-control inputBorder"
                      placeholder="Enter workspace's name"
                      // value={clientInput}
                      onChange={(e) => {
                        setWorkSpaceName(e.target.value);
                        // console.log(workspaceName);
                      }}
                    />
                    <button
                      className="btn w-100 submit"
                      style={{ backgroundColor: `${secondaryColor}` }}
                      // onClick={() => {
                      //   if (clientInput !== "") {
                      //     setshowErr(false);
                      //     clientUpdate();
                      //   } else {
                      //     setshowErr(true);
                      //   }
                      // }}
                      onClick={handelCreateWorkspace}
                    >
                      Submit
                    </button>
                  </DialogContentText>
                </DialogContent>
              </Dialog>
              {/* </div> */}
            </Collapse>
          </div>
        </div>
      )}
    </div>
  );
}

export default ThemeIcons;
