import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import moment from "moment";


    const workspaceID = localStorage.getItem("workspace_id")
    const token  = localStorage.getItem("access_token")
    // const userId = JSON.parse(localStorage.getItem("tt-userinfo"))
    // const {_id} = userId


export const getAllClient = createAsyncThunk(
    "client/getall",
    async (name, thunkapi) => {
      const workspaceID = localStorage.getItem("workspace_id")
    const token  = localStorage.getItem("access_token")
      try {
        const res = await axios.post("https://api.trackify.ai/client/clientsdata", {
            wkspc_id : workspaceID,
            token
          })
        // console.log(res, " res in redux User")
        const data = { data : res.data, id:workspaceID }
        return data;
      } catch (e) {
        return thunkapi.rejectWithValue(e.message);
      }
    }
  );
  export const updateClient = createAsyncThunk(
    "client/update",
    async ({client, clientInput, clients}, thunkapi) =>{
    const token  = localStorage.getItem("access_token")
    const workspaceID = localStorage.getItem("workspace_id")
        try {
            // console.log(client, clientInput, token)
            const res = await axios.patch("https://api.trackify.ai/client/updateClient", {
              clientID: client._id,
              clientName: clientInput,
              token
            })
              
            // console.log(clients, "newClients")
            // console.log(res, "Res in redux update")
            // console.log(clients)
            
            return {client, clientInput,workspaceID}

        } catch (error) {
            return thunkapi.rejectWithValue(error.message);
        }
    }
  )

  export const addClient = createAsyncThunk(
    "client/add",
    async ({clientName,createdAt, clients}, thunkapi) =>{
    const token  = localStorage.getItem("access_token")
    const workspaceID = localStorage.getItem("workspace_id")
        try {
            const res = await axios
            .post("https://api.trackify.ai/client/create", {
              clientName: clientName,
              createdAt : moment(createdAt).format("MM/DD/YYYY"),
              workspaceID : localStorage.getItem("workspace_id"),
              token
            })
              
            // console.log(clients, "newClients")
            // console.log(res, "Res in redux add")
            // console.log(clients)
            const data = {data : res.data, id : workspaceID}
            return data

        } catch (error) {
            return thunkapi.rejectWithValue(error.message);
        }
    }
  )

  const initialState = {
    clients: [],
    test : {}
  };

const clientSlice = createSlice({
    name : "clientSlice",
    initialState,
    reducers : {},
    extraReducers(builder){
        builder.addCase(getAllClient.fulfilled, (state, action)=>{
            // state.clients = action.payload
            const workspace_id = action.payload.id
            let existing = false;
            state.clients.map((el)=>{
              if(el.id == workspace_id){
                existing = true
              }
            })
                if(!existing){
                  const {id, data} = action.payload
                  state.clients = [...state.clients, action.payload]
              state.test[id] = data
            }
        })
        builder.addCase(updateClient.fulfilled, (state, action)=>{
            // state.clients = action.payload
            state.test[action.payload.workspaceID].forEach((el)=>{
                if(el._id === action.payload.client._id){
                    // console.log("YESSS")
                    el.clientName = action.payload.clientInput
                }
            })
            // console.log(action.payload, "action")
        })
        builder.addCase(addClient.fulfilled, (state, action)=>{ 
            // state.clients = [...action.payload.clients, {}]
            const {id, data} = action.payload
            state.test[id] = data
        })
    }
})

// console.log(clientSlice.actions)
// export {projects}
export default clientSlice.reducer
 
// export const {addProject} = projectSlice.actions