import React, { useState, useRef, useEffect, useContext } from "react";
import "./Header.scss";
import PauseTimer from "../assets/images/header/stop-button-filled.png";
import PlayTimer from "../assets/images/header/play-button-filled.png";
import { convertHMS } from "../Common/convertHMS";
import useApi from "../Component/services/api";
import moment from "moment";
import { toast } from "react-toastify";
import LoadingBar from "react-top-loading-bar";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useDispatch, useSelector } from "react-redux";
import { DashboardApi } from "../Actions/DashboardApi";
import { Box, Button, CircularProgress } from "@mui/material";
import { DataContext } from "../context/DataContext";
import { ColorContext } from "../context/ColorContext";
import { TextColorContext } from "../context/TextColorContext";
import { TextHoverContext } from "../context/TextHoverContext";
import { TimerContext } from "../context/TimerContext";
import { ProjectContext } from "../context/ProjectContext";
import { useLocation } from "react-router-dom";
import AddProject from "../Views/Projects/AddProject/AddProject";
import axios from "axios";
import { addProject, getAllProject, selectProject } from "../Reducers/ProjectSlice";
import { getAllUser } from "../Reducers/UserSlice";
import { getAllClient } from "../Reducers/ClientSlice";
import Store from "../Store";

const Header = () => {
  const api = useApi();
  const [timerIcon, setTimerIcon] = useState(false);
  const [hmsTime, sethmsTime] = useState(convertHMS("0"));
  const interValRef = useRef(undefined);
  const [description, setDescription] = useState("");
  const [projectID, setProjectID] = useState("");
  const [userId, setUserId] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [timerId, setTimerId] = useState("");
  const [title, setTitle] = useState("");
  const [isWaiting, setIsWaiting] = useState(false);
  const [uniqueProject, setUniqueProject] = useState({});
  const [projectName, setProjectName] = React.useState("");
  const [projectDetails, setProjectDetails] = React.useState([]);
  const [progress, setProgress] = useState(0);
  const dispatch = useDispatch();
  const [projects, setProjects] = useState([]);
  const [projectReports, projectReportsSet] = useState([]);
  const [primaryColor, setPrimaryColor] = useContext(DataContext);
  const [secondaryColor, setSecondaryColor] = useContext(ColorContext);
  const [PrimaryTextColor, setPrimaryTextColor] = useContext(TextColorContext);
  const [PrimaryTextHover, setPrimaryTextHover] = useContext(TextHoverContext);
  const [onTimerDashboard, setonTimerDashboard] = useContext(TimerContext);
  const singleProject = useSelector((state) => state.GetSingleProject);
  const [ProjectDashboard, setProjectDashboard] = useContext(ProjectContext);
  const [isHeader, setIsHeader] = useState(true)
  const [projectError, setProjectError] = useState(true);
  const timerRef = useRef(null);
  const [timerIdState, setTimerIdState] = useState("");
  const [countTimer, setCountTimer] = useState(0);
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  const history = useNavigate();

  const userInfo = window.localStorage.getItem("tt-userinfo");

  const userInfoId = userInfo && JSON.parse(userInfo)._id;
  // console.log(JSON.parse(userInfo).role, "roleeee")
  // console.log(userInfoId, "userInfoId")

  let projectStateData = useSelector((state) => {
    // console.log(state, "state")
    const data = state.projects.projects.map((el)=>{
      if(el.id === localStorage.getItem("workspace_id")){
        return el.data
      }
    })
    // console.log(data, "data")
    return state.projects.projects;
  });
  let individualWorkspaceProject = useSelector((state)=>{
    return state.projects.test[localStorage.getItem("workspace_id")]
  })
  let individualWorkspaceUser = useSelector((state)=>{
    return state.users.test[localStorage.getItem("workspace_id")]
  })
  let individualWorkspaceClient = useSelector((state)=>{
    return state.clients.test[localStorage.getItem("workspace_id")]
  })
  // console.log(individualWorkspaceUser, "individualWorkspaceUser")
  
  // console.log(individualWorkspaceProject, "individualWorkspaceProject")
  let StateData = []
  // if(projectStateData){
  //   projectStateData.map((el)=>{
  //     console.log(el)
  //      if(el.id === localStorage.getItem("workspace_id")){
  //       StateData = el.data
  //      }
  //   })
  // }
  const indexValue = projectStateData.findIndex(obj => obj.id === localStorage.getItem("workspace_id"));
  if(indexValue !== -1){
  StateData = projectStateData[indexValue].data
  }

  // console.log(indexValue, "index value")
  // console.log(StateData, "state Data")
  // console.log(projectStateData, "projectStateData")
  let usertStateData = useSelector((state) => {
    return state.users.users;
  });
  let clientStateData = useSelector((state) => {
    return state.clients.clients;
  });

  // console.log(selectProject(Store.getState()), "select proejct")
  // console.log(useSelector(selectProject), "using useSelector")

  useEffect(() => {
    setPrimaryColor(window.localStorage.getItem("primary_color_theme"));
  }, [primaryColor]);

  useEffect(() => {
    setSecondaryColor(window.localStorage.getItem("secondary_color_theme"));
  }, [secondaryColor]);

  useEffect(() => {
    setPrimaryTextColor(window.localStorage.getItem("text_color_theme"));
  }, [PrimaryTextColor]);

  useEffect(() => {
    setPrimaryTextHover(window.localStorage.getItem("textHover_color_theme"));
  }, [PrimaryTextHover]);

  let time = 0;

  useEffect(() => {
    document.title = title;
  }, [title]);
  // useEffect(()=>{
  //   // getAllProjects()
  // },[localStorage.getItem("workspace_id")])

  // console.log(projectDetails, "project Details")
  useEffect(() => {
    projectDetails?.map((value) => {
      // console.log(value, "value")
      if (projectID === value.projectID) {
        setProjectName(value.projectName);
      }
    });
  }, [projectID, individualWorkspaceProject]);

  // console.log(projectDetails, "pppeee")
  useEffect(() => {
    if (timerIcon === true && location.pathname !== "/") {
      setTitle(`${hmsTime}-${projectName}`);
    } else {
      setTitle("Trackify");
    }
  }, [hmsTime]);

  useEffect(() => {
    setTitle("Trackify");
  }, []);

  // useEffect(() => {
  //   // getProjectsDetails();
  // }, []);

  useEffect(() => {
    document.body.style.setProperty("--themeColor", primaryColor);
  }, [primaryColor]);

  useEffect(() => {
    document.body.style.setProperty("--themeColorSecondary", secondaryColor);
  }, [secondaryColor]);

  useEffect(() => {
    if (timerIcon === true) {
      let startTime = startDate;
      let beforeDate = new Date(startTime.replace(/\s/, "T"));
      // console.log(beforeDate, "beforeDate")
      // console.log(startTime, "start time")
      // console.log(new Date(), "this is current time")
      const getDifferenceInSeconds = (date1, date2) => {
        const diffInMs = Math.abs(date2 - date1);
        return diffInMs / 1000;
      };
      interValRef.current = window.setInterval(() => {
        sethmsTime(
          convertHMS(getDifferenceInSeconds(beforeDate, new Date()).toString())
        );
      }, 1000);
    }
  }, [timerIcon]);

  useEffect(() => {
    if (timerIcon === false) {
      sethmsTime(convertHMS("0"));
    }
  }, [timerIcon]);

  useEffect(() => {
    let onTimer = onTimerDashboard;
    // console.log(onTimer, "ontimer")
    if (onTimer?.length > 0) {
      setUniqueProject(onTimer[0]);
      setProjectID(onTimer[0].projectId === null ? "" : onTimer[0].projectId);
      setStartDate(onTimer[0].startTime);
      setDescription(onTimer[0].description);
      setTimerIcon(true);
    }
  }, [onTimerDashboard]);

  useEffect(() => {
    document.body.style.setProperty("--themeColor", primaryColor);
  }, [primaryColor]);

  // const getAllProjects = () => {
  //   projectReportsSet([]);
  //   setProgress(80);
  //   const workspaceID = localStorage.getItem("workspace_id")
  //   const token  = localStorage.getItem("access_token")
  //   const userId = JSON.parse(localStorage.getItem("tt-userinfo"))
  //   const {_id} = userId
  //   axios
  //     .all([
  //       api.get(`https://api.trackify.ai/projects/proj/${workspaceID}/${token}/${_id}`),
  //       api.post("https://api.trackify.ai/client/clientsdata", {
  //         wkspc_id : localStorage.getItem("workspace_id"),
  //         token
  //       }),
  //       api.post("https://api.trackify.ai/user/users", {
  //         wkspcId : localStorage.getItem("workspace_id")
  //       }),
  //     ])
  //     .then(
  //       (response) => {
  //           console.log(response, "responseeee in header for")
  //           console.log(secondaryColor, "secondary")
  //         setProjects({
  //           clients: response[1].data,
  //           users: response[2].data,
  //           project : response[0].data.projects
  //         });

  //         // dispatch(addProject(response[0].data.projects))

  //         projectReportsSet(response[0].data.projects);

  //         setProgress(100);
  //       },
  //       (error) => {
  //         // console.log(error.response.status);
  //         setProgress(100);
  //         if (error.response.status === 401) {
  //           window.localStorage.clear();
  //           history("/");
  //         }
  //       }
  //     );
  // };

  useEffect(() => {
    if (usertStateData.length !== 0) {
      setProjects({
        clients: individualWorkspaceClient,
        users: individualWorkspaceUser,
        project: individualWorkspaceProject,
      });

      // setProjectDetails(projectStateData)
      // console.log(clientStateData, usertStateData,projectStateData, "data")
      projectReportsSet(individualWorkspaceProject);
    } else {
      dispatch(getAllUser());
      // setTimeout(() => {
        dispatch(getAllProject());
        dispatch(getAllClient());
      // }, 2000);
    }
  }, [individualWorkspaceProject, clientStateData, usertStateData]);

  // useEffect(() => {
  //   if ( individualWorkspaceProject !== undefined && individualWorkspaceProject.length !== 0) {
  //     const projectNameArray = individualWorkspaceProject.map((el) => {
  //       return { projectID: el._id, projectName: el.projectName };
  //     });
  //     setProjectDetails(projectNameArray);
  //     setProjectDashboard(projectNameArray);
  //   }else{
  //     setProjectDetails([]);
  //     setProjectDashboard([]);
  //   }
  // }, [individualWorkspaceProject]);

  // useEffect(() => {
  //   if (projectStateData.length !== 0 && clientStateData !== 0 && usertStateData !== 0) {
  //     setProjects({
  //       clients: clientStateData,
  //       users: usertStateData,
  //       project: projectStateData
  //     });
  //     projectReportsSet(projectStateData);
  //   } else {
  //     if (projectStateData.length === 0) {
  //       dispatch(getAllProject());
  //     }
  //     if (clientStateData.length === 0) {
  //       dispatch(getAllClient());
  //     }
  //     if (usertStateData.length === 0) {
  //       dispatch(getAllUser());
  //     }
  //   }
  // }, [projectStateData, clientStateData, usertStateData]);

  // useEffect(()=>{

  // }, [])

  const getProjects = () => {
    setProgress(80);
    const workspaceIdFromLocal = localStorage.getItem("workspace_id");
    const user = JSON.parse(localStorage.getItem("tt-userinfo"));
    const { _id } = user;
    const token = localStorage.getItem("access_token");
    api
      .get(
        `https://api.trackify.ai/timer/${workspaceIdFromLocal}/${_id}/${token}`
      )
      .then(
        (response) => {
          // console.log(response.data, "projects");/
          let onTimer = response.data.timer.filter((data) => {
            return data.endTime === null;
          });

          if (onTimer.length > 0) {
            setUniqueProject(onTimer[0]);
            setProjectID(
              onTimer[0].projectId === null ? "" : onTimer[0].projectId
            );
            // console.log(onTimer)
            setStartDate(onTimer[0].startTime);
            setDescription(onTimer[0].description);
            setTimerIcon(true);
          }

          setProgress(100);
        },
        (error) => {
          // console.log(error);
          setProgress(100);
          if (error.response.status === 401) {
            window.localStorage.clear();
            history("/");
          }
        }
      );
  };

  const getProjectsDetails = () => {
    setProgress(80);
    const workSpaceIDLS = localStorage.getItem("workspace_id");
    const userId = JSON.parse(localStorage.getItem("tt-userinfo"));
    const { _id } = userId;
    api
      .get(
        `https://api.trackify.ai/projects/projectNames/${workSpaceIDLS}/${_id}`
      )
      .then(
        (response) => {
          setProgress(100);
          // console.log(response, "Header Project");
          // console.log(response.data);
          // setProjectDetails(response.data.project1);
          // setProjectDashboard(response.data.project2);
          setProjectDetails(response.data);
          setProjectDashboard(response.data);
        },
        (error) => {
          // console.log(error);
          setProgress(100);
          if (error.response.status === 401) {
            window.localStorage.clear();
            history("/");
          }
        }
      );
  };

  useEffect(() => {
    // console.log("Workspace changed")
    if (timerIcon === true) {
      stopTimer();
      // console.log("timer is running and workspace has been changed.")
    }
  }, [localStorage.getItem("workspace_id")]);

  useEffect(() => {
    getProjectsDetails();
  }, [localStorage.getItem("workspace_id"), individualWorkspaceProject]);

  useEffect(() => {
    getProjects();
  }, [localStorage.getItem("workspace_id")]);

  const stopTimer = () => {
    if (description !== "") {
      localStorage.setItem("startTimer", false);
      setTimerIcon(false);
      window.clearInterval(interValRef.current);
      setCountTimer(0);
      setProgress(80);
      let timerId = localStorage.getItem("timerId");
      if (!timerId) {
        const tempData = JSON.parse(localStorage.getItem("tt-userinfo"));
        const { timerOn } = tempData;
        timerId = timerOn;
      }
      api
        .patch("https://api.trackify.ai/timer", {
          description: description,
          timerId: `${timerId}`,
        })
        .then(
          (response) => {
            setTimerIcon(false);
            setStartDate(null);
            setLoading(false);
            sethmsTime(convertHMS("0"));
            setDescription("");
            setProjectID("");
            setProgress(100);
            getProjects();
            getProjectsDetails();
            setTimerIcon(false);
            dispatch(
              DashboardApi({
                apiUpdate: true,
              })
            );
            dispatch(getAllProject())
            if (response.data.status === "failed") {
              toast.error(response.data.message, {
                theme: "colored",
                position: "top-left",
              });
              setLoading(false);
            } else if (response.data == "already completed") {
              toast.error(
                "Project was marked completed so your timer was stopped! Please contact admin in case of any discrepancy",
                {
                  theme: "colored",
                  position: "top-left",
                }
              );
              setLoading(false);
            }
          },
          (error) => {
            // console.log(error);
            getProjects();
            setProgress(100);
            setLoading(false);
            if (error.response.status === 401) {
              window.localStorage.clear();
              history("/");
            }
          }
        );
    } else {
      toast.error("Please add description before stopping the timer!!", {
        theme: "colored",
        position: "top-left",
      });
    }
  };

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const UpdatingUserSchema = () => {
    api
      .patch("https://api.trackify.ai/user/timerOn", {
        id: userInfoId,
        timerId: localStorage.getItem("timerId"),
      })
      .then((res) => {
        // console.log(res.data)
        // console.log(JSON.parse(localStorage.getItem("tt-userinfo")))
        const localUser = JSON.parse(localStorage.getItem("tt-userinfo"));
        localUser.timerOn = res.data;
        localStorage.setItem("tt-userinfo", JSON.stringify(localUser));
        // console.log(localUser, "localuser")
      });
  };

  const startTimer = async () => {
    await delay(1000);
    if (projectID !== "" && isWaiting === false) {
      // let startTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      // setStartDate(startTime);
      localStorage.setItem("startTimer", true);
      setProgress(80);
      api
        .post("https://api.trackify.ai/timer", {
          description: description,
          // duration: null,
          // endTime: null,
          projectId: projectID,
          projectName,
          workspaceId: localStorage.getItem("workspace_id"),
          // startTime: startTime,
          // timerID: null,
          userId: userInfoId,
        })
        .then(
          (response) => {
            setLoading(false);
            // console.log(response, "start timer");
            window.localStorage.setItem("timerId", response.data._id);
            setTimerIdState(response.data._id);
            getProjects();
            // localStorage.setItem("projectName", response.data.projectName)
            setProjectID(response.data.projectId);
            UpdatingUserSchema();
            // window.location.reload()
            // setTimerIcon(true);
            // setProgress(100);
          },
          (error) => {
            // console.log(error);
            setLoading(false);
            getProjects();
            setProgress(100);
            if (error.response.status === 401) {
              window.localStorage.clear();
              history("/");
            }
          }
        );

      // const UpdatingUserSchema = api.patch("https://api.trackify.ai/user/timerOn",{
      //   id : userInfoId,
      //   timerId : localStorage.getItem("timerId")
      // }).then((res)=>{
      //   console.log(res)
      // })
      // console.log(timerIdState, "timerIDState")
    } else {
      toast.error("Please select project!!", {
        theme: "colored",
        position: "top-left",
      });
      setCountTimer(0);
      setLoading(false);
    }
  };

  async function handleDoubleClickOnTimer() {
    toast.error("Please wait sometime!!", {
      theme: "colored",
      position: "top-left",
    });
    setIsWaiting(true);
    await delay(7000);
    setIsWaiting(false);
    sethmsTime(convertHMS("0"));
  }

  useEffect(() => {
    if (singleProject.iconChange === true) {
      getProjects();
    }
  }, [singleProject.iconChange]);

  const handleBlur = () => {
    setProgress(80);
    api
      .put("v1/timer", {
        description: description,
        duration: null,
        endTime: null,
        projectID: projectID,
        startTime: uniqueProject.startTime,
        timerID: uniqueProject.timerID,
        userID: uniqueProject.userID,
      })
      .then(
        (response) => {
          // console.log(response);
          // getProjects();
          setProgress(100);
        },
        (error) => {
          // console.log(error);
          setProgress(100);
          if (error.response.status === 401) {
            window.localStorage.clear();
            history("/");
          }
        }
      );
  };
  const handelAddProject = () => {
    history("/projects");
    // console.log("hello, project add clicked")
  };
  const handelProjectError = () => {
    if (!projectError) {
      // setProjectError(true);
      setTimeout(() => {
        setProjectError(true);
      }, 10000);
    }
    // setProjectError(false)
  };
  const clearTimer = () => {
    clearTimeout(timerRef.current);
  };
  const renderPopup = () => {
    // Conditionally render the popup/dialog based on the selected value
    if (projectID === "ADD PROJECT") {
      return (
        <AddProject
          projects={{
            clients: individualWorkspaceClient,
            users: individualWorkspaceUser,
            team: individualWorkspaceUser,
            project: individualWorkspaceProject,
          }}
          getAllProjects={getAllProject}
          setProjectID = {setProjectID}
          isHeader={isHeader}
        />
      );
    }

    return null;
  };

  const colorOfHeader = primaryColor;
  const colorOfTimerButton = secondaryColor;

  return (
    <Box className="header" style={{ backgroundColor: `${colorOfHeader}` }}>
      <Box
        className="header-content"
        style={{ backgroundColor: `${colorOfHeader}` }}
      >
        <div className="row m-0">
          <div className="col-8">
            <div className="row">
              <div className="col-md-6 col-lg-8">
                <input
                  style={{ backgroundColor: `${colorOfHeader}` }}
                  type="text"
                  className="form-control px-2"
                  placeholder="What are you working on ?"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  onBlur={() => {
                    if (timerIcon === true) {
                      // handleBlur();
                    }
                  }}
                />
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="position-relative adjustWidth mx-auto p-2">
                  {/* <FormControl className="mui-select-custom" sx={{ m: 1 }}> */}
                  <Select
                    className="mui-select-custom"
                    sx={{ m: 1 }}
                    value={projectID}
                    onChange={(e) => {
                      setProjectID(e.target.value);
                      // console.log(e.target.value);
                    }}
                    displayEmpty
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                    }}
                    inputProps={{
                      "aria-label": "Without label",
                    }}
                  >
                    <MenuItem value="">Select Project</MenuItem>
                    {projectDetails?.map((value, index) => (
                      <MenuItem
                        key={index}
                        value={value.projectID}
                        onClick={() => {
                          setProjectID(value.projectID);
                          // console.log(value.projectID, "hey", projectID)
                        }}
                      >
                        {value.projectName}
                      </MenuItem>
                    ))}
                    <MenuItem
                      value="ADD PROJECT"
                      className="add-client"
                      style={{
                        color: `${secondaryColor}`,
                        marginBottom: "0px",
                        padding: "0",
                        fontSize : "large",
                        fontWeight : 500,
                        padding : "7px 10px"
                      }}
                    >
                      + Add Project
                    </MenuItem>
                    {/* {JSON.parse(userInfo).role === "admin" &&
                      <AddProject projects={{
                        clients: clientStateData,
                        users: usertStateData,
                        team: usertStateData,
                        project : projectStateData
                      }} getAllProjects={getAllProject} />
                      } */}

                    {/* <div role={"button"} tabIndex={0} style={{paddingLeft:"15px", cursor:"pointer",}} onClick={handelAddProject} > + Add Project</div> */}
                    {/* <MenuItem onClick={handelAddProject}> + Add Project</MenuItem> */}
                    {/* <Button onClick={handelAddProject} variant="text" >+ Add Project</Button> */}
                  </Select>
                  {renderPopup()}

                  {/* </FormControl> */}
                </div>
              </div>
            </div>
          </div>

          <div className="col-4 d-lg-flex justify-content-center mt-1 mt-md-0">
            <div className="timer">
              {timerIcon === true ? (
                <p className="timer-tag mb-0">{hmsTime}</p>
              ) : (
                <p className="timer-tag mb-0">
                  {loading ? (
                    <CircularProgress size={30} color="inherit" />
                  ) : (
                    "00:00:00"
                  )}
                </p>
              )}
              <button
                className="btn"
                style={{ backgroundColor: `${colorOfTimerButton}` }}
                onClick={() => {
                  if (projectID === "") {
                    handelProjectError();
                    if (projectError) {
                      toast.error("Please select project!!", {
                        theme: "colored",
                        position: "top-left",
                      });
                      setProjectError(false);
                    }
                  } else {
                    setLoading(true);
                    setCountTimer(countTimer + 1);
                    setTimeout(() => {
                      if (timerIcon === false && countTimer === 0) {
                        startTimer();
                      } else if (timerIcon === true) {
                        stopTimer();
                      }
                    }, 1000);
                  }
                }}
                onDoubleClick={handleDoubleClickOnTimer}
              >
                {timerIcon === true ? (
                  <img
                    src={PauseTimer}
                    alt="timer-pause"
                    className="timer-pause"
                  />
                ) : (
                  <img
                    src={PlayTimer}
                    alt="timer-play"
                    className="timer-play"
                  />
                )}
              </button>
            </div>
          </div>
        </div>
      </Box>
      <LoadingBar
        color="black"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
        loaderSpeed={3000}
        waitingTime={1000}
      />
    </Box>
  );
};

export default Header;
