import React, { createContext, useState } from "react";

const ShowEditorContext = createContext();

const ShowEditorProvider = (props) => {
  const [showEditor, setShowEditor] = useState(false);

  return (
    <>
      <ShowEditorContext.Provider value={[showEditor, setShowEditor]}>
        {props.children}
      </ShowEditorContext.Provider>
    </>
  );
};

export { ShowEditorContext };
export { ShowEditorProvider };
