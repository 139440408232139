import React, { useState, useEffect, useContext } from "react";
import "./LeaveReport.scss";
// import { UserType } from "./LeaveReport.types";
import moment from "moment";
import LeaveReportFilter from "../../Views/leave/LeaveReportFilter";
import useApi from "../services/api";
import LoadingBar from "react-top-loading-bar";
import { useNavigate } from "react-router-dom";
import { ColorContext } from "../../context/ColorContext";
const LeaveReport = () => {
  const api = useApi();
  const [leaves, setLeaves] = useState([]);
  const [user, setUser] = useState([]);
  const [getAllUser, setgetAllUser] = useState([]);
  const history = useNavigate();
  const [startDate, setstartDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, setendDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [progress, setProgress] = useState(0);
  const [totalDays, setTotalDays] = useState(null);
  const [secondaryColor] = useContext(ColorContext);

  useEffect(() => {
    setProgress(80);
    api
      .get(
        `v1/leaveReports/?&userID=undefined&startDate=${startDate}&endDate=${endDate}`
      )
      .then(
        (response) => {
          // console.log(response);
          setUser(response.data.users);
          setLeaves(response.data.leaves);
          setTotalDays(response.data.totalDays);
          setProgress(100);
        },
        (error) => {
          console.log(error);
          setProgress(100);
          if (error.response.status === 401) {
            window.localStorage.clear();
            history("/");
          }
        }
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="leave-report">
      <div className="box-header">
        <div className="page-title" style={{ color: `${secondaryColor}` }}>
          Leave Report
        </div>
      </div>

      <LeaveReportFilter
        user={user}
        getAllUser={getAllUser}
        setgetAllUser={setgetAllUser}
        setstartDate={setstartDate}
        setendDate={setendDate}
        setLeaves={setLeaves}
        startDate={startDate}
        endDate={endDate}
        setTotalDays={setTotalDays}
      />

      <div className="middle-content">
        <div className="table-content">
          <div className="total-time mt-5">
            <p>
              Total:
              <span className="bold-time">{totalDays}</span>
            </p>
          </div>
          <table className="table">
            <tbody>
              <tr>
                <th scope="col">User Name</th>
                <th scope="col" className="text-center">
                  Date From
                </th>
                <th scope="col" className="text-center">
                  Date To
                </th>
                <th scope="col" className="text-center">
                  Description
                </th>
                <th scope="col" className="text-center">
                  Days
                </th>
                <th scope="col" className="text-center">
                  Status
                </th>
              </tr>
              {leaves.map((leavedetail, index) => (
                <tr key={index}>
                  <td>-</td>
                  <td className="text-center">-</td>
                  <td className="text-center">-</td>
                  <td className="text-center">-</td>
                  <td className="text-center">-</td>
                  <td className="text-center">-</td>
                </tr>
              ))}
              {leaves.length === 0 && (
                <tr className="no-record-found">
                  <td align="center" colSpan={6}>
                    No Record Found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <LoadingBar
        color="black"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
        loaderSpeed={3000}
        waitingTime={1000}
      />
    </div>
  );
};

export default LeaveReport;
