import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import "./AddHoliday.scss";
// import { DialogTitleProps, UpdateUserProps } from "./AddHoliday.types";
import AppsIcon from "@mui/icons-material/Apps";
import useApi from "../../../Component/services/api";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import moment from "moment";
import LoadingBar from "react-top-loading-bar";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { ColorContext } from "../../../context/ColorContext";
import { useDispatch } from "react-redux";
import { addHoliday } from "../../../Reducers/holidaySlice";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuDialogActions-root": {
    padding: theme.spacing(1),
  },
}));


const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
        style={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
function AddHoliday({ getAllHolidays }) {
  const api = useApi();
  const [open, setOpen] = React.useState(false);
  const [holidayDate, setholidayDate] = useState("");
  const [progress, setProgress] = useState(0);
  const [dateError, setdateError] = useState("");
  // const [countRntry, setCountEntry] = useState(0);
  const [isDisable, setIsDisable] = useState(false);
  const history = useNavigate();
  const [secondaryColor] = useContext(ColorContext);
  const dispatch = useDispatch()

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const handleClickOpen = () => {
    setIsDisable(false);
    // setCountEntry(0)
    setOpen(true);
  };
  const handleClose = () => {
    // setIsDisable(false)
    // setCountEntry(0)
    setdateError("")
    setOpen(false);
  };

  //   React.useEffect(() => {
  //     if (user.joinDate !== "0000-00-00") {
  //       setJoiningDate(new Date(user.joinDate));
  //     }
  //   }, []);

  const onSubmit = (data) => {
    // console.log(data);
    // document.querySelector(".one").value = ""
    // console.log(register("holidayName"))
    // register("holidayName")
    // console.log(data, "data")
    // document.querySelector(".btn").disabled = true
    if(holidayDate !== ""){
      setholidayDate("")
    setProgress(80);
    if(!data.Restricted){
      data.Restricted = "0"
    }
    setIsDisable(true);
    const passingData = {
      holidayName: data.holidayName,
        holidayDate: moment(holidayDate).format("YYYY-MM-DD"),
        isYearly: data.ApplyYearly,
        description: data.description,
        restricted : data.Restricted,
        workspaceId : localStorage.getItem("workspace_id")
    }
    dispatch(addHoliday(passingData))
    handleClose();
          setProgress(100);
          // getAllHolidays();
          reset({ ...data });
    
    // api
    //   // .post("v1/addHoliday/", {
    //   .post("https://api.trackify.ai/holiday/addHoliday", {
    //     holidayName: data.holidayName,
    //     holidayDate: moment(holidayDate).format("YYYY-MM-DD"),
    //     isYearly: data.ApplyYearly,
    //     description: data.description,
    //     restricted : data.Restricted,
    //     workspaceId : localStorage.getItem("workspace_id")
    //   })
    //   .then(
    //     (response) => {
    //       console.log(response);
    //       handleClose();
    //       setProgress(100);
    //       getAllHolidays();
    //       reset({ ...data });
    //     },
    //     (error) => {
    //       console.log(error.response);
    //       toast.error(error.response.data.error, {
    //         theme: "colored",
    //       });
    //       setProgress(100);

    //       if (error.response.status === 401) {
    //         window.localStorage.clear();
    //         history("/");
    //       }
    //     }
    //   );
    }
    
  };

  return (
    <div className="add-holiday-heading">
      <div
        className="box-header"
        onClick={handleClickOpen}
        style={{ cursor: "pointer" }}
      >
        <div className="holiday-head" style={{ color: `${secondaryColor}` }}>
          + Add Holiday
        </div>
      </div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        className="add-holiday"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <span style={{ color: `${secondaryColor}` }}>Add Holiday</span>
        </BootstrapDialogTitle>
        <DialogContent dividers className="ps-3 pe-3">
          <form className="form-width" onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-3">
              <label className="form-label">Holiday Name :</label>
              <div className="flex-input w-100">
                <div className="w-100">
                  <input
                    type="text"
                    className="form-control disable-input one"
                    placeholder="Enter Holiday Name"
                    // defaultValue={user.name}
                    {...register("holidayName",{ required: true, shouldUnregister: true })}
                  />
              {/* {console.log(errors)} */}
              {/* {errors ? setCountEntry(0) : console.log(errors, "errors")} */}
                </div>
              </div>
              {errors.holidayName && errors.holidayName.type === "required" && (
                <span style={{ color: "red" }}>
                  Holiday Name can not be blank
                </span>
              )}
            </div>

            <div style={{display:"flex", justifyContent:"space-around", alignItems: "start"}} >
            <div className="mb-3" style={{position:"absolute", left:"35px"}} >
              <label className="form-label">Apply Yearly:</label>
              <div>
                <div className="form-check form-check-inline p-0 m-0">
                  <input
                    type="radio"
                    {...register("ApplyYearly", { required: true, shouldUnregister: true })}
                    value="1"
                  />
                  &nbsp;
                  <span>Yes</span>
                </div>
                &nbsp;
                <div className="form-check form-check-inline p-0 m-0">
                  <input
                    type="radio"
                    {...register("ApplyYearly", { required: true, shouldUnregister: true })}
                    value="0"
                  />
                  &nbsp;
                  <span>No</span>
                </div>
              </div>

              <div>
                {errors.ApplyYearly &&
                  errors.ApplyYearly.type === "required" && (
                    <span style={{ color: "red" }}>
                      Apply Yearly can not be blank
                    </span>
                  )}
              </div>
            </div>
            <div className="mb-3">
              <label className="form-label">Restricted:</label>
              <div>
                <div className="form-check form-check-inline p-0 m-0">
                  <input
                    type="radio"
                    {...register("Restricted", { shouldUnregister: true })}
                    value="1"
                  />
                  &nbsp;
                  <span>Yes</span>
                </div>
                &nbsp;
                <div className="form-check form-check-inline p-0 m-0">
                  <input
                    type="radio"
                    {...register("Restricted", { shouldUnregister: true })}
                    value="0"
                  />
                  &nbsp;
                  <span>No</span>
                </div>
              </div>

              <div>
                {/* {errors.Restricted &&
                  errors.Restricted.type === "required" && (
                    <span style={{ color: "red" }}>
                      Apply Yearly can not be blank
                    </span>
                  )} */}
              </div>
            </div>
            </div>
            {/* <div className="mb-3">
              <label className="form-label">Apply Yearly:</label>
              <div>
                <div className="form-check form-check-inline p-0 m-0">
                  <input
                    type="radio"
                    {...register("ApplyYearly", { required: true, shouldUnregister: true })}
                    value="1"
                  />
                  &nbsp;
                  <span>Yes</span>
                </div>
                &nbsp;
                <div className="form-check form-check-inline p-0 m-0">
                  <input
                    type="radio"
                    {...register("ApplyYearly", { required: true, shouldUnregister: true })}
                    value="0"
                  />
                  &nbsp;
                  <span>No</span>
                </div>
              </div>

              <div>
                {errors.ApplyYearly &&
                  errors.ApplyYearly.type === "required" && (
                    <span style={{ color: "red" }}>
                      Apply Yearly can not be blank
                    </span>
                  )}
              </div>
            </div> */}

            <div className="mb-3">
              <label className="form-label">Date</label>
              <div className="flex-input w-100">
                <div className="w-100">
                  <DatePicker
                    selected={holidayDate}
                    className="date-input w-100"
                    onChange={(date) => setholidayDate(date)}
                  />

                  <input
                    type="text"
                    className="form-control disable-input"
                    disabled
                    placeholder="Select Holiday Date"
                    value={
                      holidayDate && moment(holidayDate).format("YYYY-MM-DD")
                    }
                  />
                </div>
                {/* <AppsIcon className="date-icon" /> */}
              </div>
              {/* {holidayDate === null && ( */}

                <div>
                  {dateError && (
                    <span style={{ color: "red" }}>{dateError}</span>
                  )}
                </div>
              {/* )} */}
            </div>

            <div className="mb-3">
              <label className="form-label">Description</label>
              <textarea
                className="form-control"
                placeholder="Enter Description"
                rows={4}
                {...register("description", { required: true, shouldUnregister: true })}
              />
              <div>
                {errors.description &&
                  errors.description.type === "required" && (
                    <span style={{ color: "red" }}>
                      Description can not be blank
                    </span>
                  )}
              </div>
            </div>

            <button
              type="submit"
              className="btn submit-form"
              style={{ backgroundColor: `${secondaryColor}` }}
              onClick={() => {
                if (holidayDate === "") {
                  setdateError("Holiday Date can not be blank");
                } else {
                  setdateError("");
                }
                // if(errors){
                  // setCountEntry(countRntry + 1);
                  // if(countRntry === 1){
                  //   setIsDisable(true)
                  // }
                  // console.log(countRntry)
                // }else{
                  // console.log(errors)
                // }
              }}
              
              // disabled = {isDisable}
              // onDoubleClick= {()=>{
              //   alert("Only one holiday can be set at once.")
              //   disabled
              // }}
              disabled= {isDisable}
            >
              Submit
            </button>
          </form>
        </DialogContent>
      </BootstrapDialog>
      <LoadingBar
        color="black"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
        loaderSpeed={3000}
        waitingTime={1000}
      />
    </div>
  );
}

export default React.memo(AddHoliday);
