import { useState, useEffect, useContext } from "react";
import useApi from "../../../Component/services/api";
import { useForm } from "react-hook-form";
import ProjectMenu from "../../dev-reports/ProjectMenu/ProjectMenu";
import ClientMenu from "../../dev-reports/ClientMenu/ClientMenu";
import UserMenu from "../../dev-reports/UserMenu/UserMenu";
import StartEndDate from "../../dev-reports/StartEndDate/StartEndDate";
import axios from "axios";
import "./ReportFilter.scss";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { NewLeaveContext } from "../../../context/NewLeaveContext";
import { ColorContext } from "../../../context/ColorContext";
import DatePicker from "react-datepicker";
import Popover from "@mui/material/Popover";
import moment from "moment";

const NewLeaveReportFilter = ({
  getAllProjectId,
  getClient,
  getAllUser,
  setstartDate,
  setendDate,
  setgetAllProjectId,
  setgetClient,
  setgetAllUser,
  setProgress,
  NotShowRangePicker,
}) => {
  const api = useApi();
  const { register, handleSubmit } = useForm();
  const [user, setUser] = useState([]);
  const [client, setClient] = useState([]);
  const [project, setProject] = useState([]);
  const history = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const currentYear = new Date().getFullYear();
  const [currentMonth, setCurrentMonth] = useState(
    (new Date().getMonth() + 1).toString().padStart(2, "0")
  );

  const [monthApi, setMonthApi] = useState(new Date());
  const [inputValue, setInputValue] = useState("");
  const [newLeaveData, setNewLeaveData] = useContext(NewLeaveContext);
  const [secondaryColor] = useContext(ColorContext);

  const today = new Date();
  let lastSixMonths = [];

  for (var i = 5; i >= 0; i -= 1) {
    const date = new Date(today.getFullYear(), today.getMonth() - i, 1);
    lastSixMonths.push({
      monthValues: moment(date).format("YYYY-MM"),
      monthLabels: moment(date).format("MMMM-YYYY"),
    });
  }

  const monthData = lastSixMonths.reverse();
  // console.log(monthData)

  const handleClickOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  function handleMonthDataChange(e) {
    window.localStorage.setItem("monthFilterApi", e.target.value);
    setMonthApi(e.target.value);
    setInputValue(e.target.value);
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setProgress(80);
    axios
      .all([
        api.get("v1/projectNames/"),
        api.get("v1/clientNames/"),
        api.get("v1/userNames/"),
      ])
      .then(
        (response) => {
          //   console.log("response is", response);
          setProject(response[0].data.project2);
          setClient(response[1].data);
          setUser(response[2].data);
          setProgress(100);
        },
        (error) => {
          // console.log(error.response);
          setProgress(100);
          if (error.response.status === 401) {
            window.localStorage.clear();
            history("/");
          }
        }
      );
  }, []);

  const onSubmit = (data) => {
    // console.log(data);
    setProgress(80);
    let monthFilterApi = window.localStorage.getItem("monthFilterApi");
    api
      .get(
        `v1/leaveBalanceReport?month=${monthFilterApi}&userID=${
          getAllUser.length === 0 ? undefined : getAllUser
        }`
      )
      .then(
        (response) => {
          setProgress(100);
          setNewLeaveData(response.data);
        },
        (error) => {
          // console.log(error);
          setNewLeaveData([]);
          setProgress(100);
          if (error.response.status === 401) {
            window.localStorage.clear();
            history("/");
          }
        }
      );
  };

  return (
    <div className="ReportFilter">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex-inputs">
          <ProjectMenu
            ProjectList={project}
            getAllProjectId={getAllProjectId}
            setgetAllProjectId={setgetAllProjectId}
          />

          <ClientMenu
            ClientList={client}
            getClient={getClient}
            setgetClient={setgetClient}
          />

          <UserMenu
            userList={user}
            getAllUser={getAllUser}
            setgetAllUser={setgetAllUser}
          />

          {NotShowRangePicker ? (
            <>
              <div className="inner-Input">
                <input
                  type="text"
                  value={inputValue}
                  className="form-control"
                  placeholder={"Select Month"}
                  aria-describedby={id}
                  onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                  }}
                  onChange= {(e)=>{
                    // console.log(e)
                  }}
                />
                <i className="fas fa-caret-down"></i>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  disableAutoFocus={true}
                  disableEnforceFocus={true}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  className="devSearchNew"
                >
                  <ul className="project-list">
                    {monthData.map((list, index) => (
                      <li key={index}>
                        <label className="container m-0">
                          {list.monthLabels}
                          <input
                            type="radio"
                            name="colorTheme"
                            className="userCheck"
                            value={list.monthValues}
                            onChange={handleMonthDataChange}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </li>
                    ))}
                  </ul>
                </Popover>
              </div>
            </>
          ) : (
            <></>
          )}

          {NotShowRangePicker ? (
            <></>
          ) : (
            <div className="inner-Input">
              <StartEndDate
                setstartDate={setstartDate}
                setendDate={setendDate}
              />
            </div>
          )}
          {NotShowRangePicker ? (
            <></>
          ) : (
            <div className="inner-search-Input">
              <input
                type="text"
                className="form-control"
                placeholder="Enter Description"
                {...register("description")}
              />
            </div>
          )}

          <div className="go-button">
            <button
              className="btn"
              type="submit"
              style={{ color: `${secondaryColor}` }}
            >
              GO
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default NewLeaveReportFilter;
