import React, { useEffect, useState, useContext, useRef } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import "./UpdateProjects.scss";
import { useForm } from "react-hook-form";
import useApi from "../../../Component/services/api";
import LoadingBar from "react-top-loading-bar";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import EditIcon from "../../../assets/images/icons/edit.png";
import { useNavigate } from "react-router-dom";
import { ColorContext } from "../../../context/ColorContext";
import axios from "axios";
import { toast } from "react-toastify";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          style={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function UpdateProjects({
  projects,
  projectData,
  getAllProjects,
}) {
  const api = useApi();
  const [open, setOpen] = React.useState(false);
  const [applyDate, setapplyDate] = useState("");
  const [progress, setProgress] = useState(0);
  const [personName, setPersonName] = useState([]);
  const [clients, setClients] = useState([]);
  const [users, setUsers] = useState([]);
  const [names, setNames] = useState([]);
  const [uniqueName, setUniqueName] = useState([]);
  const [warnError, setwarnError] = useState("");
  const [secondaryColor] = useContext(ColorContext);
  const [allSelected, setAllSelected] = useState(false);
  const [alreadyAllSelected, setalreadyAllSelected] = useState(false);
  const history = useNavigate();
  const [checkAll, setCheckedALl] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [allUsersSelected, setallUsersSelected] = useState(false);
  const selectAllRef = useRef(null);
  useEffect(() => {
    // console.log(projects, "projects from redux");
    // console.log(uniqueName, "UQNAMW");
    // console.log(projects.team, "only project");
    const length = projects.team.filter((el) => {
      return el.name !== "Select All";
    });
    // console.log(length.length, projectData.team.length, "len");
    if (length.length === projectData.team.length) {
      // console.log("is changeing to true");
      setAllSelected(true);
      setallUsersSelected(true);
      // projectData.team = [...projectData.team, "all selected!"]
      const data = [...projectData.team, "all selected!"];
      // console.log(projectData.team, data, "projetc team");
    }
    setPersonName(projectData.team);
    setSelectedUsers(projectData.team);

    // console.log(projects, projectData, "hey there i am here");
    if (projects.clients) {
      setClients(projects.clients);

      // console.log(projects.users, "project users");
      // projects.users.unshift({
      //   name: "Select All",
      //   userID: "all selected!",
      // });
      // projects.users = [{name: "Select All",
      // userID: "all selected!"}, ...projects.users
      // ]

      const uniqueArray1 = projects.users.filter((value, index) => {
        const _value = JSON.stringify(value);
        // console.log(value, "value");
        return (
          index ===
          projects.users.findIndex((obj) => {
            return JSON.stringify(obj) === _value;
          })
        );
      });
      // console.log(uniqueArray1, "unnique 1");
      const uniqueArray = uniqueArray1.filter((el) => {
        return !el.userID;
      });
      // uniqueArray.unshift({
      //   name: "Select All",
      //   userID: "all selected!",
      // });
      // console.log(uniqueArray, "UniqueArray");

      setUsers(uniqueArray);
      let userID = uniqueArray.map((user) => {
        return user._id;
      });
      let NewUserid = userID.filter((el) => {
        return el !== undefined;
      });

      // console.log(NewUserid, "USERIDD");
      setNames(NewUserid);

      // let teamUsers = projects.team.filter((data, index) => {
      //   return data.projectID === projectData.projectID;
      // });

      // let differentiateTeam = teamUsers.map((data) => {
      //   return data.team;
      // });
      // let totalName = differentiateTeam.join(", ").split(",");
      // let filteruserID = uniqueArray.filter((data, index) => {
      //   return totalName.includes(data.name);
      // });

      // let uniqueIds = filteruserID.map((data) => {
      //   return data.userID;
      // });

      // console.log(filteruserID);
      // console.log(uniqueIds);
      setUniqueName(uniqueArray);
      // if (uniqueArray.length === uniqueIds.length + 1) {
      //   setPersonName(() => {
      //     return [...uniqueIds, "all selected!"];
      //   });
      // } else {
      //   setPersonName(uniqueIds);
      //   console.log(uniqueIds, "uqIDS")
      // }
    }
  }, [projects, allSelected]);

  const handleUserSelection = (event) => {
    // console.log(event.target.value, "handleUserSelection")
    // console.log(selectedUsers, "handel usr select");
    if (!event.target.value.includes("all")) {
      setSelectedUsers(event.target.value);
    }
    // console.log(event.target.value, "value")
    // if(event.target.value.includes("all selected")){
    //   console.log("first")
    //   if(allSelected === true){
    //     setSelectedUsers([])
    // // console.log(allUsersSelected)
    //     setallUsersSelected(!allUsersSelected)

    //   }
    //   if(allSelected === false){
    //     console.log("Not wala case")
    //     console.log(projects.team)
    //     const data = projects.team.map((user) => user._id)
    //     console.log(data, "data")
    //     setSelectedUsers(data);
    //     setallUsersSelected(true)
    //   }
    // }else{
    //   setSelectedUsers(event.target.value);
    //   if(selectedUsers.length === users.length){
    //     setallUsersSelected(true)
    //   }
    // }
    // console.log(allUsersSelected)
  };
  const handleSelectAll = (event) => {
    const isChecked = event.target.checked;
    // console.log(isChecked, selectedUsers, "inside handel select all");
    if (isChecked) {
      setSelectedUsers(projects.team.map((user) => user._id));
    } else {
      setSelectedUsers([]);
    }
  };

  const handelClick = (event) => {
    // console.log(event.target.outerText, "click")
    // let isPresentInNamesArray= false;
    // uniqueName.map((el)=>{
    //   if(el.name === event.target.outerText){
    //     isPresentInNamesArray = true
    //   }
    // })
    // if(event.target.outerText === "Select All" && allSelected === true){
    //   console.log("get it")
    //   setPersonName([]);
    //   setAllSelected(false)
    // }else if(event.target.outerText !== "Select All" && isPresentInNamesArray && allSelected === true){
    //   console.log("Situation 2", personName, event.target.outerText)
    //   let ID = ""
    //   uniqueName.map((el)=>{
    //     if(el.name === event.target.outerText){
    //       ID = el._id
    //     }
    //   })
    //   console.log(ID)
    //   const newArray = personName.filter((el)=>{
    //     return el != ID && el != "all selected!"
    //   })
    //   setPersonName(newArray)
    //   setAllSelected(false)
    //   // console.log(newArray, "newArray")
    //   // console.log(uniqueName, "personName in click")
    // }
  };

  const handleChange = (event) => {
    // console.log(event)
    // const {
    //   target: { value },
    // } = event;
    // console.log(value, "in event");
    // if (value.includes("all selected!")) {
    //   setAllSelected(true)
    //   setPersonName((prev) => {
    //     return [...names, "all selected!"];
    //   });
    // }else if (value.length === uniqueName.length-1){
    //   setAllSelected(true)
    //   setPersonName((prev) => {
    //     return [...names, "all selected!"];
    //   });
    // }
    //  else {
    //   setPersonName(typeof value === "string" ? value.split(",") : value);
    //   // console.log(personName)
    // }
    // if(value.includes("all selected!")&& value.length === uniqueName.length-1){
    //   console.log(value, "here is value")
    //  const  newvalue=  value.filter((e)=>{
    //     return e !== "all selected!"
    //   })
    //   setPersonName(typeof newvalue === "string" ? newvalue.split(",") : newvalue);
    // }
    // setPersonName(
    //   // On autofill we get a stringified value.
    //   typeof value === "string" ? value.split(",") : value
    // );
  };
  // const handleChange = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   console.log(value, "values in event");
  //   if (value.includes("all selected!") && !alreadyAllSelected ) {
  //     setPersonName((prev) => {
  //       setalreadyAllSelected(true)
  //       return [...names, "all selected!"];
  //     });
  //   }else if(value.includes("all selected!") && alreadyAllSelected ){
  //     setPersonName([])
  //     setalreadyAllSelected(false)
  //   }
  //    else {
  //     setPersonName(typeof value === "string" ? value.split(",") : value);
  //   }
  //   // setPersonName(
  //   //   // On autofill we get a stringified value.
  //   //   typeof value === "string" ? value.split(",") : value
  //   // );
  // };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const handleClickOpen = () => {
    // console.log(projects, "projects");
    // console.log(projectData, "projectData");
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handelRfresh = () => {
    window.location.reload();
  };

  const onSubmit = (data) => {
    // console.log(data);

    let allUserId = personName.filter((data) => {
      return data !== "all selected!";
    });
    // console.log(allUserId);
    setProgress(80);

    // console.log(data, "Submitted")
    // console.log(names, "names")
    // console.log(personName, "personname")
    // console.log(uniqueName, "uuqqnname")
    let alreadyPresent = false;
    if (projects.project.length > 0) {
      projects.project.map((el) => {
        if (
          el.projectName === data.ProjectTitle &&
          data.ProjectTitle !== projectData.projectName
        ) {
          alreadyPresent = true;
        }
      });
      // console.log(data, "data", personName.length);
    }
    if (
      data.TimeEstimation &&
      data.ProjectTitle &&
      data.Description &&
      data.Client &&
      personName.length !== 0
    ) {
      if (!alreadyPresent) {
        axios
          .patch(
            "https://api.trackify.ai/projects/projectupdated",
            {
              projectId: projectData._id,
              estimatedHours: data.TimeEstimation,
              projectName: data.ProjectTitle,
              projectDescription: data.Description,
              clientID: data.Client,
              team: selectedUsers,
            }
          )
          .then(
            (response) => {
              // console.log(response);
              if (response.data === "All Feilds are required.") {
                toast.error("All Feilds are required.", {
                  theme: "colored",
                  position: "top-left",
                });
              }
              // else if(response.data === "Project updated successfully."){
              //   toast.success("Project updated successfully.", {
              //     theme: "colored",
              //     position: "top-left",
              //   });
              // }
              setProgress(100);
              handleClose();
              setwarnError("");
              setPersonName([]);
              reset();
              handelRfresh();
              getAllProjects();
            },
            (error) => {
              // console.log(error);
              setwarnError(error.response.data.error);
              setProgress(100);

              if (error.response.status === 401) {
                window.localStorage.clear();
                history("/");
              }
            }
          );
      } else {
        setProgress(100);
        toast.error("Project with this name already exist.", {
          theme: "colored",
          position: "top-left",
        });
      }
    } else {
      toast.error("All Feilds are required.", {
        theme: "colored",
        position: "top-left",
      });
      setProgress(100);
    }

    // api
    //   .put("v1/project/", {
    //     clientID: data.Client,
    //     estimatedHours: data.TimeEstimation,
    //     manageBy: data.ProjectManager,
    //     projectDescription: data.Description,
    //     projectID: projectData.projectID,
    //     projectName: data.ProjectTitle,
    //     team: allUserId,
    //   })
    //   .then(
    //     (response) => {
    //       // console.log(response);
    //       setProgress(100);
    //       handleClose();
    //       setwarnError("");
    //       setPersonName([]);
    //       reset();
    //       getAllProjects();
    //     },
    //     (error) => {
    //       console.log(error);
    //       setwarnError(error.response.data.error);
    //       setProgress(100);

    //       if (error.response.status === 401) {
    //         window.localStorage.clear();
    //         history("/");
    //       }
    //     }
    //   );
  };

  // const handelItemClick = (event)=>{
  //   console.log(allUsersSelected)
  //   if(allUsersSelected){
  //     setSelectedUsers([])
  //     setallUsersSelected(!allUsersSelected)
  //   }else{
  //     setSelectedUsers(projects.team.map((user) => user._id));
  //     setallUsersSelected(!allUsersSelected)
  //   }
  //   console.log(selectedUsers)
  //   console.log(event)
  // }
  const handleMenuItemClick = (event) => {
    if (event.target.tagName !== "INPUT") {
      // console.log(event.target.tagName, "input");
      // console.log(selectAllRef.current.checked, "selectALLRef");
      const allCheckbox = selectAllRef.current;
      allCheckbox.checked = !selectAllRef.current.checked;
      // console.log(allCheckbox.checked, "ALLCHECK");
      handleSelectAll({ target: allCheckbox });
    }
  };
  return (
    <div className="UpdateProject-type-content">
      <div onClick={handleClickOpen}>
        <img
          src={EditIcon}
          alt="edit"
          width="25"
          style={{
            cursor: "pointer",
          }}
        />
      </div>

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        className="Update-Project"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => {
            setwarnError("");
            handleClose();
          }}
        >
          <span style={{ color: `${secondaryColor}` }}>Update Project</span>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <form className="form-width" onSubmit={handleSubmit(onSubmit)}>
            {warnError && <div className="client-error">{warnError}</div>}
            <div className="mb-3">
              <div className="flex-input w-100">
                <div className="w-100">
                  <input
                    type="text"
                    placeholder="Project Title"
                    className="form-control disable-input"
                    defaultValue={projectData.projectName}
                    {...register("ProjectTitle")}
                  />
                </div>
              </div>
            </div>

            <div className="mb-3">
              <div className="flex-input w-100">
                <div className="w-100">
                  <input
                    type="number"
                    placeholder="Time Estimation"
                    className="form-control disable-input"
                    {...register("TimeEstimation")}
                    defaultValue={parseInt(projectData.estimatedHours)}
                  />
                </div>
              </div>
            </div>

            <div className="mb-3">
              <div className="flex-input w-100">
                <div className="w-100">
                  <input
                    type="text"
                    placeholder="Description"
                    className="form-control disable-input"
                    {...register("Description")}
                    defaultValue={projectData.projectDescription}
                  />
                </div>
              </div>
            </div>

            <div className="mb-3">
              <select
                className="form-select"
                aria-label="Default select example"
                {...register("Client")}
              >
                <option value="">Select Client</option>
                {clients.map((client, index) => {
                  if (client.clientName !== "Select All")
                    return (
                      <option
                        key={index}
                        value={client._id}
                        selected={
                          projectData.clientID === client._id ? true : false
                        }
                      >
                        {client.clientName}
                      </option>
                    );
                })}
              </select>
            </div>

            {/* <div className="mb-3">
              <select
                className="form-select"
                aria-label="Default select example"
                {...register("ProjectManager")}
              >
                <option value="">Select Project Manager</option>
                {users.map((user, index) => {
                  if (user.name !== "Select All")
                    return (
                      <option
                        key={index}
                        value={user.userID}
                        // selected={
                        //   projectData.userID === user.userID ? true : false
                        // }
                      >
                        {user.name}
                      </option>
                    );
                })}
              </select>
            </div> */}

            <div className="mb-3">
              <FormControl sx={{ width: "100%" }}>
                <InputLabel
                  id="demo-multiple-name-label"
                  style={{
                    fontWeight: "normal",
                    color: "#212529",
                    fontFamily: "Source Sans Pro",
                  }}
                >
                  Select Team
                </InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={selectedUsers}
                  onChange={handleUserSelection}
                  renderValue={(selected) => {
                    if (selected.length === users.length) {
                      return [`All Selected!`];
                    } else if (selected.length > 0) {
                      return [`${selected.length} selected`];
                    } else {
                      return ['Select Team'];
                    }
                  }}
                  // {(selected) => (
                  //   <div>
                  //     {selected.length === users.length
                  //       ? "All users"
                  //       : selected.length < 1 ? "Select" : selected.length + " selected" }
                  //   </div>
                  // )}
                >
                  <MenuItem
                    // className="inputOptionList"
                    style={{
                      borderBottom: "1px solid #f5f5f5",
                      padding: "0",
                      margin: "0 0px",
                    }}
                    key="all"
                    value="all"
                    onClick={handleMenuItemClick}
                    input={<Checkbox inputRef={selectAllRef} />}
                  >
                    <Checkbox
                      inputRef={selectAllRef}
                      checked={selectedUsers.length === projects.team.length}
                      onChange={handleSelectAll}
                    />
                    Select All
                  </MenuItem  >
                  {projects.team.map((user) => (
                    <MenuItem 
                    // className="inputOptionList"
                    style={{
                      borderBottom: "1px solid #f5f5f5",
                      padding: "0",
                      margin: "0 23px",
                    }} key={user._id} value={user._id}>
                      <Checkbox checked={selectedUsers.includes(user._id)} />
                      {user.name}
                    </MenuItem>
                  ))}
                </Select>
                {/* <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  displayEmpty
                  value={projects.team}
                  onClick={handelClick}
                  onChange={handleChange}
                  input={<OutlinedInput label="Tag" />}
                  renderValue={(selected) => {
                    if (selected.includes("all selected!")) {
                      return [`all selected!`];
                    } else if (selected.length > 0) {
                      return [`${selected.length} selected`];
                    } else {
                      return ['Select Team'];
                    }
                  }}
                  MenuProps={MenuProps}
                >
                  <MenuItem
                      className="inputOptionList"
                      key={1}
                      value={"all selected"}
                      style={{
                        borderBottom: "1px solid #f5f5f5",
                        padding: "0",
                        margin:  "0 0px",
                      }}
                    >
                      <Checkbox
                        // checked={personName.indexOf("all selected") > -1}
                        checked={true}
                        onChange={(e) => {
                            if (e.target.checked === false) {
                              setPersonName([]);
                            }
                            setCheckedALl(!checkAll)
                        }}
                      />
                      <ListItemText primary={"Select All"} />
                    </MenuItem>
                  { projects.team &&  projects.team?.map((name, index) => (
                    <MenuItem
                      className="inputOptionList"
                      key={name.name}
                      value={name.name}
                      style={{
                        borderBottom: "1px solid #f5f5f5",
                        padding: "0",
                        margin: name.name === "all selected!" ? "0 0px" : "0 23px",
                      }}
                    >
                      <Checkbox
                        checked={names.indexOf(name._id) > -1}
                        // checked={true}
                        onChange={(e) => {
                          if (name.name === "all selected!") {
                            if (e.target.checked === false) {
                              setPersonName([]);
                            }
                          }
                        }}
                      />
                      <ListItemText primary={projects.team[index].name} />
                    </MenuItem>
                  ))}
                </Select> */}
              </FormControl>
            </div>
            {/* <div className="mb-3">
              <FormControl sx={{ width: "100%" }}>
                <InputLabel
                  id="demo-multiple-name-label"
                  style={{
                    fontWeight: "normal",
                    color: "#212529",
                    fontFamily: "Source Sans Pro",
                  }}
                >
                  Select Team
                </InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  displayEmpty
                  value={personName}
                  onChange={handleChange}
                  input={<OutlinedInput label="Tag" />}
                  renderValue={(selected) => {
                    if (selected.includes("all selected!")) {
                      return [`all selected!`];
                    } else if (selected.length > 0) {
                      return [`${selected.length} selected`];
                    } else {
                      return ['Select Team'];
                    }
                  }}
                  MenuProps={MenuProps}
                >
                  {names.map((name, index) => (
                    <MenuItem
                      className="inputOptionList"
                      key={name}
                      value={name}
                      style={{
                        borderBottom: "1px solid #f5f5f5",
                        padding: "0",
                        margin: name === "all selected!" ? "0 0px" : "0 23px",
                      }}
                      onClick= {(e)=>{
                        console.log("heyyy")
                      }}
                    >
                      <Checkbox
                        checked={personName.indexOf(name) > -1}
                        onClick={(e) => {
                          console.log("change")
                          if (name === "all selected!") {
                            if (e.target.checked === false) {
                              setPersonName([]);
                            }
                          }
                        }}
                      />
                      <ListItemText primary={uniqueName[index].name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div> */}

            <button
              type="submit"
              className="btn submit-form"
              style={{ backgroundColor: `${secondaryColor}` }}
            >
              Update
            </button>
          </form>
        </DialogContent>
      </BootstrapDialog>

      <LoadingBar
        color="black"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
        loaderSpeed={3000}
        waitingTime={1000}
      />
    </div>
  );
}
