const initialState = {
    iconChange: false,
    singleProject: {},
  };
  
  const GetSingleProject = (state = initialState, action) => {
    switch (action.type) {
      case "GET_SINGLE_PROJECT":
        return (state = action.payload);
      default:
        return state;
    }
  };
  
  export default GetSingleProject;
  