import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "./LeaveManagement.scss";


const theme = createTheme({
  components: {
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: "#fc6b6c",
          height: 2,
        },
      },
    },
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const LeaveManagement = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="leave-management">
      <div className="box-header">
        <div className="page-title">Leave Management</div>
      </div>

      <Box sx={{ width: "100%" }}>
        <ThemeProvider theme={theme}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                label="Leave"
                style={{
                  textTransform: "capitalize",
                  fontFamily: "Source Sans Pro",
                  color: "#444",
                }}
                {...a11yProps(0)}
              />
              <Tab
                label="Balance Leaves"
                style={{
                  textTransform: "capitalize",
                  fontFamily: "Source Sans Pro",
                  color: "#444",
                }}
                {...a11yProps(1)}
              />
            </Tabs>
          </Box>
        </ThemeProvider>
        <TabPanel value={value} index={0}>
          <div className="table-content">
            <div style={{ borderBottom: "1px solid #f4f4f4" }}>
              <table className="table">
                <tbody>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col" className="text-center">
                      Leave Type
                    </th>
                    <th scope="col" className="text-center">
                      Description
                    </th>

                    <th scope="col" className="text-center">
                      Date From
                    </th>

                    <th scope="col" className="text-center">
                      Date To
                    </th>

                    <th scope="col" className="text-center">
                      Days
                    </th>

                    <th scope="col" className="text-center">
                      Approve
                    </th>

                    <th scope="col" className="text-center">
                      Disapprove
                    </th>
                  </tr>

                  {/* <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr> */}

                  <tr className="no-record-found">
                    <td align="center" colSpan={8}>
                      No Record Found
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div className="table-content">
            <table className="table">
              <tbody>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col" className="text-center">
                    Permitted Leave
                  </th>
                  <th scope="col" className="text-center">
                    Leaves
                  </th>

                  <th scope="col" className="text-center">
                    Adjust Leave Count
                  </th>

                  <th scope="col" className="text-center">
                    Balance
                  </th>

                  <th scope="col" className="text-center">
                    Leave Adjustment
                  </th>

                  <th scope="col" className="text-center">
                    Log
                  </th>
                </tr>

                {/* <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr> */}
              </tbody>
            </table>
          </div>
        </TabPanel>
      </Box>
    </div>
  );
};

export default LeaveManagement;
