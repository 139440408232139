import React, { useContext, useState, useEffect } from "react";
import "./Dashboard.scss";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DashboardProject from "./DashboardProject";
import { toast } from "react-toastify";
import DashboardTimer from "./DashboardTimer";
import moment from "moment";
import useApi from "../services/api";
import LoadingBar from "react-top-loading-bar";
import { useDispatch, useSelector } from "react-redux";
import { DashboardApi, SingleProject } from "../../Actions/DashboardApi";
import { useNavigate } from "react-router-dom";
import EditIcon from "../../assets/images/icons/edit.png";
import SyncIcon from "@mui/icons-material/Sync";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DashboardDescription from "./DashboardDescription";
import ThemeIcon from "../../assets/images/icons/themeicon.jpeg";
import DashboardThemeModal from "./DashboardThemeModal";
import CloseIcon from "@mui/icons-material/Close";
import { DataContext } from "../../context/DataContext";
import { ColorContext } from "../../context/ColorContext";
import { TextColorContext } from "../../context/TextColorContext";
import { TextHoverContext } from "../../context/TextHoverContext";
import { TimerContext } from "../../context/TimerContext";
import { ProjectContext } from "../../context/ProjectContext";
import { ShowEditorContext } from "../../context/ShowEditorContext";
// import {play} from "../../assets/images/header/play-solid.svg"
import play from "../../assets/images/header/play-button.png";
import cancel from "../../assets/images/header/plus.png";
import {
  Box,
  Drawer,
  List,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import ThemeIcons from "../../Common/ThemeIcon";
import { getEntries, getTheme, timerEditor } from "../../Reducers/dashboardSlice";

export default function DashBoard() {
  const api = useApi();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMenuModelOpen, setIsMenuModelOpen] = useState(0);
  const [progress, setProgress] = useState(0);
  const [last_date, setLast_date] = useState("");

  const [durationData, setDurationData] = useState([]);
  const [mainDuration, setMainDuration] = useState([]);
  const dispatch = useDispatch();
  const [primaryColor, setPrimaryColor] = useContext(DataContext);
  const [secondaryColor, setSecondaryColor] = useContext(ColorContext);
  const [PrimaryTextColor, setPrimaryTextColor] = useContext(TextColorContext);
  const [PrimaryTextHover, setPrimaryTextHover] = useContext(TextHoverContext);
  const [onTimerDashboard, setonTimerDashboard] = useContext(TimerContext);
  const [ProjectDashboard, setProjectDashboard] = useContext(ProjectContext);
  const [showEditor, setShowEditor] = useContext(ShowEditorContext);
  const [workspaceName, setWorkSpaceName] = useState("");
  const [workspace, setWorkSpace] = useState([]);
  const [workspaceToggel, setWorkspaceToggel] = useState(false);
  const [themeToggel, setThemeToggel] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [workspaceId, setWorkSpaceId] = useState("");

  const updateEntries = useSelector((state) => state.UpadateDashboard);
  const userInfo = window.localStorage.getItem("tt-userinfo");

  const userInfoRole = userInfo && JSON.parse(userInfo).role;

  const [checkForRole, setCheckForRole] = useState([]);

  const projectDetails = ProjectDashboard;

  const handleClickOpen = () => {
    // console.log("handel open called");
    setOpen(true);
  };

  const handleClose = () => {
    // console.log("handel close called");
    setOpen(false);
  };

  const themeStateData = useSelector((state)=>{
    // return state.dashboards.theme
    return state.dashboards.test[localStorage.getItem("workspace_id")]
  })
  // console.log(themeStateData, "themeStateData")

  useEffect(() => {
    // setTimeout(() => {
    //   TimerEditorStatus();
    // }, 2000);
    // dispatch(timerEditor())
    const data = JSON.parse(localStorage.getItem("workspace"));
    setWorkSpace(data);
    const ID = localStorage.getItem("workspace_id");
    setWorkSpaceId(ID);
    // console.log(data, "data");
    // console.log()
  }, [open]);

  useEffect(()=>{
    if( themeStateData && themeStateData.length !== 0){
      window.localStorage.setItem(
        "primary_color_theme",
        themeStateData.primaryColor
      );
      setPrimaryColor(themeStateData.primaryColor);
      window.localStorage.setItem(
        "secondary_color_theme",
        themeStateData.secondaryColor
      );
      setSecondaryColor(themeStateData.secondaryColor);
      window.localStorage.setItem("text_color_theme", themeStateData.textColor);
      setPrimaryTextColor(themeStateData.textColor);
      window.localStorage.setItem("textHover_color_theme", themeStateData.textHover);
      setPrimaryTextHover(themeStateData.textHover);
      // console.log(themeStateData, "themestateData is DS")
    }else{
      setTimeout(() => {
        dispatch(getTheme());
      }, 2000);
    }
  }, [themeStateData])



  const timerStatusInStore = useSelector((state)=>{
    return state.dashboards.timerStatus
  })
  // console.log(timerStatusInStore, "timerStatusInStore")

  useEffect(()=>{
    if(timerStatusInStore !== null){
      setShowEditor(timerStatusInStore)
    }else{
      dispatch(timerEditor())
    }
  }, [timerStatusInStore])

  // console.log(workspace, "workspace");

  // function TimerEditorStatus() {
  //   api.post("https://api.trackify.ai/setting/timeEditorStatus",{
  //     workspaceId : localStorage.getItem("workspace_id")
  //   }).then(
  //     (response) => {
  //       // console.log(response)
  //       console.log(response, "setting")
  //       // console.log(response)
  //       if (response.data.editable === false) {
  //         setShowEditor(false);
  //       } else {
  //         setShowEditor(true);
  //       }
  //     },
  //     (error) => {
  //       console.log(error);
  //     }
  //   );
  // }

  // function getColorThemeAdmin() {
  //   console.log("comeing infdf ")
  //   const workspace_id = localStorage.getItem("workspace_id");
  //   api.get(`https://api.trackify.ai/color/colorsetting/${workspace_id}`).then(
  //     (response) => {
  //       console.log(response, "erws ofd color");
  //       response.data.map((value) => {
  //         // value.selected === 1 &&
  //           window.localStorage.setItem(
  //             "primary_color_theme",
  //             value.primaryColor
  //           );
  //         setPrimaryColor(value.primaryColor);
  //         // value.selected === 1 &&
  //           window.localStorage.setItem(
  //             "secondary_color_theme",
  //             value.secondaryColor
  //           );
  //         setSecondaryColor(value.secondaryColor);
  //         // value.selected === 1 &&
  //           window.localStorage.setItem("text_color_theme", value.textColor);
  //         setPrimaryTextColor(value.textColor);
  //         // value.selected === 1 &&
  //           window.localStorage.setItem(
  //             "textHover_color_theme",
  //             value.textHover
  //           );
  //         setPrimaryTextHover(value.textHover);
  //       });
  //     },
  //     (error) => {
  //       console.log(error);
  //     }
  //   );
  // }

  // function getColorThemeUser() {
  //   const workspace_id = localStorage.getItem("workspace_id");
  //   api.get(`https://api.trackify.ai/color/colorsetting/${workspace_id}`).then(
  //     (response) => {
  //       // console.log(response.data);
  //       response.data.map((value) => {
  //         window.localStorage.setItem(
  //           "primary_color_theme",
  //           value.primaryColor
  //         );
  //         setPrimaryColor(value.primaryColor);
  //         window.localStorage.setItem(
  //           "secondary_color_theme",
  //           value.secondaryColor
  //         );
  //         setSecondaryColor(value.secondaryColor);
  //         window.localStorage.setItem("text_color_theme", value.textColor);
  //         setPrimaryTextColor(value.textColor);
  //         window.localStorage.setItem("textHover_color_theme", value.textHover);
  //         setPrimaryTextHover(value.textHover);
  //       });
  //     },
  //     (error) => {
  //       console.log(error);
  //     }
  //   );
  // }

  useEffect(() => {
    document.body.style.setProperty("--themeColor", primaryColor);
    document.body.style.setProperty("--themeColorSecondary", secondaryColor);
  }, [primaryColor, secondaryColor]);

  {
    // useEffect(() => {
    //   // userInfoRole === "admin" && getColorThemeAdmin();
    //   // userInfoRole !== "admin" && getColorThemeUser();
    //   setTimeout(() => {
    //     getColorThemeAdmin()
    //   }, 2000);

    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);
  }

  
  const history = useNavigate();

  const [data, setData] = useState([]);
  // console.log(data)

  var [timerIs1, setTimerIs1] = useState([]);

  useEffect(() => {
    if (isMenuModelOpen === 1) {
      setIsMenuOpen(false);
      setIsMenuModelOpen(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMenuModelOpen]);

  useEffect(() => {
    var data1 = {};
    // console.log(data, "data")
    if(data.length == 0){
      mainData = []
      setTimerIs1([])
    }
    data.map((value) => {
      if (value.endTime !== "null" && value.endTime !== "") {
        var startTime = moment(value.startTime).format("hh:mm A");
        var endTime = moment(value.endTime).format("hh:mm A");
        var dbDate = moment(value.startTime).format("DD MMM YYYY");
        var startTimeRaw = value.startTime;
        var endTimeRaw = value.endTime;
        var date = dbDate;
        if (!data1[date]) {
          data1[date] = [];
        }
        data1[date].push({
          timerID: value._id,
          description: value.description,
          userID: value.userId,
          startTime: startTime,
          endTime: endTime,
          duration: value.duration,
          projectName: value.projectName,
          dbDate: date,
          projectID: value.projectId,
          startTimeRaw: startTimeRaw,
          endTimeRaw: endTimeRaw,
        });
      }
      setTimerIs1(data1);
    });
  }, [data, last_date, localStorage.getItem("workspace_id")]);

  var mainData = Object.entries(timerIs1);
  
  // console.log(mainData, "maindata");

  // When the user scrolls down 20px from the top of the document, show the button
  const scrollTopBtn = document.getElementById("scrollTopBtn");
  window.onscroll = () => scrollFunction();

  function scrollFunction() {
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      scrollTopBtn.style.display = "block";
    } else {
      scrollTopBtn.style.display = "none";
    }
  }

  function topFunction() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  function getTimeEntries() { 
    setProgress(80);
    dispatch(getEntries())
    const workspaceIdFromLocal = localStorage.getItem("workspace_id");
    const user = JSON.parse(localStorage.getItem("tt-userinfo"))
    const {_id} = user;
    const token = localStorage.getItem("access_token") 
    // console.log(_id, "user")
    // console.log(workspaceIdFromLocal, "IDD")
    api.get(`https://api.trackify.ai/timer/${workspaceIdFromLocal}/${_id}/${token}`).then(
      (response) => {
        setLast_date(response.data.last_date);
        // console.log(response, "time enrty");
        // setProjectDetails(response.data.projects.project2);
        setDurationData(response.data.totalDuration);

        setonTimerDashboard(
          response.data.timer.filter((data) => {
            return data.endTime === null;
          })
        );



        // endTime
        if(response.data.timer.length == 0){
          // console.log("timer is empty now")
          mainData = []
          setData([])
        }
        response.data.timer.forEach((element) => {
          element.endTime = element.endTime === null ? "" : element.endTime;
        });

        setData(response.data.timer);

        dispatch(
          DashboardApi({
            apiUpdate: false,
          })
        );

        dispatch(
          SingleProject({
            iconChange: false,
            singleProject: {},
          })
        );

        setProgress(100);
      },
      (error) => {
        dispatch(
          DashboardApi({
            apiUpdate: false,
          })
        );
        dispatch(
          SingleProject({
            iconChange: false,
            singleProject: {},
          })
        );
        setProgress(100);

        if (error.response.status === 401) {
          window.localStorage.clear();
          history("/");
        }
      }
    );
  }

  useEffect(() => {
    setPrimaryColor(window.localStorage.getItem("primary_color_theme"));
  }, [primaryColor]);

  useEffect(() => {
    setSecondaryColor(window.localStorage.getItem("secondary_color_theme"));
  }, [secondaryColor]);

  useEffect(() => {
    setPrimaryTextColor(window.localStorage.getItem("text_color_theme"));
  }, [PrimaryTextColor]);

  useEffect(() => {
    setPrimaryTextHover(window.localStorage.getItem("textHover_color_theme"));
  }, [PrimaryTextHover]);

  useEffect(() => {
    var totalDurations = durationData.sort(function (a, b) {
      return new Date(b.date) - new Date(a.date);
    });

    setMainDuration(totalDurations);
  }, [durationData]);

  useEffect(() => {
    getTimeEntries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceId]);
  useEffect(() => {
    if (updateEntries.apiUpdate === true) {
      getTimeEntries();
    }
  }, [updateEntries.apiUpdate]);

  const handleDelete = (id) => {
    if (window.confirm("Do you really want to delete this time entry?")) {
      setProgress(80);
      api.delete(`https://api.trackify.ai/timer/delete/${id}`).then(
        (response) => {
          // console.log(response, "res")
          if(response.data === "Project is completed."){
            localStorage.setItem("startTimer", false);
            setProgress(100);
            toast.error("Project in this time entry is marked as completed!", {
              theme: "colored",
              position: "top-left",
            })}
          getTimeEntries();
          setProgress(100);
        },
        (error) => {
          setProgress(100);

          if (error.response.status === 401) {
            window.localStorage.clear();
            history("/");
          }
        }
      );
    }
    // console.log(id, "IDD");
  };

  const UpdatingUserSchema = (userInfoId)=>{
    api.patch("https://api.trackify.ai/user/timerOn",{
     id : userInfoId,
     timerId : localStorage.getItem("timerId")
   }).then((res)=>{
    //  console.log(res.data)
    //  console.log(JSON.parse(localStorage.getItem("tt-userinfo")))
     const localUser = JSON.parse(localStorage.getItem("tt-userinfo"))
     localUser.timerOn = res.data;
     localStorage.setItem("tt-userinfo", JSON.stringify(localUser));
    //  console.log(localUser, "localuser")
   })

}

  const handleContinue = (project) => {
    // console.log(project, "project");
    localStorage.setItem("timerId", project.timerID);
    localStorage.setItem("startTimer", true);
    const startTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
    setProgress(80);

    api
      .post("https://api.trackify.ai/timer/", {
        // duration: null,
        // endTime: null,
        // startTime: startTime,
        projectId: project.projectID,
        projectName: project.projectName,
        description: project.description,
        workspaceId : localStorage.getItem("workspace_id"),
        // timerID: project.timerID,
        userId: project.userID,
      })
      .then(
        (response) => {
          // console.log(response, "response")
          if(response.data === "Project is completed."){
            localStorage.setItem("startTimer", false);
            setProgress(100);
            toast.error("Project in this time entry is marked as completed!", {
              theme: "colored",
              position: "top-left",
            }) 
          }else{
            localStorage.setItem("timerId", response.data._id);
            UpdatingUserSchema(project.userID)
            // console.log(response, "continue data");
            dispatch(
              SingleProject({
                iconChange: true,
                singleProject: {
                  projectID: response.data.projectId,
                  description: response.data.description,
                  timerID: response.data._id.toString(),
                  startTime: response.data.startTime,
                  userID: project.userId,
                },
              })
            );
            setProgress(100);
          }
        },
        (error) => {
          setProgress(100);
          if (error.response.status === 401) {
            window.localStorage.clear();
            history("/");
          }
        }
      );
  };
  const handleLoadMore = () => {
    setProgress(80);
    String(last_date);
    // console.log(last_date);
    const workspaceIdFromLocal = localStorage.getItem("workspace_id");
    const user = JSON.parse(localStorage.getItem("tt-userinfo"))
    const {_id} = user;
    api.get(`https://api.trackify.ai/timer/last_date/${last_date}/${workspaceIdFromLocal}/${_id}`).then(
      (response) => {
        // console.log(response, "LOAD MORE RESPONSE");
        // response.data.timer.forEach((element) => {
        //   element.endTime = element.endTime === null ? "" : element.endTime;
        // });
        data.push(...response.data.timer);
        durationData.push(...response.data.totalDuration);
        setLast_date(response.data.last_date);
        setProgress(100);
      },
      (error) => {
        setProgress(100);

        if (error.response.status === 401) {
          window.localStorage.clear();
          history("/");
        }
      }
    );
  };

  const handelThemeToggel = () => {
    // console.log(themeToggel);
    setThemeToggel(!themeToggel);
    if (!themeToggel) {
      setWorkspaceToggel(false);
    }
    // setWorkspaceToggel(!workspaceToggel)
  };
  const handelWorkspaceToggel = () => {
    // console.log(themeToggel)
    setWorkspaceToggel(!workspaceToggel);
    // setThemeToggel(!themeToggel)
    if (!workspaceToggel) {
      setThemeToggel(false);
    }
  };

  // const handelCreateWorkspace = () => {
  //   const token = localStorage.getItem("access_token");
  //   const body = {
  //     token,
  //     name: workspaceName,
  //     wkspcName: workspaceName,
  //     workspaceType: "personal",
  //     roleId: "Admin",
  //     wkspcId: "NONE",
  //   };
  //   api
  //     .post("https://api.trackify.ai/workspace/create", {
  //       token: token,
  //       name: workspaceName,
  //       wkspcName: workspaceName,
  //       workspaceType: "personal",
  //       roleId: "Admin",
  //       wkspcId: "1234",
  //       status: workspaceName,
  //     })
  //     .then(
  //       (response) => {
  //         // console.log(response);
  //         if (response.data.status === "success") {
  //           localStorage.setItem(
  //             "workspace",
  //             JSON.stringify(response.data.workspace)
  //           );
  //           setOpen(false);
  //         }
  //       },
  //       (err) => {
  //         console.log(err);
  //       }
  //     );
  //   // fetch("/https://api.trackify.ai/workspace/create", {
  //   //   method : "POST",
  //   //   headers : {
  //   //     "Content-Type" : "application/json"
  //   //   },
  //   //   body : JSON.stringify(body)
  //   // }).then((res)=>res.json())
  //   // .then((res)=>console.log(res))
  // };

  useEffect(()=>{

    const getworkspace = ()=>{
       const userINFO =  JSON.parse(localStorage.getItem("tt-userinfo"))
       const {_id} = userINFO;
       api.get(`https://api.trackify.ai/workspace/get/${_id}`)
       .then((res)=>{
        // console.log(res, "resss");
        localStorage.setItem("workspace", JSON.stringify(res.data))
       })
    }
    getworkspace()

  }, [localStorage.getItem("workspace_id")])

  // const handelSwitchWorkspace = (id) => {
  //   localStorage.setItem("workspace_id", id);
  //   setWorkSpaceId(id)
  //   // console.log(id);
  //   setProgress(80);
  //   api
  //     .post("https://api.trackify.ai/workspace/switch", {
  //       id,
  //     })
  //     .then((response) => {
  //       // console.log(response.data[0].user_id);
  //       const data1 = response.data[0].user_id;
  //       setProgress(100);
  //       const data = JSON.parse(localStorage.getItem("tt-userinfo"));
  //       const { _id } = data;
  //       // console.log(_id, "ID");
  //       data1.map((el) => {
  //         if (el.user_id == _id) {
  //           // console.log(el.role);
  //           data.role = el.role;
  //           localStorage.setItem("tt-userinfo", JSON.stringify(data));
  //         }
  //       });

  //       setCheckForRole(response.data[0].user_id);
  //       // handelSwitch()
  //     });

  //   // setWorkSpaceId(id);
  // };

  // const handelSwitch = () => {
  //   const data = JSON.parse(localStorage.getItem("tt-userinfo"));
  //   const { _id } = data;
  //   // console.log(_id, "ID");

  //   // console.log(checkForRole, "checkForRole");
  //   // checkForRole.map((el)=>{
  //   //   if(el.user_id == _id){
  //   //     console.log(el.role)
  //   //   }
  //   // })
  // };

  useEffect(() => {
   return history("/dashboard")
  }, [workspaceId]);

  return (
    <div className="dashboard_content">
      <div className="displayEntries">
        {mainData.map((value, index) => {
          return (
            <div className="time-entries-main" key={index}>
              <ul className="display_date_entries">
                <li className="text-center">
                  <div className="date-info">
                    <span className="date-info">{value[0]}</span>
                    <span className="date-duration-separator">|</span>
                    <AccessTimeIcon className="date-info-clockIcon" />
                    {mainDuration.map((tvalue, index) => (
                      <span key={Math.random()}>
                        {value[0] ===
                          moment(tvalue.date).format("DD MMM YYYY") && (
                          <>
                            {" "}
                            <span className="date-info">
                              {tvalue.duration}
                            </span>{" "}
                            Hr
                          </>
                        )}
                      </span>
                    ))}
                  </div>
                </li>
                {value[1]?.map((svalue, index) => {
                  if (svalue.endTime !== "Invalid date")
                    return (
                      <li className="time-entries-info" key={Math.random()}>
                        <div className="row">
                          <div className="col-lg-6 col-6">
                            <div className="row">
                              <div className="col-lg-7">
                                {/* {svalue.description} */}
                                <DashboardDescription
                                  desc={svalue.description}
                                  timerID={svalue.timerID}
                                />
                              </div>
                              <div className="col-lg-5">
                                {/* <DashboardProject
                                  value={svalue.projectName}
                                  projectid={svalue.projectID}
                                  timerid={svalue.timerID}
                                  projectDetails={projectDetails}
                                  stateChanger={setData}
                                  stateChangerProgress={setProgress}
                                /> */}
                                {svalue.projectName}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 col-4">
                            <div className="row">
                              <div className="col-lg-6 no-padding text-end">
                                <DashboardTimer
                                  startTimeRaw={svalue.startTimeRaw}
                                  endTimeRaw={svalue.endTimeRaw}
                                  timeridData={svalue.timerID}
                                  dateOfTimer={svalue.dbDate}
                                  olddate={svalue.dbDate}
                                  stateChanger={setData}
                                  stateChangerDuration={setDurationData}
                                  stateChangerProgress={setProgress}
                                  stateChangerLastDate={setLast_date}
                                  getTimeEntries={getTimeEntries}
                                  className="input-timer-1"
                                />
                              </div>
                              <div className="col-lg-6 no-padding text-end">
                                <div className="input-timer-1">
                                  {svalue.duration} Hr
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-2 col-2 no-padding">
                            <div className="float-end btn-img">
                              <button
                                className="btn btn-default time-entry-button"
                                title="Continue time entry"
                                onClick={() => {
                                  {
                                    localStorage.getItem("startTimer") == "true" ? toast.error("One Time Entry is already running!!", {
                                      theme: "colored",
                                      position: "top-left",
                                    }) : 
                                    handleContinue(svalue);
                                  }
                                  }
                                }
                              >
                                <img
                                  // width="30"
                                  // src="https://tt.wlc.technology/assets/img/play-button.png"
                                  src={play}
                                  alt=""
                                />
                              </button>
                              <button
                                className="btn btn-default time-entry-button"
                                title="Delete time entry"
                                onClick={(e) => handleDelete(svalue.timerID)}
                              >
                                <img
                                  // width="30"
                                  className="rotate-45"
                                  // src="https://tt.wlc.technology/assets/img/plus-symbol.png"
                                  src={cancel}
                                  alt=""
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                      </li>
                    );
                })}
              </ul>
            </div>
          );
        })}
      </div>
      <div className="text-center">
        <button
          className="btn btn-default load-more-btn mb-0 mt-2"
          onClick={handleLoadMore}
        >
          <SyncIcon className="dashboard__loadmoreIcon" />
          Load More
        </button>
        {/* {userInfoRole === "admin" && ( */}
        <ThemeIcons getTimeEntries={getTimeEntries} TimerEditorStatus={timerStatusInStore} />
        {/* )} */}
      </div>

      <div className="scrollUp" id="scrollTopBtn" onClick={topFunction}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "20px",
          }}
        >
          <KeyboardArrowUpIcon className="dashboard__scrollIconFirst" />
          <KeyboardArrowUpIcon className="dashboard__scrollIconSecond" />
        </div>
      </div>
      <LoadingBar
        color="black"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
        loaderSpeed={3000}
        waitingTime={1000}
      />
    </div>
  );
}
