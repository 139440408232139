import React, { createContext, useState } from "react";

const TimerContext = createContext();

const TimerProvider = (props) => {
  const [onTimerDashboard, setonTimerDashboard] = useState("");

  return (
    <>
      <TimerContext.Provider value={[onTimerDashboard, setonTimerDashboard]}>
        {props.children}
      </TimerContext.Provider>
    </>
  );
};

export { TimerContext };
export { TimerProvider };
