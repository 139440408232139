import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import AppsIcon from "@mui/icons-material/Apps";
import "./ApplyForLeave.scss";
import { useForm, Controller } from "react-hook-form";
// import { DialogTitleProps, middleDateType } from "./ApplyForLeave.types";
import useApi from "../../../Component/services/api";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import { ColorContext } from "../../../context/ColorContext";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          style={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function ApplyForLeave() {
  const api = useApi();
  const [open, setOpen] = React.useState(false);
  const [dateForm, setDateForm] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [middleDates, setMiddleDates] = useState([]);
  const [middleDatesV2, setMiddleDatesV2] = useState([]);
  const history = useNavigate();
  const [secondaryColor] = useContext(ColorContext);
  // console.log(secondaryColor)

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const [days, setDays] = useState(0);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setDays(middleDates.length);
  }, [middleDates.length]);

  function getDates(startDate, endDate) {
    const dates = [];
    let currentDate = startDate;
    const addDays = function (days) {
      const date = new Date(this.valueOf());
      date.setDate(date.getDate() + days);
      return date;
    };
    while (currentDate <= endDate) {
      dates.push(currentDate);
      currentDate = addDays.call(currentDate, 1);
    }

    const newDate = dates.map(function (date) {
      return {
        singleDate: date,
        progressWidth: false,
      };
    });

    setMiddleDates(newDate);
    setMiddleDatesV2(newDate);
  }

  const onSubmit = (data) => {
    // console.log(data);
    // console.log(middleDatesV2);

    const dateCheck = middleDatesV2.filter(
      (date) => {
        if (date.progressWidth === true) {
          return moment(date.singleDate).format("YYYY-MM-DD");
        }
        return null;
      }
    );
    const countCheck = dateCheck.map(
      (date) => {
        return moment(date.singleDate).format("YYYY-MM-DD");
      }
    );

    api
      .post("v1/leave/", {
        countCheck: countCheck,
        dateFrom: data.dateForm,
        dateTo: data.dateTo,
        days: days,
        description: data.description,
        leaveType: data.leaveType,
      })
      .then(
        (response) => {
          // console.log(response);
        },
        (error) => {
          // console.log(error);
          if (error.response.status === 401) {
            window.localStorage.clear();
            history("/");
          }
        }
      );
  };

  return (
    <>
      <div
        className="page-title"
        onClick={handleClickOpen}
        style={{
          cursor: "pointer",
          textAlign: "end",
          color: `${secondaryColor}`,
        }}
      >
        Apply For a Leave
      </div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        className="leave-apply-form"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <span style={{ color: `${secondaryColor}` }}>Leave Apply</span>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <form className="form-width" onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-3">
              <label className="form-label">Leave Type:</label>
              <select
                className="form-select"
                aria-label="Default select example"
                {...register("leaveType", { required: true })}
              >
                <option value="">Select Leave Type</option>
                <option value="1">Casual Leave</option>
                <option value="2">Sick Leave</option>
              </select>
              {errors.leaveType && (
                <span
                  style={{
                    color: "red",
                  }}
                >
                  Leave Type is required
                </span>
              )}
            </div>
            <div className="mb-3">
              <label className="form-label">Date From:</label>
              <div className="flex-input w-100">
                <div className="w-100">
                  {/* <Controller
                    render={({ field }) => (
                      <input
                        type="date"
                        {...field}
                        className="form-control date-input w-100"
                        onChange={(e) => {
                          field.onChange(e.target.value);
                          setDateForm(e.target.value);
                        }}
                      />
                    )}
                    control={control}
                    name="dateForm"
                    rules={{ required: true }}
                  /> */}

                  <DatePicker
                    selected={dateForm}
                    className="date-input w-100"
                    onChange={(date) => setDateForm(date)}
                  />

                  <input
                    type="text"
                    className="form-control disable-input"
                    disabled
                    value={dateForm && moment(dateForm).format("YYYY-MM-DD")}
                  />
                </div>
                <AppsIcon className="date-icon" />
              </div>
              {errors.dateForm && (
                <span
                  style={{
                    color: "red",
                  }}
                >
                  Date is required
                </span>
              )}
            </div>

            <div className="mb-3">
              <label className="form-label">Date To:</label>
              <div className="flex-input w-100">
                <div className="w-100">
                  {/* <Controller
                    render={({ field }) => (
                      <input
                        type="date"
                        className="form-control date-input w-100"
                        onChange={(e) => {
                          if (dateForm !== "") {
                            field.onChange(e.target.value);
                            setDateTo(e.target.value);
                            getDates(
                              new Date(dateForm),
                              new Date(e.target.value)
                            );
                          }
                        }}
                      />
                    )}
                    control={control}
                    name="dateTo"
                    rules={{
                      required: true,
                      validate: (value) => value >= dateForm,
                    }}
                  /> */}

                  <DatePicker
                    selected={dateTo}
                    className="date-input w-100"
                    onChange={(date) => {
                      setDateTo(date);
                      getDates(new Date(dateForm), new Date(date));
                    }}
                  />

                  <input
                    type="text"
                    className="form-control disable-input"
                    disabled
                    value={dateTo && moment(dateTo).format("YYYY-MM-DD")}
                  />
                </div>
                <AppsIcon className="date-icon" />
              </div>

              {errors.dateTo && errors.dateTo.type === "required" && (
                <span
                  style={{
                    color: "red",
                  }}
                >
                  Date is required
                </span>
              )}
              {errors.dateTo && errors.dateTo.type === "validate" && (
                <span
                  style={{
                    color: "red",
                  }}
                >
                  Enter valid Date
                </span>
              )}
            </div>

            {middleDates.map(
              (date, index) => (
                <div className="progressBar" key={index}>
                  <p className="mb-0">{new Date(date.singleDate).toString()}</p>
                  <br />
                  <div className="progressInsider">
                    <div className="progress" style={{ height: "6px" }}>
                      <div
                        className="progress-bar bg-success"
                        role="progressbar"
                        style={{ width: date.progressWidth ? "50%" : "100%" }}
                      ></div>
                    </div>
                    <div className="half-day">
                      <p className="mb-0">Half Day:</p>
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          middleDatesV2[index].progressWidth = e.target.checked;
                          setMiddleDates(() => {
                            return [...middleDatesV2];
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              )
            )}

            <div className="mb-3">
              <label className="form-label">Days</label>
              <input
                type="text"
                className="form-control days-input"
                value={`${middleDates.length} Days`}
                disabled
              />
            </div>

            <div className="mb-3">
              <label className="form-label">Description</label>
              <textarea
                className="form-control"
                rows={3}
                {...register("description", { required: true })}
              />

              {errors.description && (
                <span
                  style={{
                    color: "red",
                  }}
                >
                  Description is required
                </span>
              )}
            </div>

            <button
              type="submit"
              className="btn submit-form"
              style={{ backgroundColor: `${secondaryColor}` }}
            >
              Submit
            </button>
          </form>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
}
