import React, { useState, useEffect, useContext } from "react";
import useApi from "../../../Component/services/api";
import { useForm } from "react-hook-form";
import ProjectMenu from "../../dev-reports/ProjectMenu/ProjectMenu";
import ClientMenu from "../../dev-reports/ClientMenu/ClientMenu";
import UserMenu from "../../dev-reports/UserMenu/UserMenu";
import StartEndDate from "../../dev-reports/StartEndDate/StartEndDate";
import axios from "axios";
import Popover from "@mui/material/Popover";
import $ from "jquery";
// import { listType, userProps } from "../../dev-reports/UserMenu/UserMenu.types";
// import {
//   ReportFilterProps,
//   ProjectType,
//   ClientType,
//   UserType,
// } from "./ReportFilter.types";
import "./ReportFilter.scss";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ColorContext } from "../../../context/ColorContext";

const ReportFilter = ({
  getAllProjectId,
  getClient,
  getAllUser,
  getAllMonth,
  startDate,
  setstartDate,
  endDate,
  setendDate,
  setReport,
  settotalTimeDuration,
  setgetAllProjectId,
  setgetClient,
  setgetAllUser,
  setgetAllMonth,
  setProgress,
  reportApiurl,
  setshowBarData,
  setshowGraphOption,
  NotShowRangePicker,
  stateChangerMonthFilter,
}) => {
  const api = useApi();
  const { register, handleSubmit } = useForm();
  const location = useLocation();

  const [user, setUser] = useState([]);
  const [allUser, setAllUser] = useState([])
  const [client, setClient] = useState([]);
  const [allClient, setAllClient] = useState([]);
  const [project, setProject] = useState([]);

  const history = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const [checkList, setcheckList] = useState([]);
  const [secondaryColor] = useContext(ColorContext);
  const userInfo = window.localStorage.getItem("tt-userinfo");
  const userInfoRole = userInfo && JSON.parse(userInfo).role;
  const userInfoId = userInfo && JSON.parse(userInfo).userID;

  const handleClickOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const currentYear = new Date().getFullYear();

  const monthData = [
    {
      monthID: `${currentYear}-01`,
      name: "January",
      check: false,
    },
    {
      monthID: `${currentYear}-02`,
      name: "Febuarary",
      check: false,
    },
    {
      monthID: `${currentYear}-03`,
      name: "March",
      check: false,
    },
    {
      monthID: `${currentYear}-04`,
      name: "April",
      check: false,
    },
    {
      monthID: `${currentYear}-05`,
      name: "May",
      check: false,
    },
    {
      monthID: `${currentYear}-06`,
      name: "June",
      check: false,
    },
    {
      monthID: `${currentYear}-07`,
      name: "July",
      check: false,
    },
    {
      monthID: `${currentYear}-08`,
      name: "August",
      check: false,
    },
    {
      monthID: `${currentYear}-09`,
      name: "September",
      check: false,
    },
    {
      monthID: `${currentYear}-10`,
      name: "Octomber",
      check: false,
    },
    {
      monthID: `${currentYear}-11`,
      name: "November",
      check: false,
    },
    {
      monthID: `${currentYear}-12`,
      name: "Deceber",
      check: false,
    },
  ];

  useEffect(() => {
    setProgress(80);
    const id = localStorage.getItem("workspace_id");
    const userId = JSON.parse(localStorage.getItem("tt-userinfo"))
    const {_id} = userId
    // console.log(id, "idd")
    axios
      .all([
        // api.get("v1/projectNames/"),
        // api.get("v1/clientNames/"),
        // api.get("v1/userNames/"),
        api.get(`https://api.trackify.ai/reports/projectsName/${id}/${_id}`),
        api.get(`https://api.trackify.ai/reports/clientsName/${id}`),
        api.get(`https://api.trackify.ai/reports/usersName/${id}`)
      ])
      .then(
        (response) => {
          // console.log(response,"responsee")
          setProject(response[0].data);
          setClient(response[1].data);
          setAllClient(response[1].data);
          setUser(response[2].data);
          setAllUser(response[2].data);
          setProgress(100);
        },
        (error) => {
          // console.log(error.response);
          setProgress(100);
          if (error.response.status === 401) {
            window.localStorage.clear();
            history("/");
          }
        }
      );
  }, []);

  const onSubmit = (data) => {
    // console.log(data);
    setProgress(80);
    const userId = JSON.parse(localStorage.getItem("tt-userinfo"))
    const {_id} = userId
    api
      .get(
        `${reportApiurl}?projectID=${
          getAllProjectId.length === 0 ? undefined : getAllProjectId
        }&clientID=${getClient.length === 0 ? undefined : getClient}&userID=${
          getAllUser.length === 0 ? undefined : getAllUser
        }&startDate=${startDate}&endDate=${endDate}&description=${
          data.description !== "" ? data.description : undefined
        }&workspaceId=${localStorage.getItem("workspace_id")}&ID=${_id}`
      )
      .then(
        (response) => {
          console.log(response, "datafilter");
          setProgress(100);
          setReport(response.data.data);

          if (location.pathname === "/reports/detail") {
            // console.log(response.data, "data as filter");
            // response.data.report.forEach((element) => {
            //   let p= element.duration.split(":"),
            //     s = 0,
            //     m = 1;

            //   while (p.length > 0) {
            //     s += m * parseInt(p.pop(), 10);
            //     m *= 60;
            //   }
            //   element.totalDuration = s;
            // });

            let reducer = (acc, cur) => {
              return acc + Number(cur.durationSeconds);
            };
            // console.log("response.data.report");
            settotalTimeDuration(response.data.data.reduce(reducer, 0));
          }else if(location.pathname === "/reports/summarybydev"){
            
            let reducer = (acc, cur) => {
              return acc + Number(cur.date_of_joining);
            };
            settotalTimeDuration(response.data.data.reduce(reducer, 0));

          } else {
            let reducer = (acc, cur) => {
              return acc + Number(cur.timeSpent);
            };
            settotalTimeDuration(response.data.data.reduce(reducer, 0));
            // settotalTimeDuration("05555");
          }

          setgetAllProjectId([]);
          setgetClient([]);
          setgetAllUser([]);
          if (reportApiurl === "https://api.trackify.ai/reports/detail") {
            setshowBarData({
              labels: response.data.graphLable,
              datasets: [
                {
                  data: response.data.graphValue,
                  backgroundColor: "#dbf5f39e",
                  borderColor: "#4dccc3",
                },
                // {
                //   data: response.data.graphValue,
                //   backgroundColor: "#dbf5f39e",
                //   borderColor: "#4dccc3",
                // },
              ],
            });

            const array = response.data.graphValue;
            const smallValue = array.reduce((a, b) => Math.min(a, b));
            const highestValue = array.reduce((a, b) =>
              Math.max(a, b)
            );

            setshowGraphOption({
              responsive: true,
              maintainAspectRatio: false,
              elements: {
                bar: {
                  borderWidth: 2,
                },
              },
              plugins: {
                legend: {
                  display: false,
                },
              },
              scales: {
                y: {
                  display: true,
                  min: smallValue <= 2 ? 0 : (smallValue - 1).toFixed(1),
                  max: Math.round(highestValue) + 1,
                },
              },
            });
          }
        },
        (error) => {
          console.log(error);
          setProgress(100);
          if (error.response.status === 401) {
            window.localStorage.clear();
            history("/");
          }
        }
      );
  };

  const onSubmitUser = (data) => {
    // console.log(data);
    setProgress(80);
    const userId = JSON.parse(localStorage.getItem("tt-userinfo"))
    const {_id} = userId
    api
      .get(
        `${reportApiurl}?projectID=${
          getAllProjectId.length === 0 ? undefined : getAllProjectId
        }&clientID=${getClient.length === 0 ? undefined : getClient}&userID=${
          getAllUser.length === 0 ? parseInt(userInfoId,10) : getAllUser
        }&startDate=${startDate}&endDate=${endDate}&description=${
          data.description !== "" ? encodeURIComponent(data.description) : undefined
        }&workspaceId=${localStorage.getItem("workspace_id")}&ID=${_id}`
      )
      .then(
        (response) => {
          // console.log(response, "res data");
          setReport(response.data.data);
          setProgress(100);

          if (location.pathname === "/reports/detail") {
            response.data.data.forEach((element) => {
              let p = element.duration.split(":"),
                s = 0,
                m = 1;

              while (p.length > 0) {
                s += m * parseInt(p.pop(), 10);
                m *= 60;
              }
              element.totalDuration = s;
            });

            let reducer = (acc, cur) => {
              return acc + Number(cur.timeSpent);
            };
            // console.log("response.data.report");
            // console.log(response.data.report);
            settotalTimeDuration(response.data.data.reduce(reducer, 0));
          } else {
            let reducer = (acc, cur) => {
              return acc + Number(cur.timeSpent);
            };
            settotalTimeDuration(response.data.data.reduce(reducer, 0));
          }

          // setgetAllProjectId([]);
          // setgetClient([]);
          // setgetAllUser([]);
          if (reportApiurl === "https://api.trackify.ai/reports/detail") {
            setshowBarData({
              labels: response.data.graphLable,
              datasets: [
                {
                  data: response.data.graphValue,
                  backgroundColor: "#dbf5f39e",
                  borderColor: "#4dccc3",
                },
                // {
                //   data: response.data.graphValue,
                //   backgroundColor: "#dbf5f39e",
                //   borderColor: "#4dccc3",
                // },
              ],
            });

            const array = response.data.graphValue;
            const smallValue = array.reduce((a, b) => Math.min(a, b));
            const highestValue = array.reduce((a, b) =>
              Math.max(a, b)
            );

            setshowGraphOption({
              responsive: true,
              maintainAspectRatio: false,
              elements: {
                bar: {
                  borderWidth: 2,
                },
              },
              plugins: {
                legend: {
                  display: false,
                },
              },
              scales: {
                y: {
                  display: true,
                  min: smallValue <= 2 ? 0 : (smallValue - 1).toFixed(1),
                  max: Math.round(highestValue) + 1,
                },
              },
            });
          }
        },
        (error) => {
          // console.log(error);
          setProgress(100);
          if (error.response.status === 401) {
            window.localStorage.clear();
            history("/");
          }
        }
      );
  };

  return (
    <div className="ReportFilter">
      <form  onSubmit={handleSubmit(userInfoRole === "admin"? onSubmit : onSubmitUser)}>
        <div className="flex-inputs">
          {location.pathname !==  "/reports/summarybydev" && 
          <ProjectMenu
            ProjectList={project}
            getAllProjectId={getAllProjectId}
            setgetAllProjectId={setgetAllProjectId}
            setClient={setClient}
            client={client}
            ClientList={allClient}
            userList= {allUser}
            setUser={setUser}
          />
  }
          {userInfoRole === "admin" && location.pathname !== "/reports/summarybydev" && 
          <ClientMenu
            ClientList={client}
            getClient={getClient}
            setgetClient={setgetClient}
          />
  }

          {userInfoRole === "admin" && location.pathname !== "/reports/summary"  && (
            <UserMenu
              userList={user}
              getAllUser={getAllUser}
              setgetAllUser={setgetAllUser}
            />
          )}

          {NotShowRangePicker ? (
            <>
              <div className="inner-Input" onClick={handleClickOpen}>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Select Month"
                  value=""
                />
                <i className="fas fa-caret-down"></i>
              </div>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                disableAutoFocus={true}
                disableEnforceFocus={true}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                className="devSearchNew"
              >
                <ul className="project-list">
                  {monthData.map(
                    (list, index) => (
                      <li key={index}>
                        <label className="container m-0">
                          {list.name}
                          <input
                            type="radio"
                            name="colorTheme"
                            className="userCheck"
                            value={list.monthID}
                            onChange={(e) =>
                              stateChangerMonthFilter(e.target.value)
                            }
                          />
                          <span className="checkmark"></span>
                        </label>
                      </li>
                    )
                  )}
                </ul>
              </Popover>
            </>
          ) : (
            <></>
          )}

          {NotShowRangePicker ? (
            <></>
          ) : (
            <div className="inner-Input">
              <StartEndDate
                setstartDate={setstartDate}
                setendDate={setendDate}
              />
            </div>
          )}
          {/* {NotShowRangePicker ? (
            <></>
          ) : (
            <div className="inner-search-Input">
              <input
                type="text"
                className="form-control"
                placeholder="Enter Description"
                {...register("description")}
              />
            </div>
          )} */}

          <div className="go-button">
            <button
              className="btn"
              type="submit"
              style={{ color: `${secondaryColor}` }}
            >
              GO
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ReportFilter;
