import { useState, useEffect, useContext } from "react";
import "./Detailed.scss";
import useApi from "../services/api";
// import {
//   UserType,
//   ClientType,
//   ReportType,
//   ProjectType,
// } from "./Detailed.types";
import moment from "moment";
import LoadingBar from "react-top-loading-bar";
import { convertHMS } from "../../Common/convertHMS";
import ReportFilter from "../../Views/report/ReportFilter/ReportFilter";
import { useNavigate } from "react-router-dom";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { ColorContext } from "../../context/ColorContext";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Detailed = () => {
  const api = useApi();
  const [tabButtons, settabButtons] = useState(true);
  const [secondaryColor] = useContext(ColorContext);
  // const [user, setUser] = useState<UserType | []>([]);
  // const [client, setClient] = useState<ClientType | []>([]);
  const [report, setReport] = useState([]);
  const [startDate, setstartDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, setendDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [getClient, setgetClient] = useState([]);
  const [getAllUser, setgetAllUser] = useState([]);
  // const [project, setProject] = useState<ProjectType | []>([]);
  const [getAllProjectId, setgetAllProjectId] = useState([]);
  const [totalTimeDuration, settotalTimeDuration] = useState(
    null
  );
  const [progress, setProgress] = useState(0);
  const history = useNavigate();

  const reportApiurl = "https://api.trackify.ai/reports/detail";
  const [graphValue, setgraphValue] = useState([]);
  const [graphLable, setgraphLable] = useState([]);
  const [showBarData, setshowBarData] = useState({
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: "#dbf5f39e",
        borderColor: "#4dccc3",
      },
    ],
  });
  const [showGraphOption, setshowGraphOption] = useState({
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  });

  useEffect(() => {
    // console.log("coming here hey")
    setProgress(80);
    const userId = JSON.parse(localStorage.getItem("tt-userinfo"))
    const {_id} = userId
    api
      .get(
        `${reportApiurl}?projectID=${
          getAllProjectId.length === 0 ? undefined : getAllProjectId
        }&clientID=${getClient.length === 0 ? undefined : getClient}&userID=${
          getAllUser.length === 0 ? undefined : getAllUser
        }&startDate=${startDate}&endDate=${endDate}&description=${undefined}&workspaceId=${localStorage.getItem("workspace_id")}&ID=${_id}`
      )
      .then(
        (response) => {
          // console.log(response, "res");
          setReport(response.data.data);

          // function hmsToSecondsOnly(str: string) {

          //   return s;
          // }
          setProgress(100);
          response.data.data.forEach((element) => {
            let p = element.duration.split(":"),
              s = 0,
              m = 1;

            while (p.length > 0) {
              s += m * parseInt(p.pop(), 10);
              m *= 60;
            }
            element.totalDuration = s;
          });

          let reducer = (acc, cur) => {
            return acc + Number(cur.durationSeconds);
          };
          // console.log("response.data.report");
          // console.log(response.data.report);
          settotalTimeDuration(response.data.data.reduce(reducer, 0));

          // setgraphValue(response.data.graph_value);
          // setgraphLable(response.data.graph_lables);
          setgraphValue(response.data.graphValue);
          // response.data.map((el)=>{
          //   setgraphLable(()=>{
          //     return [...graphLable, el.duration]
          //   })
          // })
          setgraphLable(response.data.graphLable);
          setshowBarData({
            labels: response.data.graphLable,
            datasets: [
              {
                data: response.data.graphValue,
                backgroundColor: "#dbf5f39e",
                borderColor: "#4dccc3",
              },
            ],
          });

          const array = response.data.graphValue;
          // console.log(response);
          const smallValue = array.reduce((a, b) => Math.min(a, b));
          const highestValue = array.reduce((a, b) => Math.max(a, b));

          setshowGraphOption({
            responsive: true,
            maintainAspectRatio: false,
            elements: {
              bar: {
                borderWidth: 2,
              },
            },
            plugins: {
              legend: {
                display: false,
              },
            },
            scales: {
              y: {
                display: true,
                min: smallValue <= 2 ? 0 : (smallValue - 1).toFixed(1),
                max: Math.round(highestValue) + 1,
              },
            },
          });

          setgetAllProjectId([]);
          setgetClient([]);
          setgetAllUser([]);
          setProgress(100);
        },
        (error) => {
          console.log(error);
          setProgress(100);
          if (error.response.status === 401) {
            window.localStorage.clear();
            history("/");
          }
        }
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="Detailed">
      <div className="box-header">
        <div className="page-title" style={{ color: `${secondaryColor}` }}>
          Detailed Report
        </div>
      </div>

      <ReportFilter
        getAllProjectId={getAllProjectId}
        getClient={getClient}
        getAllUser={getAllUser}
        startDate={startDate}
        setstartDate={setstartDate}
        endDate={endDate}
        setendDate={setendDate}
        setReport={setReport}
        settotalTimeDuration={settotalTimeDuration}
        setgetAllProjectId={setgetAllProjectId}
        setgetClient={setgetClient}
        setgetAllUser={setgetAllUser}
        setProgress={setProgress}
        // project={project}
        // client={client}
        // user={user}
        reportApiurl={reportApiurl}
        setshowBarData={setshowBarData}
        setshowGraphOption={setshowGraphOption}
      />

      <div className="middle-content" style={{ overflow: "auto" }}>
        <div className="tab-buttons">
          <button
            className="btn"
            onClick={() => settabButtons(true)}
            style={{
              background: tabButtons ? `${secondaryColor}` : "white",
              color: tabButtons ? "white" : "black",
            }}
          >
            Table View
          </button>
          <button
            className="btn"
            onClick={() => settabButtons(false)}
            style={{
              background: tabButtons ? "white" : `${secondaryColor}`,
              color: tabButtons ? "black" : "white",
            }}
          >
            Graph View
          </button>
        </div>

        <div className="table-content">
          <div className="total-time">
            <p>
              Total:
              <span className="bold-time">
                {totalTimeDuration
                  ? String(convertHMS(totalTimeDuration)).replace(
                      /\d{1,3}(?=(\d{3})+(?!\d))/g,
                      "$&,"
                    )
                  : "00:00:00"}
              </span>
            </p>
          </div>
          {tabButtons && (
            <table className="table">
              <tbody>
                <tr>
                  <th scope="col">Description</th>
                  <th scope="col" className="text-center">
                    Project Name
                  </th>
                  <th scope="col" className="text-center">
                    Name
                  </th>
                  <th scope="col" className="text-center">
                    Duration
                  </th>
                  <th scope="col" className="text-center">
                    Time
                  </th>
                  <th scope="col" className="text-center">
                    Date
                  </th>
                </tr>

                {report.map(
                  (
                    report,
                    index
                  ) => {
                    return (
                      <tr key={index}>
                        <td>{report.description}</td>
                        <td>{report.projectName}</td>
                        <td className="text-center">{report.duration}</td>
                        <td className="text-center">
                        {report.durationSeconds
                  ? String(convertHMS(report.durationSeconds)).replace(
                      /\d{1,3}(?=(\d{3})+(?!\d))/g,
                      "$&,"
                    )
                  : "00:00:00"}
                        </td>
                        <td className="text-center">
                          {moment(report.startTime).format("LT")}
                          &nbsp;-&nbsp;
                          {moment(report.endTime).format("LT")}
                        </td>
                        <td>{moment(report.endTime).format("YYYY-MM-DD")}</td>
                      </tr>
                    );
                  }
                )}
                {report.length === 0 && (
                  <tr className="no-record-found">
                    <td align="center" colSpan={6}>
                      No Record Found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>

        {tabButtons === false && (
          <div className="graph-view col-md-8 m-auto">
            <Bar options={showGraphOption} data={showBarData} height={512} />
          </div>
        )}
        <br />
      </div>
      <LoadingBar
        color="black"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
        loaderSpeed={3000}
        waitingTime={1000}
      />
    </div>
  );
};

export default Detailed;
