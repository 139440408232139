import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import "./UpdateHoliday.scss";
import AppsIcon from "@mui/icons-material/Apps";
import useApi from "../../../Component/services/api";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import moment from "moment";
import LoadingBar from "react-top-loading-bar";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateHoliday } from "../../../Reducers/holidaySlice";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          style={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
function UpdateHoliday({ getAllHolidays, holiday }) {
  const api = useApi();
  const [open, setOpen] = React.useState(false);
  const [holidayDate, setholidayDate] = useState(null);
  const [progress, setProgress] = useState(0);
  const [dateError, setdateError] = useState("");
  const history = useNavigate();
  const dispatch = useDispatch()

  useEffect(() => {
    if (holiday.holidayDate !== "0000-00-00") {
      setholidayDate(new Date(holiday.holidayDate));
    }
  }, []);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const handleClickOpen = () => {
    // console.log(holiday, "holiday")
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = (data) => {
    // console.log(data);
    setProgress(80);
    const passingData = {
      holidayName: data.holidayName,
        holidayDate: moment(holidayDate).format("YYYY-MM-DD"),
        holidayID: holiday._id,
        isYearly: data.ApplyYearly,
        restricted : data.Restricted,
        description: data.description,
    }
    dispatch(updateHoliday(passingData))
    handleClose();
          setProgress(100);
          // getAllHolidays();
          reset({ ...data });
      // .put("v1/updateHoliday/", {
      // .patch("https://api.trackify.ai/holiday/updateHoliday", {
      //   holidayName: data.holidayName,
      //   holidayDate: moment(holidayDate).format("YYYY-MM-DD"),
      //   holidayID: holiday._id,
      //   isYearly: data.ApplyYearly,
      //   restricted : data.Restricted,
      //   description: data.description,
      // })
      // .then(
      //   (response) => {
      //     console.log(response);
      //     handleClose();
      //     setProgress(100);
      //     getAllHolidays();
      //     reset({ ...data });
      //   },
      //   (error) => {
      //     console.log(error.response);
      //     toast.error(error.response.data.error, {
      //       theme: "colored",
      //     });
      //     setProgress(100);

      //     if (error.response.status === 401) {
      //       window.localStorage.clear();
      //       history("/");
      //     }
      //   }
      // );
  };

  return (
    <div className="update-holiday-heading">
      <button className="btn btn-Edit" onClick={handleClickOpen}>
        Edit
      </button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        className="update-holiday"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Update Holiday
        </BootstrapDialogTitle>
        <DialogContent dividers className="ps-3 pe-3">
          <form className="form-width" onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-3">
              <label className="form-label">Holiday Name :</label>
              <div className="flex-input w-100">
                <div className="w-100">
                  <input
                    type="text"
                    className="form-control disable-input"
                    defaultValue={holiday.holidayName}
                    {...register("holidayName", { required: true })}
                  />
                </div>
              </div>
              {errors.holidayName && errors.holidayName.type === "required" && (
                <span style={{ color: "red" }}>
                  Holiday Name can not be blank
                </span>
              )}
            </div>
            <div style={{display:"flex", justifyContent:"space-around", alignItems: "start"}} >
            <div className="mb-3" style={{position:"absolute", left:"35px"}}>
              <label className="form-label">Apply Yearly:</label>
              <div>
                <div className="form-check form-check-inline p-0 m-0">
                  <input
                    type="radio"
                    {...register("ApplyYearly", { required: true })}
                    value="1"
                    defaultChecked={holiday.isYearly === "1" ? true : false}
                  />
                  &nbsp;
                  <span>Yes</span>
                </div>
                &nbsp;
                <div className="form-check form-check-inline p-0 m-0">
                  <input
                    type="radio"
                    {...register("ApplyYearly", { required: true })}
                    value="0"
                    defaultChecked={holiday.isYearly === "0" ? true : false}
                  />
                  &nbsp;
                  <span>No</span>
                </div>
              </div>

              <div>
                {errors.ApplyYearly &&
                  errors.ApplyYearly.type === "required" && (
                    <span style={{ color: "red" }}>
                      Apply Yearly can not be blank
                    </span>
                  )}
              </div>
            </div>
            <div className="mb-3">
              <label className="form-label">Restricted:</label>
              <div>
                <div className="form-check form-check-inline p-0 m-0">
                  <input
                    type="radio"
                    {...register("Restricted")}
                    value="1"
                    defaultChecked={holiday.restricted === "1" ? true : false}
                  />
                  &nbsp;
                  <span>Yes</span>
                </div>
                &nbsp;
                <div className="form-check form-check-inline p-0 m-0">
                  <input
                    type="radio"
                    {...register("Restricted", { required: true })}
                    value="0"
                    defaultChecked={holiday.restricted === "0" ? true : false}
                  />
                  &nbsp;
                  <span>No</span>
                </div>
              </div>

              <div>
                {/* {errors.ApplyYearly &&
                  errors.ApplyYearly.type === "required" && (
                    <span style={{ color: "red" }}>
                      Apply Yearly can not be blank
                    </span>
                  )} */}
              </div>
            </div>
            </div>

            

            <div className="mb-3">
              <label className="form-label">Date</label>
              <div className="flex-input w-100">
                <div className="w-100">
                  <DatePicker
                    selected={holidayDate}
                    className="date-input w-100"
                    onChange={(date) => setholidayDate(date)}
                  />

                  <input
                    type="text"
                    className="form-control disable-input"
                    disabled
                    value={
                      holidayDate && moment(holidayDate).format("YYYY-MM-DD")
                    }
                  />
                </div>
                {/* <AppsIcon className="date-icon" /> */}
              </div>
              {holidayDate === null && (
                <div>
                  {dateError && (
                    <span style={{ color: "red" }}>{dateError}</span>
                  )}
                </div>
              )}
            </div>
            <div className="mb-3">
              <label className="form-label">Description</label>
              <textarea
                className="form-control"
                rows={4}
                {...register("description", { required: true })}
                defaultValue={holiday.description}
              />
              <div>
                {errors.description &&
                  errors.description.type === "required" && (
                    <span style={{ color: "red" }}>
                      Description can not be blank
                    </span>
                  )}
              </div>
            </div>

            <button
              type="submit"
              className="btn submit-form"
              onClick={() => {
                if (holidayDate === null) {
                  setdateError("Holiday Date can not be blank");
                } else {
                  setdateError("");
                }
              }}
            >
              Submit
            </button>
          </form>
        </DialogContent>
      </BootstrapDialog>
      <LoadingBar
        color="black"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
        loaderSpeed={3000}
        waitingTime={1000}
      />
    </div>
  );
}

export default React.memo(UpdateHoliday);
