import React, { useContext, useEffect, useState } from "react";
import "./ApplyLeave.scss";
import { ColorContext } from "../../context/ColorContext";
import useApi from "../services/api";

import ApplyForLeave from "../../Views/apply-leave/apply-for-leave/ApplyForLeave";
const ApplyLeave = () => {
  const api = useApi();
  const [secondaryColor] = useContext(ColorContext);
  const [leaveBalanceData, setLeaveBalanceData] = useState([]);

  const userInfo = window.localStorage.getItem("tt-userinfo");

  const userInfoId = userInfo && JSON.parse(userInfo).userID;

  function roundOff2(str) {
    return parseFloat(str).toFixed(3).slice(0, -1);
  }

  function getLeaveBalanceUser() {
    const userIdApi = parseInt(userInfoId, 10);
    api.get(`leave/userLeaveBalance?userID=${userIdApi}`).then((response) => {
      setLeaveBalanceData(response.data);
    });
  }

  useEffect(() => {
    getLeaveBalanceUser();
  }, []);

  return (
    <div className="apply-leave">
      <div className="box-header">
        <div className="page-title" style={{ color: `${secondaryColor}` }}>
          Leave Balance
        </div>
      </div>

      <div className="table-content">
        <table className="table">
          <tbody>
            <tr>
              <th scope="col">Month</th>
              <th scope="col">Leave Balance</th>
              <th scope="col">Leave Allocated</th>
              <th scope="col">Starting Balance</th>
              <th scope="col">Hours Worked</th>
              <th scope="col">Days Worked</th>
              <th scope="col">Leave Taken</th>
              <th scope="col">Ending Balance</th>
              <th scope="col">LOP</th>
              <th scope="col">Leave Balance</th>
            </tr>
            {leaveBalanceData.map((value) => (
              <tr style={{ textAlign: "center" }} key={value} >
                <td>{value.current_update_month}</td>
                <td>{roundOff2(value.previous_leave_balance)}</td>
                <td>{roundOff2(value.leave_allocated)}</td>
                <td>{roundOff2(value.starting_leave_balance)}</td>
                <td>{roundOff2(value.hours_worked)}</td>
                <td>{roundOff2(value.days_worked)}</td>
                <td>{roundOff2(value.leave_added_taken)}</td>
                <td>{roundOff2(value.ending_balance)}</td>
                <td>{roundOff2(value.lop)}</td>
                <td>{roundOff2(value.cur_leave_balance)}</td>
              </tr>
            ))}

            {leaveBalanceData.length === 0 && (
              <tr className="no-record-found">
                <td align="center" colSpan={10}>
                  No Record Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <div className="d-flex justify-content-between align-items-center">
        <div className="box-header pt-0">
          <div className="page-title" style={{ color: `${secondaryColor}` }}>
            Leave Apply
          </div>
        </div>

        <div className="box-header pt-0">
          <ApplyForLeave />
        </div>
      </div>

      <div className="table-content">
        <table className="table">
          <tbody>
            <tr>
              <th scope="col">Leave Type</th>
              <th scope="col">Description</th>
              <th scope="col">Date From</th>
              <th scope="col">Date To</th>
              <th scope="col">Days</th>
              <th scope="col">Applied Date</th>
              <th scope="col">Status</th>
              <th scope="col">Updated Date</th>
              <th scope="col">Admin Comment</th>
              <th scope="col">Action</th>
            </tr>
            {/* <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr> */}
            <tr className="no-record-found ng-scope">
              <td align="center" colSpan={10}>
                No Record Found
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ApplyLeave;
