import React, { useState, useEffect } from "react";
import Popover from "@mui/material/Popover";
import $ from "jquery";
// import { listType, clientProps } from "./ClientMenu.types";

const ClientMenu = ({ ClientList, setgetClient, getClient }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectClient, setSelectClient] = useState(false);
  const [list, setList] = useState([]);
  const [checkList, setcheckList] = useState([]);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const [inputValue, setinputValue] = useState("");
  const [allChecked, setAllChecked] = useState(false)
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    ClientList.forEach((element) => {
      element.check = false;
    });
    setcheckList(ClientList);
    setList(ClientList);
  }, [ClientList]);

  const filterData = (value) => {
    const lowercasedValue = value.toLowerCase().trim();

    const filteredData = ClientList.filter((item) => {
      return Object.keys(item).some((key) =>
        item[key].toString().toLowerCase().includes(lowercasedValue)
      );
    });

    setList(() => {
      return [...filteredData];
    });
  };

  useEffect(() => {
    if (selectClient === true) {
      // $(".clientCheck").prop("disabled", true);

      checkList.forEach((element) => {
        element.check = true;
      });

      setcheckList(() => {
        return [...checkList];
      });

      const newList = list.map((client) => {
        return client._id;
      });
      setgetClient(newList);
    } else {
      // $(".clientCheck").prop("disabled", false);
      let flag = true
      // console.log(checkList,"xyz")
      checkList.forEach((element)=>{
        if(element.check == false){
          flag = false
        }
      })
      if(flag){
        checkList.forEach((element) => {
          element.check = false;
        });
  
        setcheckList(() => {
          return [...checkList];
        });
  
        setgetClient([]);
      }else if(getClient.length == 0){
        const newList =  checkList.filter((el)=>{
          return el.check === true
        })
        // console.log(newList, "after")
        const data = newList.map((client) => {
          return client._id;
        });
        setgetClient(data);
      }
    }
  }, [selectClient, list, setgetClient]);

  useEffect(() => {
    if (getClient.length >= 1) {
      setinputValue(`${getClient.length} Selected`);
    } else if (getClient.length === 0) {
      filterData("");
      setinputValue("");
    }
  }, [getClient.length]);

  return (
    <div className="w-100 me-3">
      <div className="inner-Input">
        <input
          type="text"
          className="form-control"
          value={inputValue}
          placeholder={"Select Client"}
          aria-describedby={id}
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
          }}
          onChange={(event) => {
            if (getClient.length <= 0) {
              filterData(event.target.value);
              setinputValue(event.target.value);
            }
          }}
        />
        <i className="fas fa-caret-down"></i>
      </div>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        disableAutoFocus={true}
        disableEnforceFocus={true}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        className="devSearch "
      >
        <ul className="project-list">
          <li>
            <label className="container">
              Select All
              <input
                type="checkbox"
                onChange={() => {
                  setSelectClient(!selectClient);
                  setAllChecked(!allChecked)
                }}
                checked = {allChecked}
              />
              <span className="checkmark"></span>
            </label>
          </li>
          {list.map(
            (list, index) => (
              <li key={index}>
                <label className="container m-0">
                  {list.clientName}
                  <input
                    type="checkbox"
                    className="clientCheck"
                    checked={checkList[index].check}
                    onChange={(e) => {
                      if (e.target.checked === true) {
                        setgetClient((prev) => {
                          return [...prev, list._id];
                        });
                        if(getClient.length === ClientList.length-1){
                          setAllChecked(true)
                          setSelectClient(true);
                        }
                        checkList[index].check = !checkList[index].check;
                        setcheckList(() => {
                          return [...checkList];
                        });
                      } else if (e.target.checked === false) {
                        var listArr = [...getClient];
                        var indexVal = listArr.indexOf(list._id);
                        listArr.splice(indexVal, 1);
                        setgetClient(listArr);
                        if(listArr.length === 0){
                          setAllChecked(false)
                          setSelectClient(false);
                        }else{
                          setAllChecked(false)
                          setSelectClient(false)
                        }
                        checkList[index].check = !checkList[index].check;
                        setcheckList(() => {
                          return [...checkList];
                        });
                      }
                    }}
                  />
                  <span className="checkmark"></span>
                </label>
              </li>
            )
          )}
        </ul>
      </Popover>
    </div>
  );
};

export default React.memo(ClientMenu);
