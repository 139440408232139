import React, { useState, useContext } from "react";
import "./LeaveReportFilter.scss";
import useApi from "../../Component/services/api";
import { useForm } from "react-hook-form";
import UserMenu from "../dev-reports/UserMenu/UserMenu";
import StartEndDate from "../dev-reports/StartEndDate/StartEndDate";
import LoadingBar from "react-top-loading-bar";
import { useNavigate } from "react-router-dom";
import { ColorContext } from "../../context/ColorContext";

const LeaveReportFilter = ({
  user,
  getAllUser,
  setgetAllUser,
  setstartDate,
  setendDate,
  setLeaves,
  startDate,
  endDate,
  setTotalDays,
}) => {
  const { handleSubmit } = useForm();
  const api = useApi();
  const [progress, setProgress] = useState(0);
  const history = useNavigate();
  const [secondaryColor] = useContext(ColorContext);

  const onSubmit = (data) => {
    // console.log(data);

    setProgress(80);
    api
      .get(
        `v1/leaveReports/?&userID=${
          getAllUser.length === 0 ? undefined : getAllUser
        }&startDate=${startDate}&endDate=${endDate}`
      )
      .then(
        (response) => {
          // console.log(response);
          setLeaves(response.data.leaves);
          setTotalDays(response.data.totalDays);
          setProgress(100);
        },
        (error) => {
          // console.log(error);
          setProgress(100);
          if (error.response.status === 401) {
            window.localStorage.clear();
            history("/");
          }
        }
      );
  };
  return (
    <div className="leave-report-filter">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex-inputs">
          <UserMenu
            userList={user}
            getAllUser={getAllUser}
            setgetAllUser={setgetAllUser}
          />

          <div className="inner-Input">
            <StartEndDate setstartDate={setstartDate} setendDate={setendDate} />
          </div>

          <div className="go-button">
            <button
              className="btn"
              type="submit"
              style={{ color: `${secondaryColor}` }}
            >
              GO
            </button>
          </div>
        </div>
      </form>
      <LoadingBar
        color="black"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
        loaderSpeed={3000}
        waitingTime={1000}
      />
    </div>
  );
};

export default LeaveReportFilter;
