import React, { useState, useContext } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import "./AddClient.scss";
// import { AddClientProps } from "./AddClient.types";
import useApi from "../../../Component/services/api";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation, useNavigate } from "react-router-dom";
import { ColorContext } from "../../../context/ColorContext";
import moment from "moment";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { addClient, getAllClient } from "../../../Reducers/ClientSlice";
import { useForm } from "react-hook-form";

export default function AddClient({
  getAllClients,
  setProgress,
  clients,
  applyClientCss,
  isHeader,
  setValueAsEmpty
}) {
  const api = useApi();
  const [clientName, setclientName] = useState("");
  const [showErr, setshowErr] = useState(false);
  const [warnError, setwarnError] = useState("");
  const [secondaryColor, setSecondaryColor] = useContext(ColorContext);
  const [countRntry, setCountEntry] = useState(0)
  const [isDisable, setIsDisable] = useState(false)
  const history = useNavigate();
  const dispatch = useDispatch()
  const location = useLocation()
  const [open, setOpen] = useState(isHeader ? true : false);
  const [clientsPath, setClientsPath] = useState(location.pathname == "/client")

  const handleClickOpen = () => {
    // console.log(clients)
    // console.log(clients.length)
    setIsDisable(false)
    setCountEntry(0)
    setOpen(true);
  };
  const {
    register,
    reset,
    formState: { errors },
    watch,
    setValue
  } = useForm();
  
  const handleClose = () => {
    // setValue("Client", "")
    setValueAsEmpty()
    // const data = watch("Client")
    // console.log(data)
    setCountEntry(0)
    setOpen(false);
  };

  const handleSubmit = (clientName) => {
    // console.log(clients, "clients")
    let alreadyPresent = false;
    if(clients.length > 0){
      clients.map((el)=>{
        if(el.clientName === clientName){
          // console.log("yess")
          alreadyPresent = true;
        }
      })
    }
    setProgress(80);
      const createdAt = new Date();
      // console.log(localStorage.getItem("workspace_id"), "wprkspaceId")
      const token  = localStorage.getItem("access_token")

    if(!alreadyPresent){
      
      // console.log(token , "create")
      dispatch(addClient({clientName,createdAt, clients}))
      setProgress(100);
            handleClose();
            // dispatch(getAllClient())
            // getAllClients(localStorage.getItem("workspace_id"));
            // console.log(clients, "clients")
            setclientName("");
            setwarnError("");
      // api
      //   .post("https://api.trackify.ai/client/create", {
      //     clientName: clientName,
      //     createdAt : moment(createdAt).format("MM/DD/YYYY"),
      //     workspaceID : localStorage.getItem("workspace_id"),
      //     token
      //   })
      //   .then(
      //     (response) => {
      //       // console.log(response, "response");
      //       setProgress(100);
      //       handleClose();
      //       dispatch(getAllClient())
      //       // getAllClients(localStorage.getItem("workspace_id"));
      //       console.log(clients, "clients")
      //       setclientName("");
      //       setwarnError("");
      //     },
      //     (err) => {
      //       console.log(err);
      //       // setwarnError(err.response.data.error);
      //       setProgress(100);
      //       // console.log(err, "error")
      //       if (err.response.status === 401) {
      //         window.localStorage.clear();
      //         history("/");
      //       }
      //     }
      //   );
    }else{
      setProgress(100);
      toast.error("Client with this name already exists!", {
        theme: "colored",
        position: "top-left",
      })
    }
  };

  return (
    <div className="add-client">
      <div className="box-header" onClick={handleClickOpen} style={{padding: !applyClientCss? "30px 0 0 0" : "0px",
       marginBottom: clientsPath?"0px":"0px" }}  >
        <div className="add-client" style={{ color: `${secondaryColor}`,
        fontSize: !clientsPath? "20px" : "20px", padding: applyClientCss?"0":"10px",
          }}>
          
          {isHeader ? "" : "+ Add Client"}
        </div>
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle id="alert-dialog-title">
          <div className="justify-content-between main-header">
            <h6
              className="add-client-title"
              style={{ color: `${secondaryColor}` }}
            >
              Add New Client
            </h6>
            {/* <i
              className="far fa-times-circle"
              style={{
                cursor: "pointer",
                color: "#878787",
                fontSize: "1.4rem",
              }}
              onClick={handleClose}
            ></i> */}

            <IconButton
              aria-label="close"
              onClick={() => {
                handleClose();
                setwarnError("");
                setclientName("");
              }}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
              style={{
                border: "1px solid #9e9e9e",
                padding: 0,
                marginTop: "7px",
                width: "24px",
                height: "24px",
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            className="add-client-middle"
          >
            {showErr && (
              <span className="client-error" id="client-error">Client Name can not be blank
              </span>
            )}
            {warnError && <span className="client-error" id="client-error" >{warnError}</span>}
            <input
              type="text"
              className="form-control inputBorder"
              placeholder="Enter Client Name"
              onChange={(e) =>{
                setclientName(e.target.value)
                setIsDisable(false)
                setCountEntry(0)
              }}
            />
            <button
              className="btn w-100 submit"
              style={{ backgroundColor: `${secondaryColor}` }}
              onClick={() => {
                if (clientName !== "") {
                  setshowErr(false);
                  handleSubmit(clientName);
                } else {
                  setshowErr(true);
                }
                setCountEntry(countRntry + 1);
                if(countRntry === 0){
                  setIsDisable(true)
                }
                // console.log(countRntry)
              }}
              disabled = {isDisable}

            >
              Submit
            </button>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
