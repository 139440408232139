import React, { createContext, useState } from "react";

const TextHoverContext = createContext();

const TextHoverProvider = (props) => {
  const [PrimaryTextHover, setPrimaryTextHover] = useState("");

  return (
    <>
      <TextHoverContext.Provider
        value={[PrimaryTextHover, setPrimaryTextHover]}
      >
        {props.children}
      </TextHoverContext.Provider>
    </>
  );
};

export { TextHoverContext };
export { TextHoverProvider };
