import * as React from "react";
import { useContext } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import "./UpdateClient.scss";
import EditIcon from "../../../assets/images/icons/edit.png";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import useApi from "../../../Component/services/api";
import { useNavigate } from "react-router-dom";
import { ColorContext } from "../../../context/ColorContext";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { getAllClient, updateClient } from "../../../Reducers/ClientSlice";

export default function UpdateClient({
  client,
  setProgress,
  getAllClients,
  clients
}) {
  const api = useApi();
  const [open, setOpen] = React.useState(false);
  const [clientInput, setclientInput] = React.useState("");
  const [showErr, setshowErr] = React.useState(false);
  const [warnError, setwarnError] = React.useState("");
  const [secondaryColor, setSecondaryColor] = useContext(ColorContext);
  const history = useNavigate();
  const dispatch = useDispatch()

  const handleClickOpen = () => {
    // console.log(clients)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    // console.log(client.clientName);
    setclientInput(client.clientName);
  }, [client.clientName]);

  const clientUpdate = () => {
    setProgress(80);
    // console.log(client)
    const token  = localStorage.getItem("access_token")
    let alreadyPresent = false;
    if(clients.length > 0){
      clients.map((el)=>{
        if(el.clientName === clientInput){
          // console.log("yess")
          alreadyPresent = true;
        }
      })
    }
    if(!alreadyPresent){
      dispatch(updateClient({client, clientInput,clients}))
      setProgress(100);
            handleClose();
                  setclientInput("");
            setwarnError("");
      // api
      //   .patch("https://api.trackify.ai/client/updateClient", {
      //     clientID: client._id,
      //     clientName: clientInput,
      //     token
      //   })
      //   .then(
      //     (response) => {
      //       // console.log(response);
      //       setProgress(100);
      //       handleClose();
      //       dispatch(getAllClient())
      //       // getAllClients(localStorage.getItem("workspace_id"));
      //       setclientInput("");
      //       setwarnError("");
      //     },
      //     (error) => {
      //       console.log(error);
      //       setwarnError(error.response.data.error);
      //       setProgress(100);
  
      //       if (error.response.status === 401) {
      //         window.localStorage.clear();
      //         history("/");
      //       }
      //     }
      //   );
    }else{
      setProgress(100);
      toast.error("Client with this name already exist.", {
        theme: "colored",
        position: "top-left",
      })
    }
  };

  return (
    <div className="add-client">
      <div onClick={handleClickOpen}>
        <img
          src={EditIcon}
          alt="edit"
          width="25"
          style={{
            cursor: "pointer",
          }}
        />
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle id="alert-dialog-title">
          <div className=" justify-content-between main-header">
            <h6
              className="add-client-title"
              style={{ color: `${secondaryColor}` }}
            >
              Update Client
            </h6>
            {/* <i
              className="far fa-times-circle"
              style={{
                cursor: "pointer",
                color: "#878787",
                fontSize: "1.4rem",
              }}
              onClick={handleClose}
            ></i> */}
            <IconButton
              aria-label="close"
              onClick={() => {
                handleClose();
                setclientInput("");
                setwarnError("");
              }}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
              style={{
                border: "1px solid #9e9e9e",
                padding: 0,
                marginTop: "7px",
                width: "24px",
                height: "24px",
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            className="add-client-middle"
          >
            {showErr && (
              <span className="client-error" id="client-error">Client Name can not be blank</span>
            )}
            {warnError && <span className="client-error" id="client-error" >{warnError}</span>}
            <input
              type="text"
              className="form-control inputBorder"
              placeholder="Enter Client Name"
              value={clientInput}
              onChange={(e) => {
                setclientInput(e.target.value);
              }}
            />
            <button
              className="btn w-100 submit"
              style={{ backgroundColor: `${secondaryColor}` }}
              onClick={() => {
                if (clientInput !== "") {
                  setshowErr(false);
                  clientUpdate();
                } else {
                  setshowErr(true);
                }
              }}
            >
              Submit
            </button>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
