import React, { useState, useEffect, useContext } from "react";
import "./Projects.scss";
import LoadingBar from "react-top-loading-bar";
import CustomTabs from "../Atoms/CustomTabs";
import useApi from "../services/api";
// import { ReportType } from "./Projects.types";
import CustomTable, { IColumn } from "../Atoms/CustomTable";
import { Paper } from "@mui/material";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import CompleteModel from "../../Views/Projects/Complete/CompleteModel";
import ReCompleteModel from "../../Views/Projects/Complete/ReCompleteModel";
import AddProject from "../../Views/Projects/AddProject/AddProject";
import UpdateProjects from "../../Views/Projects/UpdateProjects/UpdateProjects";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ColorContext } from "../../context/ColorContext";
import { useDispatch, useSelector } from "react-redux";
import { addProject, getAllProject } from "../../Reducers/ProjectSlice";

const theme = createTheme({
  typography: {
    fontFamily:
      '"Source Sans Pro", "Helvetica Neue", "Helvetica", "Arial", "sans-serif"',
  },
});

const LightPaper = styled(Paper)({
  "&": {
    boxShadow: "0.2px 1px 0.5px 0px rgb(0 0 0 / 9%)",
  },
});

const Projects = () => {
  const api = useApi();
  const [projectReports, projectReportsSet] = useState([]);
  const [progress, setProgress] = useState(0);
  const [projects, setProjects] = useState({});
  const history = useNavigate();
  const userInfo = window.localStorage.getItem("tt-userinfo");
  const [secondaryColor, setSecondaryColor] = useContext(ColorContext);
  const [stateData, setStateData] = useState([])
const [usersState,setUsersState] = useState([])
const [projectID, setProjectID] = useState("")
const [isHeader, setIsHeader] = useState(false)



  const dispatch = useDispatch()

  let projectStateData = useSelector((state)=>{
    return state.projects.test[localStorage.getItem("workspace_id")]
  })
  // console.log(projectStateData, "project ")
  let usertStateData = useSelector((state)=>{
    return state.users.test[localStorage.getItem("workspace_id")]
  })
  // console.log(usertStateData, "userStateData")
  let clientStateData = useSelector((state)=>{
    return state.clients.test[localStorage.getItem("workspace_id")]
  })

  // console.log(projectStateData,usertStateData, "project state data")

  useEffect(() => {
    setSecondaryColor(window.localStorage.getItem("secondary_color_theme"));
  }, [secondaryColor]);

  const userInfoRole = userInfo && JSON.parse(userInfo).role;

  const getAllProjects = () => {
    projectReportsSet([]);
    // projectReportsSet(projectStateData);
    setProgress(80);
    const workspaceID = localStorage.getItem("workspace_id")
    const token  = localStorage.getItem("access_token")
    const userId = JSON.parse(localStorage.getItem("tt-userinfo"))
    const {_id} = userId
    axios
      .all([
        api.get(`https://api.trackify.ai/projects/proj/${workspaceID}/${token}/${_id}`),
        api.post("https://api.trackify.ai/client/clientsdata", {
          wkspc_id : localStorage.getItem("workspace_id"),
          token
        }),
        api.post("https://api.trackify.ai/user/users", {
          wkspcId : localStorage.getItem("workspace_id")
        }),
      ])
      .then(
        (response) => {
            // console.log(response, "responseeee in  pro")
            // console.log(secondaryColor, "secondary")
            // dispatch(addProject(response[0].data.projects))
          setProjects({
            clients: response[1].data,
            users: response[2].data,
            project : response[0].data.projects
          });
          // dispatch(addProject(response[0].data.projects))

          response[0].data.projects.forEach((element) => {
            element.Completed = (
              <CompleteModel
                projects={element}
                getAllProjects={getAllProjects}
                bgColor={
                  element.projectStatus === "1" ? `#1EABB2` : "white"
                }
              />
            );
            element.Action = (
              <UpdateProjects
                projects={{
                  clients: response[1].data,
                  users: response[2].data,
                  team: response[2].data,
                  project : response[0].data.projects
                }}
                projectData={element}
                getAllProjects={getAllProjects}
              />
            );
          });

          projectReportsSet(response[0].data.projects);
          // console.log(response[0].data.projects, "helo")
          // console.log(projectStateData, "helo 2")
          // projectReportsSet(projectStateData);

          setProgress(100);
        },
        (error) => {
          // console.log(error.response.status);
          setProgress(100);
          if (error.response.status === 401) {
            window.localStorage.clear();
            history("/");
          }
        }
      );
  };

  // useEffect(()=>{
  //   setUsersState([...usertStateData])
  //   setStateData(projectStateData)

  // },[projectStateData])



  useEffect(() => {
    // console.log()
    // let newDataXyz = [...usertStateData]
    // console.log(newData, "newDaya")
    if ( projectStateData && usertStateData && projectStateData.length !== 0){
      projectReportsSet(projectStateData)
      // console.log("Yes redux")
      // console.log(stateData, "stateData")
      const newData = projectStateData.map((element)=>{
        // console.log(element, "element")
        return { ...element, Action: <UpdateProjects
          projects={{
            clients: clientStateData,
            users: usertStateData,
            team: usertStateData,
            project : projectStateData
          }}
          projectData={element}
          getAllProjects={getAllProject}
        />, Completed : <CompleteModel
        projects={element}
        getAllProjects={getAllProject}
        bgColor={
          element.projectStatus === "1" ? `#1EABB2` : "white"
        }
      /> };
        
              
        
        
      })
      // console.log(newData, "newData")
      if(newData.length === 0){
        projectReportsSet(projectStateData)
      }else{
        projectReportsSet(newData)
      }
      // projectReportsSet(newData)
    }else{
      dispatch(getAllProject())
      // getAllProjects();
    }
  }, [projectStateData, usertStateData]);

  const headCells =
    userInfoRole === "admin"
      ? [
          { id: "projectName", label: "Project Name", align: "left" },
          { id: "projectDescription", label: "Description", align: "center" },
          { id: "estimatedHours", label: "Estimated Hours", align: "center" },
          { id: "timeSpent", label: "Hours Spent", align: "center" },
          { id: "progress", label: "Progress (%)", align: "center" },
          { id: "Completed", label: "Completed", align: "center" },
          // { id: "ReCompleted", label: "Recreate Completed", align: "center" },
          { id: "Action", label: "Action", align: "center" },
        ]
      : [
          { id: "projectName", label: "Project Name", align: "left" },
          { id: "projectDescription", label: "Description", align: "center" },
          { id: "estimatedHours", label: "Estimated Hours", align: "center" },
          { id: "timeSpent", label: "Hours Spent", align: "center" },
          { id: "progress", label: "Progress (%)", align: "center" },
        ];

  const cellComplete =
    userInfoRole === "admin"
      ? [
          { id: "projectName", label: "Project Name", align: "left" },
          { id: "projectDescription", label: "Description", align: "center" },
          { id: "estimatedHours", label: "Estimated Hours", align: "center" },
          { id: "timeSpent", label: "Hours Spent", align: "center" },
          { id: "progress", label: "Progress (%)", align: "center" },
          { id: "Completed", label: "Completed", align: "center" },
        ]
      : [
          { id: "projectName", label: "Project Name", align: "left" },
          { id: "projectDescription", label: "Description", align: "center" },
          { id: "estimatedHours", label: "Estimated Hours", align: "center" },
          { id: "timeSpent", label: "Hours Spent", align: "center" },
          { id: "progress", label: "Progress (%)", align: "center" },
        ];

  return (
    <ThemeProvider theme={theme}>
      <div className="projects">
        <div className="d-flex justify-content-between align-items-center">
          <div className="box-header">
            <div className="page-title" style={{ color: `${secondaryColor}` }}>
              Projects
            </div>
          </div>
          {userInfoRole === "admin" && usertStateData !== undefined && (
            <AddProject projects={{
              clients: clientStateData,
              users: usertStateData,
              team: usertStateData,
              project : projectStateData
            }} getAllProjects={getAllProjects} setProjectID={setProjectID} 
               isHeader={isHeader} />
          )} 
        </div>

        <LightPaper>
          <CustomTabs
            ariaLabel="Project Tabs"
            labels={["Ongoing Projects", "Completed Projects"]}
            tabsContent={[
              <CustomTable
                headCells={headCells}
                rows={projectReports.filter(
                  (pr) => parseInt(pr.projectStatus) === 0
                )}
                rowIdentifier="projectID"
                id="Ongoing-projects-table"
              />,
              <CustomTable
                headCells={cellComplete}
                rows={projectReports.filter(
                  (pr) => parseInt(pr.projectStatus) === 1
                )}
                rowIdentifier="projectID"
                id="Completed-projects-table"
              />,
            ]}
          />
        </LightPaper>

        <LoadingBar
          color="black"
          progress={progress}
          onLoaderFinished={() => setProgress(0)}
          loaderSpeed={3000}
          waitingTime={1000}
        />
      </div>
    </ThemeProvider>
  );
};

export default Projects;
