import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import CompleteModel from "../Views/Projects/Complete/CompleteModel";


    // const workspaceID = localStorage.getItem("workspace_id")
    const token  = localStorage.getItem("access_token")
    // const userId = JSON.parse(localStorage.getItem("tt-userinfo"))
    // const {_id} = userId


export const getAllUser = createAsyncThunk(
    "user/getall",
    async (name, thunkapi) => {
      const workspaceID = localStorage.getItem("workspace_id")
      try {
        const res = await axios.post("https://api.trackify.ai/user/users", {
            wkspcId : localStorage.getItem("workspace_id")
          })
        // console.log(res, " res in redux User")
        const data = { data : res.data, id:workspaceID }
        return data;
      } catch (e) {
        return thunkapi.rejectWithValue(e.message);
      }
    }
  );

  const initialState = {
    users: [],
    allUsers: [],
    test : {}
  };

const userSlice = createSlice({
    name : "userSlice",
    initialState,
    reducers : {  },
    extraReducers(builder){
        builder.addCase(getAllUser.fulfilled, (state, action)=>{
            // state.users = action.payload
            const workspace_id = action.payload.id
          // const obj = {workspace_id : action.payload.data}
          // console.log(state.projects, "state projects")
          let existing = false;
          state.users.map((el)=>{
            if(el.id == workspace_id){
              existing = true
            }
          })
          //   let existing = false;
          // state.clients.map((el)=>{
            //   if(el.id == id){
              //     existing = true
              //   }
              // })
              if(!existing){
                const {id, data} = action.payload
                state.users = [...state.users, action.payload]
            state.test[id] = data
          }
            // state.allUsers = [{name: "Select All",
            // userID: "all selected!"}, ...action.payload]
        })
    }
})

// console.log(userSlice.actions)
// export {projects}
export default userSlice.reducer
 
// export const {addProject} = projectSlice.actions