import React, { useState, useEffect, useContext } from "react";
import "./HolidaySetting.scss";
import AddHoliday from "../../Views/holiday-setting/AddHoliday/AddHoliday";
import UpdateHoliday from "../../Views/holiday-setting/UpdateHoliday/UpdateHoliday";
import useApi from "../services/api";
import LoadingBar from "react-top-loading-bar";
// import { holidayType } from "./HolidaySetting.types";
import { useNavigate } from "react-router-dom";
import { ColorContext } from "../../context/ColorContext";
import { useDispatch, useSelector } from "react-redux";
import { getAllHoliday } from "../../Reducers/holidaySlice";
const HolidaySetting = () => {
  const api = useApi();
  const [holiday, setHoliday] = useState([]);
  const [progress, setProgress] = useState(0);
  const [secondaryColor] = useContext(ColorContext);
  const history = useNavigate();
  const dispatch = useDispatch()

  const getAllHolidays = () => {
    setHoliday([]);
    setProgress(80);
    const workspaceId = localStorage.getItem("workspace_id")
    // api.get("v1/holiday/").then(
    api.get(`https://api.trackify.ai/holiday/getHoliday/${workspaceId}`).then(
      (response) => {
        // console.log(response);
        setHoliday(response.data);
        setProgress(100);
      },
      (error) => {
        console.log(error);
        setProgress(100);
        if (error.response.status === 401) {
          window.localStorage.clear();
          history("/");
        }
      }
    );
  };
  const holidayStateData = useSelector((state)=>{
    return state.holidays.holidays
  })

  useEffect(() => {
    // getAllHolidays();
    if(holidayStateData.length !== 0){
      setHoliday(holidayStateData);
    }else{
      dispatch(getAllHoliday())
    }
  }, [holidayStateData]);

  return (
    <div className="holiday-setting">
      <div className="d-flex justify-content-between">
        <div className="box-header">
          <div className="page-title" style={{ color: `${secondaryColor}` }}>
            Holidays
          </div>
        </div>
        <AddHoliday getAllHolidays={getAllHolidays} /> 
      </div>

      <div className="table-content">
        <table className="table">
          <tbody>
            <tr>
              <th scope="col">Holiday Name</th>
              <th scope="col" className="text-center">
                Date
              </th>
              <th scope="col" className="text-center">
                Update
              </th>
            </tr>

            {holiday.map((data, index) => (
              <tr key={index}>
                <td>{data.holidayName}</td>
                <td className="text-center">{data.holidayDate}</td>
                <td className="text-center">
                  <UpdateHoliday
                    getAllHolidays={getAllHolidays}
                    holiday={data}
                  />
                </td>
              </tr>
            ))}

            {/* {clients.length === 0 && (
              <tr className="no-record-found">
                <td align="center" colSpan={6}>
                  No Record Found
                </td>
              </tr>
            )} */}
          </tbody>
        </table>
      </div>

      <LoadingBar
        color="black"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
        loaderSpeed={3000}
        waitingTime={1000}
      />
    </div>
  );
};

export default HolidaySetting;
