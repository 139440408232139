import { useContext, useEffect } from "react";
import Router from "./Router/Router";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/scss/main.scss";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import { DataContext } from "./context/DataContext";
import { ColorContext } from "./context/ColorContext";
import { TextColorContext } from "./context/TextColorContext";
import { TextHoverContext } from "./context/TextHoverContext";

function App() {
  const [primaryColor, setPrimaryColor] = useContext(DataContext);
  const [secondaryColor, setSecondaryColor] = useContext(ColorContext);
  const [PrimaryTextColor, setPrimaryTextColor] = useContext(TextColorContext);
  const [PrimaryTextHover, setPrimaryTextHover] = useContext(TextHoverContext);

  useEffect(() => {
    setPrimaryColor(window.localStorage.getItem("primary_color_theme"));
  }, [primaryColor]);

  useEffect(() => {
    setSecondaryColor(window.localStorage.getItem("secondary_color_theme"));
  }, [secondaryColor]);

  useEffect(() => {
    setPrimaryTextColor(window.localStorage.getItem("text_color_theme"));
  }, [PrimaryTextColor]);

  useEffect(() => {
    setPrimaryTextHover(window.localStorage.getItem("textHover_color_theme"));
  }, [PrimaryTextHover]);

  return (
    <div>
      <Router />
      <ToastContainer />
    </div>
  );
}

export default App;
