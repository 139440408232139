import React, { useContext, useEffect } from "react";
import {
  Box,
  Drawer,
  List,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Button,
} from "@mui/material";
import ClearIcon from "../assets/images/icons/cancel.png";
import DashboardIcon from "../assets/images/icons/dashboard.png";
import ProjectIcon from "../assets/images/icons/monitor.png";
import ReportIcon from "../assets/images/icons/pie-chart.png";
import SummaryIcon from "../assets/images/icons/summary.png";
import LeaveIcon from "../assets/images/icons/leave.png";
import ApplyIcon from "../assets/images/icons/apply.png";
import LeaveReportIcon from "../assets/images/icons/report.png";
import ArrowBackIosIcon from "@mui/icons-material/ChevronLeft";
import ExpandMore from "@mui/icons-material/ExpandMore";
import FlightIcon from "../assets/images/icons/holiday.png";
import HolidaysIcon from "../assets/images/icons/holidays.png";
import ProfileIcon from "../assets/images/icons/avatar.png";
import LogoutIcon from "../assets/images/icons/logout.png";
import MenuIcon from "../assets/images/icons/menu.png";
import ClientIcon from "../assets/images/icons/client.png";
import UserIcon from "../assets/images/icons/users.png";
import LeaveSettingsIcon from "../assets/images/icons/leaveSettings.png";
import LeaveManagementIcon from "../assets/images/icons/leaveManagement.png";
import { NavLink } from "react-router-dom";
// import { Anchor } from "./Footer.types";
import LoadingBar from "react-top-loading-bar";
import useApi from "../Component/services/api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { styled } from "@mui/material/styles";
import "./Footer.scss";
import { DataContext } from "../context/DataContext";
import { ColorContext } from "../context/ColorContext";
import { TextColorContext } from "../context/TextColorContext";
import { TextHoverContext } from "../context/TextHoverContext";
import { useDispatch } from "react-redux";

export default function Footer() {
  const api = useApi();
  const [state, setState] = React.useState({
    bottom: false,
  });
  const [openReport, setOpenReport] = React.useState(false);
  const [openLeave, setOpenLeave] = React.useState(false);
  const [openHoliday, setOpenHoliday] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const location = useNavigate();
  const [primaryColor, setPrimaryColor] = useContext(DataContext);
  const [secondaryColor, setSecondaryColor] = useContext(ColorContext);
  const [PrimaryTextColor, setPrimaryTextColor] = useContext(TextColorContext);
  const [PrimaryTextHover, setPrimaryTextHover] = useContext(TextHoverContext);
  const userInfo = window.localStorage.getItem("tt-userinfo");
  const dispatch = useDispatch()

  const userInfoRole = userInfo && JSON.parse(userInfo).role;

  const colorOfFooter = secondaryColor;
  const colorOfFooterTextHover = PrimaryTextHover;
  const colorOfFooterText = PrimaryTextColor;

  useEffect(() => {
    setPrimaryColor(window.localStorage.getItem("primary_color_theme"));
  }, [primaryColor]);

  useEffect(() => {
    setSecondaryColor(window.localStorage.getItem("secondary_color_theme"));
  }, [secondaryColor]);

  useEffect(() => {
    setPrimaryTextColor(window.localStorage.getItem("text_color_theme"));
  }, [PrimaryTextColor]);

  useEffect(() => {
    setPrimaryTextHover(window.localStorage.getItem("textHover_color_theme"));
  }, [PrimaryTextHover]);

  const ThemedDrawer = styled(Drawer)({
    ".MuiDrawer-paperAnchorBottom": {
      width: "300px !important",
      borderTopLeftRadius: "12px !important",
      borderTopRightRadius: "12px !important",
      backgroundColor: `${colorOfFooter} !important`,
      color: `${colorOfFooterText} !important`,
      img: {
        width: "26px !important",
        filter: "brightness(0) invert(1) !important",
      },
      a: {
        color: `${colorOfFooterText} !important`,
      },
    },
    ".MuiCollapse-wrapper": {
      background: `${colorOfFooter} !important`,
      ".MuiListItem-root:hover": {
        color: `${colorOfFooterTextHover} !important`,
      },
    },
    ".MuiListItem-button:hover": {
      color: `${colorOfFooterTextHover} !important`,
    },
  });
  const handelRfresh = ()=>{
    window.location.reload();
  }

  const toggleDrawer =
    (anchor, open) =>
    (event) => {
      if (
        event.type === "keydown" &&
        (event.key === "Tab" ||
          event.key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const logout = () => {
    setProgress(80);
    api.get("https://api.trackify.ai/profile/logout").then(
      (response) => {
        // console.log(response, "logout");
        setProgress(100);
        window.localStorage.clear();
        location("/");
        handelRfresh()
      },
      (error) => {
        // console.log(error);
        toast.error(error.response.data.error, {
          theme: "colored",
        });
        setProgress(100);
      }
    );
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
    >
      <List>
        <ListItem
          button
          onClick={toggleDrawer(anchor, false)}
          onKeyDown={toggleDrawer(anchor, false)}
          style={{
            cursor: "pointer",
            paddingTop: "8px",
            paddingBottom: "8px",
            paddingLeft: "16px",
            paddingRight: "16px",
          }}
        >
          <ListItemIcon>
            <img src={ClearIcon} alt="close drawer" />
          </ListItemIcon>
          <ListItemText primary={userInfo && JSON.parse(userInfo).name} />
        </ListItem>
      </List>
      <Divider
        style={{
          opacity: 1,
          margin: 0,
          border: 0,
          borderTop: "1px solid gold",
        }}
      />
      <List>
        <ListItem
          style={{
            cursor: "pointer",
            paddingTop: "8px",
            paddingBottom: "8px",
            paddingLeft: "16px",
            paddingRight: "16px",
          }}
          component={NavLink}
          to="/dashboard"
          onClick={toggleDrawer(anchor, false)}
          button
        >
          <ListItemIcon>
            <img src={DashboardIcon} alt="Dashboard" />
          </ListItemIcon>
          <ListItemText primary={"Dashboard"} />
        </ListItem>
        {userInfoRole === "admin" && (
          <ListItem
            button
            component={NavLink}
            to="/client"
            onClick={toggleDrawer(anchor, false)}
            style={{
              cursor: "pointer",
              paddingTop: "8px",
              paddingBottom: "8px",
              paddingLeft: "16px",
              paddingRight: "16px",
            }}
          >
            <ListItemIcon>
              <img src={ClientIcon} alt="Clients" />
            </ListItemIcon>
            <ListItemText primary={"Clients"} />
          </ListItem>
        )}

        <ListItem
          style={{
            cursor: "pointer",
            paddingTop: "8px",
            paddingBottom: "8px",
            paddingLeft: "16px",
            paddingRight: "16px",
          }}
          component={NavLink}
          to="/projects"
          onClick={toggleDrawer(anchor, false)}
          button
        >
          <ListItemIcon>
            <img src={ProjectIcon} alt="Projects" />
          </ListItemIcon>
          <ListItemText primary={"Projects"} />
        </ListItem>

        {userInfoRole === "admin" && (
          <ListItem
            button
            component={NavLink}
            to="/user"
            onClick={toggleDrawer(anchor, false)}
            style={{
              cursor: "pointer",
              paddingTop: "8px",
              paddingBottom: "8px",
              paddingLeft: "16px",
              paddingRight: "16px",
            }}
          >
            <ListItemIcon>
              <img src={UserIcon} alt="Users" />
            </ListItemIcon>
            <ListItemText primary={"Users"} />
          </ListItem>
        )}

        <ListItem
          button
          onClick={() => {
            setOpenReport(!openReport);
            setOpenLeave(false);
            setOpenHoliday(false);
          }}
          style={{
            cursor: "pointer",
            paddingTop: "8px",
            paddingBottom: "8px",
            paddingLeft: "16px",
            paddingRight: "16px",
          }}
        >
          <ListItemIcon>
            <img src={ReportIcon} alt="Reports" />
          </ListItemIcon>
          <ListItemText primary={"Reports"} />
          {openReport ? <ExpandMore /> : <ArrowBackIosIcon />}
        </ListItem>

        <Collapse in={openReport} timeout="auto" unmountOnExit>
          <List component="div">
            <ListItem
              sx={{ pl: 4 }}
              style={{
                cursor: "pointer",
                paddingTop: "8px",
                paddingBottom: "8px",
                paddingLeft: "16px",
                paddingRight: "16px",
              }}
              component={NavLink}
              to="/reports/summary"
              onClick={toggleDrawer(anchor, false)}
            >
              <ListItemIcon>
                <img src={ReportIcon} alt="Summary" />
              </ListItemIcon>
              <ListItemText primary="Summary" />
            </ListItem>
            {userInfoRole === "admin" && (
              <ListItem
                sx={{ pl: 4 }}
                style={{
                  cursor: "pointer",
                  paddingTop: "8px",
                  paddingBottom: "8px",
                  paddingLeft: "16px",
                  paddingRight: "16px",
                }}
                component={NavLink}
                to="/reports/summarybydev"
                onClick={toggleDrawer(anchor, false)}
              >
                <ListItemIcon>
                  <img src={ReportIcon} alt="Dev Report" />
                </ListItemIcon>
                <ListItemText primary="Summary By Dev" />
              </ListItem>
            )}

            <ListItem
              sx={{ pl: 4 }}
              style={{
                cursor: "pointer",
                paddingTop: "8px",
                paddingBottom: "8px",
                paddingLeft: "16px",
                paddingRight: "16px",
              }}
              component={NavLink}
              to="/reports/detail"
              onClick={toggleDrawer(anchor, false)}
            >
              <ListItemIcon>
                <img src={SummaryIcon} alt="Detailed" />
              </ListItemIcon>
              <ListItemText primary="Detailed" />
            </ListItem>
          </List>
        </Collapse>

        {/* <ListItem
          button
          onClick={() => {
            setOpenLeave(!openLeave);
            setOpenReport(false);
            setOpenHoliday(false);
          }}
          style={{
            cursor: "pointer",
            paddingTop: "8px",
            paddingBottom: "8px",
            paddingLeft: "16px",
            paddingRight: "16px",
          }}
        >
          <ListItemIcon>
            <img src={LeaveIcon} alt="Leave" />
          </ListItemIcon>
          <ListItemText primary={"Leave"} />
          {openLeave ? <ExpandMore /> : <ArrowBackIosIcon />}
        </ListItem> */}

        {/* <Collapse in={openLeave} timeout="auto" unmountOnExit>
          <List component="div">
            <ListItem
              sx={{ pl: 4 }}
              style={{
                cursor: "pointer",
                paddingTop: "8px",
                paddingBottom: "8px",
                paddingLeft: "16px",
                paddingRight: "16px",
              }}
              component={NavLink}
              to="leave/applyLeave"
              onClick={toggleDrawer(anchor, false)}
            >
              <ListItemIcon>
                <img src={ApplyIcon} alt="Apply Leave" />
              </ListItemIcon>
              <ListItemText primary="Apply Leave" />
            </ListItem>
            {userInfoRole === "admin" && (
              <ListItem
                sx={{ pl: 4 }}
                style={{
                  cursor: "pointer",
                  paddingTop: "8px",
                  paddingBottom: "8px",
                  paddingLeft: "16px",
                  paddingRight: "16px",
                }}
                component={NavLink}
                to="/leaves/leaveSetting"
                onClick={toggleDrawer(anchor, false)}
              >
                <ListItemIcon>
                  <img src={LeaveSettingsIcon} alt="Apply Leave" />
                </ListItemIcon>
                <ListItemText primary="Leave Setting" />
              </ListItem>
            )}
            {userInfoRole === "admin" && (
              <ListItem
                sx={{ pl: 4 }}
                style={{
                  cursor: "pointer",
                  paddingTop: "8px",
                  paddingBottom: "8px",
                  paddingLeft: "16px",
                  paddingRight: "16px",
                }}
                component={NavLink}
                to="/leave/leaveManagement"
                onClick={toggleDrawer(anchor, false)}
              >
                <ListItemIcon>
                  <img src={LeaveManagementIcon} alt="Leave Management" />
                </ListItemIcon>
                <ListItemText primary="Leave Management" />
              </ListItem>
            )}

            <ListItem
              sx={{ pl: 4 }}
              style={{
                cursor: "pointer",
                paddingTop: "8px",
                paddingBottom: "8px",
                paddingLeft: "16px",
                paddingRight: "16px",
              }}
              component={NavLink}
              to="leave/leaveReport"
              onClick={toggleDrawer(anchor, false)}
            >
              <ListItemIcon>
                <img src={LeaveReportIcon} alt="Leave Report" />
              </ListItemIcon>
              <ListItemText primary="Leave Report" />
            </ListItem>
          </List>
        </Collapse> */}

        <ListItem
          button
          onClick={() => {
            setOpenHoliday(!openHoliday);
            setOpenReport(false);
            setOpenLeave(false);
          }}
          style={{
            cursor: "pointer",
            paddingTop: "8px",
            paddingBottom: "8px",
            paddingLeft: "16px",
            paddingRight: "16px",
          }}
        >
          <ListItemIcon>
            <img src={FlightIcon} alt="Holiday" />
          </ListItemIcon>
          <ListItemText primary={"Holiday"} />
          {openHoliday ? <ExpandMore /> : <ArrowBackIosIcon />}
        </ListItem>

        <Collapse in={openHoliday} timeout="auto" unmountOnExit>
          <List component="div">
            <ListItem
              sx={{ pl: 4 }}
              style={{
                cursor: "pointer",
                paddingTop: "8px",
                paddingBottom: "8px",
                paddingLeft: "16px",
                paddingRight: "16px",
              }}
              component={NavLink}
              to="/holiday"
              onClick={toggleDrawer(anchor, false)}
            >
              <ListItemIcon>
                <img src={HolidaysIcon} alt="Holiday" />
              </ListItemIcon>
              <ListItemText primary="Holiday" />
            </ListItem>
            {userInfoRole === "admin" && (
              <ListItem
                sx={{ pl: 4 }}
                style={{
                  cursor: "pointer",
                  paddingTop: "8px",
                  paddingBottom: "8px",
                  paddingLeft: "16px",
                  paddingRight: "16px",
                }}
                component={NavLink}
                to="/holiday/holiDaySetting"
                onClick={toggleDrawer(anchor, false)}
              >
                <ListItemIcon>
                  <img src={LeaveManagementIcon} alt="Holiday Setting" />
                </ListItemIcon>
                <ListItemText primary="Holiday Setting" />
              </ListItem>
            )}
          </List>
        </Collapse>

        <ListItem
          style={{
            cursor: "pointer",
            paddingTop: "8px",
            paddingBottom: "8px",
            paddingLeft: "16px",
            paddingRight: "16px",
          }}
          component={NavLink}
          to="/changePassword"
          onClick={toggleDrawer(anchor, false)}
          button
        >
          <ListItemIcon>
            <img src={ProfileIcon} alt="Profile" />
          </ListItemIcon>
          <ListItemText primary={"Profile"} />
        </ListItem>

        <ListItem
          button
          onClick={logout}
          style={{
            cursor: "pointer",
            paddingTop: "8px",
            paddingBottom: "8px",
            paddingLeft: "16px",
            paddingRight: "16px",
          }}
        >
          <ListItemIcon>
            &nbsp;
            <img src={LogoutIcon} alt="Logout" />
          </ListItemIcon>
          <ListItemText primary={"Logout"} />
        </ListItem>
      </List>
    </Box>
  );

  return (
    <div>
      {["bottom"].map((anchor) => (
        <React.Fragment key={anchor}>
          <button
            onClick={toggleDrawer(anchor, true)}
            className="ThemedButton"
            style={{ background: `${colorOfFooter}` }}
          >
            <img src={MenuIcon} alt="menu" />
            <span style={{ color: `${colorOfFooterText}` }}>
              {userInfo && JSON.parse(userInfo).name}
            </span>
          </button>
          <ThemedDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </ThemedDrawer>
        </React.Fragment>
      ))}

      <LoadingBar
        color="black"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
        loaderSpeed={3000}
        waitingTime={1000}
      />
    </div>
  );
}
