import * as React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
// const moment = require("moment");
import moment from "moment";
import { styled } from "@mui/material/styles";

const StyledTable = styled(Table)({
  "& .MuiTableCell-root": {
    padding: "8px",
    fontSize: "16px",
    color: "#54636f",
  },
  "& .MuiTableCell-head": {
    fontSize: "18px",
    fontWeight: "600",
  },
  "&": {
    borderTop: "1px solid #f4f4f4",
  },
  "& .MuiTableRow-root:last-child td": {
    borderBottom: "none",
  },
});



const CustomTable = ({
  headCells,
  rows,
  rowIdentifier,
  id,
}) => {

  const handelTimeSpent = (row, headCell)=>{
    // console.log(row[headCell.id], "value")
    const data = row[headCell.id];
    if(data != null){
    var minutes = Math.floor(moment.duration(data * 1000 ).asMinutes() % 60)
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    var seconds = Math.floor(moment.duration(data * 1000 ).asSeconds() % 60)
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    var hours = Math.floor(moment.duration(data * 1000 ).asHours())
    if (hours < 10) {
      hours = "0" + hours;
    }
    // console.log( hours, minutes, seconds, "time")
    // console.log(timeSpent, "time spent")

    var ActualDuraction = `${hours}:${minutes}:${seconds}`;
      return ActualDuraction
    }
    return null
  }
  // console.log(headCells,"headceels")
  // console.log(rows,"rows")
  // console.log(id,"id")
  return (
    <TableContainer sx={{ paddingTop: "12px" }}>
      <StyledTable aria-label={id} key={id}>
        <TableHead>
          <TableRow key={`table-header-${id}`}>
            {headCells.map((headCell) => (
              <TableCell key={String(headCell.id)} align={headCell.align}>
                {headCell.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => {
            return (
              <TableRow hover tabIndex={-1} key={index}>
                {headCells.map((headCell, index) => {
                  let value = row[headCell.id];
                  {headCell.id === "timeSpent" ?value = handelTimeSpent(row, headCell) :value = row[headCell.id]}
                  return (
                    <TableCell
                      key={`${index}`}
                      align={headCell.align}
                    >
                      {value ? value : "-"} 
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </StyledTable>
    </TableContainer>
  );
};

export default CustomTable;
