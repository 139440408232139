import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";


    // const workspaceID = localStorage.getItem("workspace_id")
    const token  = localStorage.getItem("access_token")
    // const userId = JSON.parse(localStorage.getItem("tt-userinfo"))
    // const {_id} = userId


export const getAllHoliday = createAsyncThunk(
    "holiday/getall",
    async (name, thunkapi) => {
      try {
        const workspaceId = localStorage.getItem("workspace_id")
        // api.get("v1/holiday/").then(
        const res = await axios.get(`https://api.trackify.ai/holiday/getHoliday/${workspaceId}`)
        return res.data;
      } catch (e) {
        return thunkapi.rejectWithValue(e.message);
      }
    }
  );
export const addHoliday = createAsyncThunk(
    "holiday/addHoliday",
    async (data, thunkapi) => {
      try {
        const workspaceId = localStorage.getItem("workspace_id")
        // api.get("v1/holiday/").then(
        const res = await axios.post("https://api.trackify.ai/holiday/addHoliday",data)
        // console.log(res, "res after add")
        return res.data;
      } catch (e) {
        return thunkapi.rejectWithValue(e.message);
      }
    }
  );
  export const updateHoliday = createAsyncThunk(
    "holiday/update",
    async (data, thunkapi) => {
        try {
            const res = await axios.patch("https://api.trackify.ai/holiday/updateHoliday", data)
            return data
        } catch (error) {
        return thunkapi.rejectWithValue(error.message);
        }
    }
  )

  const initialState = {
    holidays: [],
  };

const holidaySlice = createSlice({
    name : "holidaySlice",
    initialState,
    reducers : {  },
    extraReducers(builder){
        builder.addCase(getAllHoliday.fulfilled, (state, action)=>{
            state.holidays = action.payload
        })
        builder.addCase(addHoliday.fulfilled, (state, action)=>{
            state.holidays = action.payload
        })
        builder.addCase(updateHoliday.fulfilled, (state, action)=>{
            state.holidays.forEach((el)=>{
                // console.log(el, "el", action.payload)
                if(el._id === action.payload.holidayID){
                    el.holidayName = action.payload.holidayName
                    el.holidayDate = action.payload.holidayDate
                    el.description = action.payload.description
                    el.isYearly = action.payload.isYearly
                    el.restricted = action.payload.restricted
                    // console.log(action.payload.holidayName, "holiday Name in update")
                    // console.log(el, "emlelemt in upate case")
                    // console.log(el._id, el.holidayName)
                }
            })
            // state.holidays = data
        })
    }
})

// console.log(holidaySlice.actions)
// export {projects}
export default holidaySlice.reducer
 
// export const {addProject} = projectSlice.actions