import React, { createContext, useState } from "react";

const NewLeaveContext = createContext();

const NewLeaveProvider = (props) => {
  const [newLeaveData, setNewLeaveData] = useState([]);

  return (
    <>
      <NewLeaveContext.Provider value={[newLeaveData, setNewLeaveData]}>
        {props.children}
      </NewLeaveContext.Provider>
    </>
  );
};

export { NewLeaveContext };
export { NewLeaveProvider };
