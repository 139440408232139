

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";


    
    // const userId = JSON.parse(localStorage.getItem("tt-userinfo"))
    // const {_id} = userId



  

  const initialState = {
    clients: [],
  };

const logoutSlice = createSlice({
    name : "logoutSlice",
    initialState,
    reducers : {  },
    
})
export const resetState = (state, action )=>{
    state = undefined
}

// console.log(clientSlice.actions)
// export {projects}
export default logoutSlice.reducer
 
// export const {addProject} = projectSlice.actions