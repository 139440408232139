import React, { useState, useContext } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import CompleteIcon from "../../../assets/images/icons/complete.svg";
import useApi from "../../../Component/services/api";
import LoadingBar from "react-top-loading-bar";
import { useNavigate } from "react-router-dom";
import { ColorContext } from "../../../context/ColorContext";
import { DataContext } from "../../../context/DataContext";

export default function CompleteModel({
  projects,
  getAllProjects,
  bgColor,
}) {
  const api = useApi();
  const [secondaryColor] = useContext(ColorContext);
  const [primaryColor] = useContext(DataContext);
  const [open, setOpen] = useState(false);
  const [progress, setProgress] = useState(0);
  const history = useNavigate();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handelRfresh = ()=>{
    window.location.reload()
  }

  const completeProject = () => {
    // console.log(projects, "cmplt");
    const token  = localStorage.getItem("access_token")
    api
      .patch("https://api.trackify.ai/projects/complete", {
        projectID: projects._id,
        projectStatus: Number(projects.projectStatus) === 1 ? 0 : 1,
        token
      })
      .then(
        (response) => {
          // console.log(response);
          getAllProjects(localStorage.getItem("workspace_id"));
          handleClose();
          handelRfresh();
        },
        (error) => {
          // console.log(error);
          if (error.response.status === 401) {
            window.localStorage.clear();
            history("/");
          }
        }
      );
  };

  return (
    <div>
      <Button onClick={handleClickOpen}>
        <img
          src={CompleteIcon}
          alt="complete icon"
          style={{
            width: "25px",
            background: bgColor,
            borderRadius: "50px",
          }}
        />
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ fontFamily: "Source Sans Pro" }}
        >
          {`Are you sure, you want to marked the project as ${
            Number(projects.projectStatus) === 0 ? "complete" : "incomplete"
          } ?`}
        </DialogTitle>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={handleClose}
            autoFocus
            style={{
              borderColor: "rgb(252, 107, 108)",
              color: "white",
              background: `${secondaryColor}`,
            }}
          >
            Disagree
          </Button>
          <Button
            variant="contained"
            onClick={completeProject}
            style={{ background: `${primaryColor}` }}
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>

      <LoadingBar
        color="black"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
        loaderSpeed={3000}
        waitingTime={1000}
      />
    </div>
  );
}
