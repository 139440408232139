import React, { createContext, useState } from "react";

const ColorContext = createContext();

const ColorProvider = (props) => {
  const [secondaryColor, setSecondaryColor] = useState("");

  return (
    <>
      <ColorContext.Provider value={[secondaryColor, setSecondaryColor]}>
        {props.children}
      </ColorContext.Provider>
    </>
  );
};

export { ColorContext };
export { ColorProvider };
