import React, { createContext, useState } from "react";

const DataContext = createContext();

const DataProvider = (props) => {
  const [primaryColor, setPrimaryColor] = useState("");

  return (
    <>
      <DataContext.Provider value={[primaryColor, setPrimaryColor]}>
        {props.children}
      </DataContext.Provider>
    </>
  );
};

export { DataContext };
export { DataProvider };
