import React, { useState, useEffect, useContext } from "react";
import "./DevReport.scss";
import useApi from "../services/api";
import moment from "moment";
import LoadingBar from "react-top-loading-bar";
// import {
//   UserType,
//   ClientType,
//   ReportType,
//   ProjectType,
// } from "./DevReport.types";
import { convertHMS } from "../../Common/convertHMS";
import ReportFilter from "../../Views/report/ReportFilter/ReportFilter";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Bar, Pie } from "react-chartjs-2";
import { useNavigate } from "react-router-dom";
import { ColorContext } from "../../context/ColorContext";

ChartJS.register(ArcElement, Tooltip, Legend);

const DevReport = () => {
  const api = useApi();
  const [tabButtons, settabButtons] = useState(true);
  // const [user, setUser] = useState<UserType | []>([]);
  // const [client, setClient] = useState<ClientType | []>([]);
  const [report, setReport] = useState([]);
  const [startDate, setstartDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, setendDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [getClient, setgetClient] = useState([]);
  const [getAllUser, setgetAllUser] = useState([]);
  // const [project, setProject] = useState<ProjectType | []>([]);
  const [getAllProjectId, setgetAllProjectId] = useState([]);
  const [totalTimeDuration, settotalTimeDuration] = useState(null);
  const [progress, setProgress] = useState(0);
  const history = useNavigate();
  const [secondaryColor] = useContext(ColorContext);

  // const reportApiurl = "v1/summaryReportsDev/";
  const reportApiurl = "https://api.trackify.ai/reports/summarybydev";

  // console.log(totalTimeDuration, "totaltime")
  useEffect(() => {
    // console.log('coming here also')
    setProgress(80);
    api
      .get(
        `${reportApiurl}?projectID=${
          getAllProjectId.length === 0 ? undefined : getAllProjectId
        }&clientID=${getClient.length === 0 ? undefined : getClient}&userID=${
          getAllUser.length === 0 ? undefined : getAllUser
        }&startDate=${startDate}&endDate=${endDate}&description=${undefined}&workspaceId=${localStorage.getItem("workspace_id")}`
      )
      .then(
        (response) => {
          // console.log(response.data.data, "response");
          setReport(response.data.data);
          // console.log(response, "Res")
        //   console.log(response)

          let reducer = (acc, cur) => {
            return acc + Number(cur.date_of_joining);
          };
          settotalTimeDuration(response.data.data.reduce(reducer, 0));
          // console.log(response.data, "dataaa")
          setgetAllProjectId([]);
          setgetClient([]);
          setgetAllUser([]);
          setProgress(100);
        },
        (error) => {
          console.log(error);
          setProgress(100);
          if (error.response.status === 401) {
            window.localStorage.clear();
            history("/");
          }
        }
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function timeStringToFloat(time) {
    var hoursMinutes = time.split(/[.:]/);
    var hours = parseInt(hoursMinutes[0], 10);
    var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
    return hours + minutes / 60;
  }

  const graphData = {
    labels: report.map((data) => {
      return data.name;
    }),
    datasets: [
      {
        label: "",
        responsive: true,
        data: report.map((data) => {
          return parseFloat(
            timeStringToFloat(convertHMS(data.date_of_joining))).toFixed(2);
        }),
        backgroundColor: report.map(() => {
          return "#" + (Math.random().toString(16) + "000000").substring(2, 8);
        }),
        borderColor: "white",
        borderWidth: 2,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
      },
    },
  };

  return (
    <div className="DevReport">
      <div className="box-header">
        <div className="page-title" style={{ color: `${secondaryColor}` }}>
          Summary By Dev
        </div>
      </div>
      <ReportFilter
        getAllProjectId={getAllProjectId}
        getClient={getClient}
        getAllUser={getAllUser}
        startDate={startDate}
        setstartDate={setstartDate}
        endDate={endDate}
        setendDate={setendDate}
        setReport={setReport}
        settotalTimeDuration={settotalTimeDuration}
        setgetAllProjectId={setgetAllProjectId}
        setgetClient={setgetClient}
        setgetAllUser={setgetAllUser}
        setProgress={setProgress}
        // project={project}
        // client={client}
        // user={user}
        reportApiurl={reportApiurl}
      />
      <div className="middle-content" style={{ overflow: "auto" }}>
        <div className="tab-buttons">
          <button
            className="btn"
            onClick={() => settabButtons(true)}
            style={{
              background: tabButtons ? `${secondaryColor}` : "white",
              color: tabButtons ? "white" : "black",
            }}
          >
            Table View
          </button>
          <button
            className="btn"
            onClick={() => settabButtons(false)}
            style={{
              background: tabButtons ? "white" : `${secondaryColor}`,
              color: tabButtons ? "black" : "white",
            }}
          >
            Graph View
          </button>
        </div>

        <div className="table-content">
          <div className="total-time">
            <p>
              Total:
              <span className="bold-time">
                {totalTimeDuration
                  ? String(convertHMS(totalTimeDuration)).replace(
                      /\d{1,3}(?=(\d{3})+(?!\d))/g,
                      "$&,"
                    )
                  : "00:00:00"}
              </span>
            </p>
          </div>
          {tabButtons && (
            <table className="table">
              <tbody>
                <tr>
                  <th scope="col">Developer Name</th>
                  <th scope="col" className="text-center">
                    Time Spent
                  </th>
                  <th scope="col" className="text-center">
                    Estimated Hours
                  </th>
                </tr>

                {report.map(
                  (report,index) => {
                    return (
                      <tr key={index}>
                        <td>{report.name}</td>
                        <td className="text-center">
                          {convertHMS(report.date_of_joining)}
                        </td>
                        <td className="text-center">-</td>
                      </tr>
                    );
                  }
                )}
                {report.length === 0 && (
                  <tr className="no-record-found">
                    <td align="center" colSpan={3}>
                      No Record Found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
        {tabButtons === false && (
          <div className="graph-view col-md-8 m-auto">
            <Bar data={graphData} options={options} height={500} />
          </div>
        )}
      </div>
      <LoadingBar
        color="black"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
        loaderSpeed={3000}
        waitingTime={1000}
      />
    </div>
  );
};

export default DevReport;
