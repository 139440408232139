const initialState = {
    apiUpdate: false,
  };
  
  const UpadateDashboard = (state = initialState, action) => {
    switch (action.type) {
      case "UPDATE_DASHBOARD_UPDATE":
        return (state = action.payload);
      default:
        return state;
    }
  };
  
  export default UpadateDashboard;
  