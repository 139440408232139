import React, { useState, useRef, useEffect } from "react";
import "./Dashboard.scss";
import useApi from "../services/api";
import { useNavigate } from "react-router-dom";
import ContentEditable from "react-contenteditable";
import LoadingBar from "react-top-loading-bar";

export default function DashboardDescription({ desc, timerID }) {
  const api = useApi();
  const [progress, setProgress] = useState(0);
  const [inputval, setInputVal] = useState(desc);
  const history = useNavigate();

  useEffect(() => {
    setInputVal(desc);
    // console.log(desc, timerID, "hey")
  }, [desc]);

  const text = useRef("");

  const handleChange = (evt) => {
    text.current = evt.target.value;
    setInputVal(evt.target.value);

  };

  const handleBlur = (id, description) => {
    setProgress(80);
    window.localStorage.setItem("descriptionApi", description);
    // console.log(window.localStorage.getItem("descriptionApi"), "yes", text.current)
    api
      .patch(
        "https://api.trackify.ai/timer/updateDescription",
        text.current.length === 0
          ? {
              description: window.localStorage.getItem("descriptionApi"),
              timerID: id,
            }
          : { description: text.current, timerID: id }
      )
      .then(
        (response) => {
          setProgress(100);
        },
        (error) => {
          setProgress(100);
          if (error.response.status === 401) {
            window.localStorage.clear();
            history("/");
          }
        }
      );
  };

  return (
    <div>
      <ContentEditable
        html={inputval}
        onBlur={() => handleBlur(timerID, desc)}
        onChange={handleChange}
        className="input-timer-1"
      />

      <LoadingBar
        color="black"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
        loaderSpeed={3000}
        waitingTime={1000}
      />
    </div>
  );
}
