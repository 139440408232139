import React, { useState, useEffect } from "react";
// import "./Signup.css";
import "./Signup.css";
import { useNavigate } from "react-router-dom";
import { CircularProgress, LinearProgress, Input } from "@mui/material";
import { Email, Lock } from "@mui/icons-material";
import Logo from "../../assets/images/logo/logo.png"
// import { Signup } from "../Signup/Signup";
import image from "../../assets/BG/email.svg";
export function Signup() {
  //   const [isLoginFailed, setIsLoginFailed] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const history = useNavigate();
  const [isSignupFail, setIsSignupFail] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  var passwordValidator = require("password-validator");
  var validator = require('validator');


  //   const token = window.localStorage.getItem("access_token");
  var schema = new passwordValidator();
  schema
    .is()
    .min(6) // Minimum length 8
    .is()
    .max(100) // Maximum length 100
    // .has().uppercase()                              // Must have uppercase letters
    // .has().lowercase()                              // Must have lowercase letters
    // .has().digits(2)                                // Must have at least 2 digits
    .has()
    .not()
    .spaces();

  const temp = email.split("@");
  //  console.log(temp);
  const name = temp[0];

  const item = {
    email,
    password,
    name,
  };

  const handleLogin = (e) => {
    e.preventDefault();
    setLoading(true);
    setIsError(false);
    seterrorMessage("");
    // console.log(schema.validate(password, { details: true }), "validator");
    // console.log(validator.isEmail(email)) 


    if (!(email && password)) {
      // console.log("provide Email and password")
      setIsError(true);
      seterrorMessage("Please Provide Email and Password both for SignUp.");
      // alert("Please Provide Email and Password both for SignUp.")
      setLoading(false);
    }else if(validator.isEmail(email) === false){
      setIsError(true);
      seterrorMessage("Please provide a valid Email.");
      // alert("Password length should not be less than 6 digits.")
      setLoading(false);
    } 
     else if (password.length <= 5) {
      setIsError(true);
      seterrorMessage("Password length should not be less than 6 digits.");
      // alert("Password length should not be less than 6 digits.")
      setLoading(false);
    } else if (password.includes(" ")) {
      setIsError(true);
      seterrorMessage("Empty spaces are not allowed in password.");
      // alert("Password length should not be less than 6 digits.")
      setLoading(false);
    } else
      fetch("https://api.trackify.ai/user/signup", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(item),
      })
        .then((response) => response.json())
        .then((result) => {
          // console.log(result);
          if (result.message === "Email already present") {
            setLoading(false);
            setIsError(true);
            seterrorMessage("Email is already present, try to Log in.");
            // alert("Email is already present, try to Log in.")
            // setIsSignupFail(true)
          } else {
            const body = {
              wkspcId: "909090",
              status: result.data.name,
              roleId: "no",
              workspaceType: "org",
              name: "gaurav ka wrkspace",
              wkspcName: result.data.name + "'s Workspace",
              token: result.token,
              theme_id: "64195b83b5e42affc1a86633",
            };
            fetch("https://api.trackify.ai/workspace/create", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(body),
            })
              .then((res) => res.json())
              .then((res) => {
                // console.log(res, "Workspace");
                localStorage.setItem(
                  "workspace",
                  JSON.stringify(res.workspace)
                );
                localStorage.setItem("workspace_id", res.workspace[0].wkspc_id);
              })
              .catch((err) => console.log(err, "Wrk error"));
            // alert("Signed Up successfully.")
            localStorage.setItem("tt-userinfo", JSON.stringify(result.data));
            window.localStorage.setItem("access_token", result.token);
            window.localStorage.setItem("startTimer", "false");
            setLoading(false);
            setTimeout(() => {
              history("/dashboard");
            }, 1000);
          }
          // console.log(result.message)
        })
        .catch((error) => {
          setLoading(false);
          console.error("Error:", error);
          // console.log(error)
        });
  };

  //   useEffect(() => {
  //     if (token) {
  //       history("/dashboard");
  //     } else {
  //       console.log("token expired");
  //     }
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, []);

  return (
    <div className="signupPage">
      <div className="leftDivSignup">
      <div style={{position:"absolute", top:"30px", left:"30px" }} >
        <img src={Logo} style={{width:"10%"}} alt="" />
        </div>
        <div className="craeteHeaderSignup">Create Account</div>

        <div className="errorDiv">
          {isError ? <p>{errorMessage}</p> : <h6>.</h6>}
        </div>

        <div className="inputDivSignup">
          <span
            style={{ border: isError ? "3px solid red" : "none" }}
            className="InputBoxSignup"
          >
            <div className="CirlceSignup">
              <Email />
            </div>
            <input
              placeholder="Email"
              className="InputBoxSignup2"
              type="email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </span>
          <span
            style={{ border: isError ? "3px solid red" : "none" }}
            className="InputBoxSignup"
          >
            <div className="CirlceSignup">
              <Lock />
            </div>
            <input
              placeholder="Password"
              className="InputBoxSignup2"
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              name=""
              id=""
            />
          </span>
        </div>
        <div className="signUpButtonSignup" onClick={handleLogin}>
          {loading ? <CircularProgress color="inherit" /> : "Sign Up"}
        </div>
        <div className="loginOptionSignup">
          Already have an account ?{" "}
          <a className="loginTag" href="/#/">
            Log In
          </a>
        </div>
      </div>
      <div className="rightDivSignup">
        {/* <div>hey</div> */}
        <div className="centerDivSignup">
          <div className="welcomeHeadingSignup">Welcome Back!</div>
          <div className="dialogeHeadingSignup">
            Track and Manage your time on one platform{" "}
          </div>
          <div
            className="signinButtonSignup"
            onClick={() => {
              history("/");
            }}
          >
            <a className="aTag" href="/#/">
              Sign In
            </a>
          </div>
        </div>
      </div>
      {/* hey whatsup */}
    </div>
    // <div className="signup">
    //   <div className="login__data">
    //   <h1 className="form-signin-heading heading" >
    //     <span className="pre-head">Time</span>
    //       <span className="login__headingHead">Tracker</span>
    //     </h1>
    //     <h3 className="form-signin-heading">
    //     <span className="login__headingHead">Signup</span>
    //       <span className="login__headingHead"> &nbsp;Page</span>
    //     </h3>
    //     {/* {isSignupFail && (
    //       <>
    //         <div className="alert  alert-danger">SignUp Failed</div>
    //       </>
    //     )} */}

    //     <form className="login__form" onSubmit={handleLogin}>
    //       <input
    //         type="email"
    //         className="form-input"
    //         id="email"
    //         placeholder="Enter Email"
    //         onChange={(e) => setEmail(e.target.value)}
    //       />
    //       <input
    //         type="password"
    //         className="form-input"
    //         id="password"
    //         placeholder="Create a Password"
    //         onChange={(e) => setPassword(e.target.value)}
    //       />
    //       {isSignupFail && (
    //       <>
    //         <div className="alert  alert-danger">SignUp Failed</div>
    //       </>
    //     )}
    //       <button className="login__button" type="submit">
    //         {loading ? <CircularProgress color="inherit" /> : "Sign Up"}
    //       </button>
    //       {/* <Signup/> */}
    //     </form>
    //     <div className="already-user" >
    //         <p>If already an user , </p> <span className="a-tag" >
    //             <a className="a-tag" href="/">&nbsp; Login Here</a></span>
    //     </div>
    //   </div>
    // </div>
  );
}

// export default Login;
