import React, { createContext, useState } from "react";

const TextColorContext = createContext();

const TextColorProvider = (props) => {
  const [PrimaryTextColor, setPrimaryTextColor] = useState("");

  return (
    <>
      <TextColorContext.Provider
        value={[PrimaryTextColor, setPrimaryTextColor]}
      >
        {props.children}
      </TextColorContext.Provider>
    </>
  );
};

export { TextColorContext };
export { TextColorProvider };
