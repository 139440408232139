import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { HashRouter } from "react-router-dom";
import Store from "./Store";
import { Provider } from "react-redux";
import { DataProvider } from "./context/DataContext";
import { ColorProvider } from "./context/ColorContext";
import { TextColorProvider } from "./context/TextColorContext";
import { TextHoverProvider } from "./context/TextHoverContext";
import { TimerProvider } from "./context/TimerContext";
import { NewLeaveProvider } from "./context/NewLeaveContext";
import { ProjectProvider } from "./context/ProjectContext";
import { ShowEditorProvider } from "./context/ShowEditorContext";

ReactDOM.render(
  <HashRouter>
    <React.StrictMode>
      <Provider store={Store}>
        <DataProvider>
          <ColorProvider>
            <TextColorProvider>
              <TextHoverProvider>
                <TimerProvider>
                  <NewLeaveProvider>
                    <ProjectProvider>
                      <ShowEditorProvider>
                      <App />
                      </ShowEditorProvider>
                    </ProjectProvider>
                  </NewLeaveProvider>
                </TimerProvider>
              </TextHoverProvider>
            </TextColorProvider>
          </ColorProvider>
        </DataProvider>
      </Provider>
    </React.StrictMode>
  </HashRouter>,
  document.getElementById("root")
);

reportWebVitals();
