import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import "./UpdateLeaveType.scss";
import EditIcon from "../../../assets/images/icons/edit.png";
import AppsIcon from "@mui/icons-material/Apps";
import DatePicker from "react-datepicker";
import moment from "moment";
import { useForm } from "react-hook-form";
import useApi from "../../../Component/services/api";
import LoadingBar from "react-top-loading-bar";
import { useNavigate } from "react-router-dom";
import { ColorContext } from "../../../context/ColorContext";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          style={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function UpdateLeaveType({
  leave,
  getAllLeave,
}) {
  const api = useApi();
  const [open, setOpen] = React.useState(false);
  const [applyDate, setapplyDate] = useState("");
  const [progress, setProgress] = useState(0);
  const history = useNavigate();
  const [secondaryColor] = useContext(ColorContext);

  useEffect(() => {
    if (leave.applyDateFrom !== "0000-00-00") {
      if (leave.applyDateFrom !== null) {
        setapplyDate(new Date(leave.applyDateFrom));
      }
    }
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = (data) => {
    // console.log(data);
    setProgress(80);
    api
      .put("v1/leaveType/", {
        applyDateFrom: moment(applyDate).format("YYYY-MM-DD"),
        applyDays: data.applyDays,
        description: data.description,
        leaveTypeId: leave.leaveTypeId,
        leaveTypeName: data.leaveType,
      })
      .then(
        (response) => {
          // console.log(response);
          handleClose();
          getAllLeave();
          setProgress(100);
        },
        (error) => {
          console.log(error);
          setProgress(100);

          if (error.response.status === 401) {
            window.localStorage.clear();
            history("/");
          }
        }
      );
  };

  return (
    <div>
      <div onClick={handleClickOpen} style={{ cursor: "pointer" }}>
        <img
          src={EditIcon}
          alt="edit"
          width="25"
          style={{
            cursor: "pointer",
          }}
        />
      </div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        className="update-leave-type"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <span style={{ color: `${secondaryColor}` }}>Update Leave Type</span>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <form className="form-width" onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-3">
              <div className="flex-input w-100">
                <div className="w-100">
                  <input
                    type="text"
                    placeholder="Leave Type"
                    className="form-control disable-input"
                    defaultValue={leave.leaveTypeName}
                    {...register("leaveType", { required: true })}
                  />
                </div>
              </div>
              {errors.leaveType && errors.leaveType.type === "required" && (
                <span style={{ color: "red" }}>
                  Leave Type can not be blank
                </span>
              )}
            </div>

            <div className="mb-3">
              <div className="flex-input w-100">
                <div className="w-100">
                  <input
                    type="text"
                    placeholder="Apply Days"
                    className="form-control disable-input"
                    defaultValue={leave.applyDays}
                    {...register("applyDays", { required: true })}
                  />
                </div>
              </div>
              {errors.applyDays && errors.applyDays.type === "required" && (
                <span style={{ color: "red" }}>
                  Apply Days can not be blank
                </span>
              )}
            </div>

            <div className="mb-3">
              <div className="flex-input w-100">
                <div className="w-100">
                  {/* <input
                    type="date"
                    className="form-control date-input w-100"
                    onChange={(e) => {
                      setJoiningDate(e.target.value);
                    }}
                  /> */}

                  <DatePicker
                    selected={applyDate}
                    className="date-input w-100"
                    onChange={(date) => setapplyDate(date)}
                  />

                  <input
                    type="text"
                    className="form-control disable-input"
                    disabled
                    placeholder="Apply From"
                    value={applyDate && moment(applyDate).format("YYYY-MM-DD")}
                  />
                </div>
                <AppsIcon className="date-icon" />
              </div>
            </div>

            <div className="mb-3">
              <textarea
                className="form-control"
                rows={4}
                placeholder="Description"
                defaultValue={leave.description}
                {...register("description", { required: true })}
              />
              <div>
                {errors.description &&
                  errors.description.type === "required" && (
                    <span style={{ color: "red" }}>
                      Description can not be blank
                    </span>
                  )}
              </div>
            </div>

            <button
              type="submit"
              className="btn submit-form"
              style={{ backgroundColor: `${secondaryColor}` }}
            >
              Update
            </button>
          </form>
        </DialogContent>
      </BootstrapDialog>

      <LoadingBar
        color="black"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
        loaderSpeed={3000}
        waitingTime={1000}
      />
    </div>
  );
}
