import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";


    


export const getAllProject = createAsyncThunk(
    "project/getall",
    async (name, thunkapi) => {
    const workspaceID = localStorage.getItem("workspace_id")
    const token  = localStorage.getItem("access_token")
    const userId = JSON.parse(localStorage.getItem("tt-userinfo"))
    let _id = ""
    if(userId){
       _id = userId._id
    }
      try {
        const res = await axios.get(`https://api.trackify.ai/projects/proj/${workspaceID}/${token}/${_id}`);
        // console.log(res, " res in redux ")
        const data = { data : res.data.projects, id:workspaceID }
        return data;
      } catch (e) {
        return thunkapi.rejectWithValue(e.message);
      }
    }
  );
export const createProject = createAsyncThunk(
    "project/create",
    async ({data,selectedUsers, selectedClient}, thunkapi) => {
      try {
        const token  = localStorage.getItem("access_token")
        const workspaceID = localStorage.getItem("workspace_id")
        // console.log(data,selectedUsers, "data in create")
        const res = await axios.post("https://api.trackify.ai/projects/projectcreated", {
          clientID: selectedClient,
          estimatedHours: data.TimeEstimation,
          // manageBy: data.ProjectManager,
          projectDescription: data.Description,
          projectName: data.ProjectTitle,
          team: selectedUsers,
          workspaceId: localStorage.getItem("workspace_id"),
          token
        })
        // console.log(data, "data create")
        // console.log(res, "res")
        const dataxy = {data : res.data, id : workspaceID}
        return dataxy
      } catch (e) {
        return thunkapi.rejectWithValue(e.message);
      }
    }
  );
  
  

  const initialState = {
    projects: [],
    test : {}
  };

const projectSlice = createSlice({
    name : "projectSlice",
    initialState,
    reducers : {
        // addProject(state, action){
        //     state.push(...action.payload)
        //     console.log(action.payload, "payload")
        // },
        // updateProject(state, action){}
    },
    extraReducers(builder){
        builder.addCase(getAllProject.fulfilled, (state, action)=>{
          // console.log(action.payload.id, "builder")
          const workspace_id = action.payload.id
          // const obj = {workspace_id : action.payload.data}
          // console.log(state.projects, "state projects")
          let existing = false;
          state.projects.map((el)=>{
            if(el.id == workspace_id){
              existing = true
            }
          })
          // console.log(existing, "state projects")
          // if(state.projects.includes(workspace_id)){
          //   console.log(workspace_id, "including")
          // }else{
          //   console.log("first not including")
          // }
          // if(!existing){
            let {data, id} = action.payload
            state.projects = [...state.projects, action.payload]
            state.test[id] = data
          // }
        })
        builder.addCase(createProject.fulfilled, (state, action)=>{
          // console.log(action.payload , "payload")
          // state.projects = [...state.projects, action.payload]
          const {id, data}  = action.payload
          state.test[id] = [...state.test[id], data]
          // state.projects.push(action.payload)

        })
    }
})

export const selectProject = (state)=> state.projects

// console.log(projectSlice.actions)
// export {projects}
export default projectSlice.reducer
 
export const {addProject} = projectSlice.actions