import React, { useState, useEffect } from "react";
// import "./Signup.css";
import "../ForgotPassword/ForgotPassword.css"
import { useNavigate } from "react-router-dom";
import useApi from "../services/api";
import { Email, Lock } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import Logo from "../../assets/images/logo/logo.png"
// import { Signup } from "../Signup/Signup";

export function ForgotPassWord() {
//   const [isLoginFailed, setIsLoginFailed] = useState(false);
  const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
  const history = useNavigate();
  const [isError, setIsError] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [loading, setLoading] = useState(false)
  const [isSignupFail, setIsSignupFail] = useState(false);

//   const token = window.localStorage.getItem("access_token");


   const temp = email.split("@")
  //  console.log(temp);
   const name = temp[0]


  const item = {
    email,
    // password,
    name
  };
  const api = useApi();

  const handelForgotPassword = (e)=>{
      e.preventDefault()
      setLoading(true)
      // console.log(email, "email")

    api.post("https://api.trackify.ai/profile/forgotpassword",{
        email
    }).then((res)=>{
        // console.log(res, "res")
        if(res.data == "user does not exist"){
          setLoading(false)
          setIsError(true)
          seterrorMessage("This email is not registered, Try to signup.")
          // alert("This email is not registered, Try to signup.")
        }else{
          setLoading(false)
          alert("Reset password link has been shared on this mail")
          history("/#/")
        }
    }).catch((err)=>{
      setLoading(false)
      console.log(err)
      alert("This email is not registered, Try to signup.")
    })

  }

  


  return (
    <div className="forgotPage" >
      <div className="leftDiv" >
      <div style={{position:"absolute", top:"30px", left:"30px" }} >
        <img src={Logo} style={{width:"10%"}} alt="" />
        </div>
        <div className="craeteHeader" >Forgot your password?</div>
        <div className="SmallDiagloge" >
        Don't worry, we can help you 
        </div>
        <div className="inputDiv" >
          <span style={{border:false ? "3px solid red" : "none"}} className="InputBox" >
            <div className="Cirlce" ><Email/></div>
            <input placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
            className="InputBox2" type="email" name="" id="" />
          </span>
          {/* <span style={{border:false ? "3px solid red" : "none"}} className="InputBox" >
            <div className="Cirlce" ><Lock/></div>
            <input placeholder="Password" className="InputBox2" type="text" name="" id="" />
          </span> */}
        </div>
        <div className="errorDivForgot" >
          {isError ? <p>{errorMessage}</p> : <h6>.</h6>}
        </div>  
        {/* <div className="forgotDiv" > */}
        {/* <a href="/#/forgot" className="forgotAtag" >Forgot Password?</a> */}
        {/* </div> */}
        <div className="signUpButton" onClick={handelForgotPassword} >
        {loading ? <CircularProgress color="inherit" /> : "Reset Password"}
        </div>
        <div className="loginOption" >New On Trackify ? <a className="loginTag" href="/#/signup">Sign Up</a></div>
      </div>
      <div className="rightDiv1">
        {/* <div>hey</div> */}
        <div className="centerDiv" >
        <div className="welcomeHeading" >Create Account</div>
        <div className="dialogeHeading" >Track and Manage your time on one platform </div>
        <div className="signinButton" onClick={()=>{history("/#/signup")}} ><a className="aTag" href="/#/signup">Sign Up</a></div>
        </div>
      </div>
      {/* hey whatsup */}
    </div>
    // <div className="signup">
    //   <div className="login__data">
    //   <h1 className="form-signin-heading heading" >
    //     <span className="pre-head">Time</span>
    //       <span className="login__headingHead">Tracker</span>
    //     </h1>
    //     <h3 className="form-signin-heading">
    //     <span className="login__headingHead">Forgot</span>
    //       <span className="login__headingHead"> &nbsp;Password?</span>
    //     </h3>
    //     {/* {isSignupFail && (
    //       <>
    //         <div className="alert  alert-danger">SignUp Failed</div>
    //       </>
    //     )} */}

    //     <form className="login__form" onSubmit={handelForgotPassword}>
    //       <input
    //         type="email"
    //         className="form-input"
    //         id="email"
    //         placeholder="Enter Email"
    //         onChange={(e) => setEmail(e.target.value)}
    //       />
    //       {/* <input
    //         type="password"
    //         className="form-input"
    //         id="password"
    //         placeholder="Create a Password"
    //         onChange={(e) => setPassword(e.target.value)}
    //       /> */}
    //       {isSignupFail && (
    //       <>
    //         <div className="alert  alert-danger">SignUp Failed</div>
    //       </>
    //     )}
    //       <button className="login__button" type="submit">
    //         Submit
    //       </button>
    //       {/* <Signup/> */}
    //     </form>
    //     <div className="already-user" >
    //         <span className="a-tag" >
    //             <a className="a-tag" href="/">&nbsp;Go Back</a></span>
    //     </div>
    //   </div>
    // </div>

  );
}

// export default Login;
