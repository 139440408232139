const DashboardApi = (data) => {
    return {
      type: "UPDATE_DASHBOARD_UPDATE",
      payload: data,
    };
  };
  
  const SingleProject = (data) => {
    return {
      type: "GET_SINGLE_PROJECT",
      payload: data,
    };
  };
  
  export { DashboardApi, SingleProject };
  