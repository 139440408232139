import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import "./UpdateUser.scss";
import EditIcon from "../../../assets/images/icons/edit.png";
import AppsIcon from "@mui/icons-material/Apps";
import useApi from "../../../Component/services/api";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import moment from "moment";
import LoadingBar from "react-top-loading-bar";
import { useNavigate } from "react-router-dom";
import { ColorContext } from "../../../context/ColorContext";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          style={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
function UpdateUser({ user, getAllUser }) {
  const api = useApi();
  const [open, setOpen] = useState(false);
  const [JoiningDate, setJoiningDate] = useState(null);
  const [progress, setProgress] = useState(0);
  const [secondaryColor] = useContext(ColorContext);
  const history = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    // if (user.joinDate !== "0000-00-00") {
    //   if (user.joinDate !== null) {
    //     setJoiningDate(new Date(user.joinDate));
    //   }
    // }
    setJoiningDate(new Date(user.joinDate));
    // console.log(user.joinDate, "in effect")
  }, []);

  const onSubmit = (data) => {
    // console.log(data);
    // console.log(moment(JoiningDate).format("YYYY-MM-DD"));
    setProgress(80);
    api
      .patch("https://api.trackify.ai/user/userUpdate", {
        email: user.email,
        joinDate: moment(JoiningDate).format("MM/DD/YYYY"),
        name: data.name,
        role: data.role,
        userID: user.userID,
        workspaceId : localStorage.getItem("workspace_id")
      })
      .then(
        (response) => {
          // console.log(response);
          handleClose();
          setProgress(100);
          getAllUser();
        },
        (error) => {
          console.log(error);
          if (error.response.status === 401) {
            window.localStorage.clear();
            history("/");
          }
        }
      );
  };

  return (
    <div>
      <div onClick={handleClickOpen} style={{ cursor: "pointer" }}>
        <img
          src={EditIcon}
          alt="edit"
          width="25"
          style={{
            cursor: "pointer",
          }}
        />
      </div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        className="update-user"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <span style={{ color: `${secondaryColor}` }}> Update</span>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <form className="form-width" onSubmit={handleSubmit(onSubmit)}>
            {/* <div className="mb-3">
              <label className="form-label">Name:</label>
              <div className="flex-input w-100">
                <div className="w-100">
                  <input
                    type="text"
                    className="form-control disable-input"
                    defaultValue={user.name}
                    {...register("name", { required: true })}
                  />
                </div>
              </div>
              {errors.name && errors.name.type === "required" && (
                <span style={{ color: "red" }}>Name can not be blank</span>
              )}
            </div> */}

            <div className="mb-3">
              <label className="form-label">Email:</label>
              <input
                type="text"
                className="form-control days-input"
                value={user.email}
                disabled
              />
            </div>

            <div className="mb-3">
              <label className="form-label">Role:</label>

              <select
                className="form-select"
                aria-label="Default select example"
                {...register("role")}
              >
                {user.role === "" && <option value="">Select Role</option>}
                <option
                  value="admin"
                  // selected={user.role === "admin" ? true : false}
                >
                  Admin
                </option>
                {/* <option
                  value="manager"
                  // selected={user.role === "manager" ? true : false}
                >
                  Manager
                </option> */}
                <option
                  value="user"
                  // selected={user.role === "user" ? true : false}
                >
                  User
                </option>
              </select>
            </div>

            <div className="mb-3">
              <label className="form-label">Date From:</label>
              <div className="flex-input w-100">
                <div className="w-100">
                  {/* <input
                    type="date"
                    className="form-control date-input w-100"
                    onChange={(e) => {
                      setJoiningDate(e.target.value);
                    }}
                  /> */}
                  <DatePicker
                    selected={JoiningDate}
                    className="date-input w-100"
                    onChange={(date) => setJoiningDate(date)}
                  />

                  <input
                    type="text"
                    className="form-control disable-input"
                    disabled
                    value={
                      JoiningDate && moment(JoiningDate).format("MM/DD/YYYY")
                    }
                  />
                </div>
                <AppsIcon className="date-icon" />
              </div>
            </div>

            <button
              type="submit"
              className="btn submit-form"
              style={{ backgroundColor: `${secondaryColor}` }}
            >
              Update
            </button>
          </form>
        </DialogContent>
      </BootstrapDialog>
      <LoadingBar
        color="black"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
        loaderSpeed={3000}
        waitingTime={1000}
      />
    </div>
  );
}

export default React.memo(UpdateUser);
