import React, { useState, useEffect, useContext } from "react";
import "../summary-report/Summary.scss";
import NewLeaveReportFilter from "../../Views/report/ReportFilter/NewLeaveReportFilter";
import LoadingBar from "react-top-loading-bar";
import moment from "moment";
import useApi from "../services/api";
import { toast } from "react-toastify";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

import { useNavigate } from "react-router-dom";
import GenerateIcon from "../../assets/images/icons/Generate_Report_Icon.gif";
import { NewLeaveContext } from "../../context/NewLeaveContext";
import { ColorContext } from "../../context/ColorContext";

ChartJS.register(ArcElement, Tooltip, Legend);
const SummaryReport = () => {
  const api = useApi();
  const [tabButtons, settabButtons] = useState(true);
  const [report, setReport] = useState([]);
  const history = useNavigate();
  const [startDate, setstartDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, setendDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const currentYear = new Date().getFullYear();
  const [currentMonth, setCurrentMonth] = useState(
    (new Date().getMonth() + 1).toString().padStart(2, "0")
  );
  const [getClient, setgetClient] = useState([]);
  const [getAllUser, setgetAllUser] = useState([]);
  const [getAllMonth, setgetAllMonth] = useState([]);
  const [monthFilter, setMonthFilter] = useState(
    `${currentYear}-${currentMonth}`
  );
  const [getAllProjectId, setgetAllProjectId] = useState([]);
  const [totalTimeDuration, settotalTimeDuration] = useState(null);
  const [progress, setProgress] = useState(0);
  const [newLeaveData, setNewLeaveData] = useContext(NewLeaveContext);
  const [secondaryColor] = useContext(ColorContext);

  const reportApiurl = "v1/summaryReports/";

  useEffect(() => {
    window.localStorage.setItem(
      "monthFilterApi",
      `${currentYear}-${currentMonth}`
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getLeaveData() {
    setProgress(80);
    let monthFilterApi = window.localStorage.getItem("monthFilterApi");
    api.get(`v1/leaveBalanceReport?month=${monthFilterApi}`).then(
      (response) => {
        // console.log(response.data);
        setProgress(100);
        setNewLeaveData(response.data);
      },
      (error) => {
        // console.log(error);
        setProgress(100);
        if (error.response.status === 401) {
          window.localStorage.clear();
          history("/");
        }
      }
    );
  }

  useEffect(() => {
    getLeaveData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleGenrateData() {
    setProgress(80);
    let monthFilterApi = window.localStorage.getItem("monthFilterApi");
    var monthforapi = monthFilterApi?.substring(5, 7);
    var yearforapi = monthFilterApi?.substring(0, 4);
    var firstDayMonth = new Date(yearforapi, monthforapi - 1, 1);
    var lastDayMonth = new Date(yearforapi, monthforapi, 0);

    var firstDayApi = moment(firstDayMonth).format("YYYY-MM-DD");
    var lastDayApi = moment(lastDayMonth).format("YYYY-MM-DD");

    api
      .post(`v1/generateLeaveBalance/`, {
        fromDate: firstDayApi,
        toDate: lastDayApi,
      })
      .then(
        (response) => {
          setProgress(100);
          if (response.data.status === "failed") {
            toast.error(response.data.message, {
              theme: "colored",
              position: "top-left",
            });
          } else {
            getLeaveData();
          }
        },
        (error) => {
          console.log(error);
          setProgress(100);
          if (error.response.status === 401) {
            window.localStorage.clear();
            history("/");
          }
        }
      );
  }

  function timeStringToFloat(time) {
    var hoursMinutes = time.split(/[.:]/);
    var hours = parseInt(hoursMinutes[0], 10);
    var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
    return hours + minutes / 60;
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
      },
    },
  };

  return (
    <div className="summary-report">
      <div className="box-header">
        <div className="page-title" style={{ color: `${secondaryColor}` }}>
          Leave Management
        </div>
      </div>
      <NewLeaveReportFilter
        getAllProjectId={getAllProjectId}
        getClient={getClient}
        getAllUser={getAllUser}
        startDate={startDate}
        setstartDate={setstartDate}
        endDate={endDate}
        setendDate={setendDate}
        setReport={setReport}
        settotalTimeDuration={settotalTimeDuration}
        setgetAllProjectId={setgetAllProjectId}
        setgetClient={setgetClient}
        setgetAllUser={setgetAllUser}
        setProgress={setProgress}
        getAllMonth={getAllMonth}
        setgetAllMonth={setgetAllMonth}
        stateChangerMonthFilter={setMonthFilter}
        reportApiurl={reportApiurl}
        NotShowRangePicker={true}
      />

      {newLeaveData.length === 0 ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "6%",
            marginLeft: "-5%",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={GenerateIcon}
              alt="loading..."
              className="leave__generateIcon"
            />
            <div onClick={handleGenrateData}>
              <div className="arrow-right"></div>
              <div className="middle-div">
                <span>Generate</span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="middle-content"
          style={{ overflow: "auto", marginTop: "2rem" }}
        >
          <div className="table-content">
            {tabButtons && (
              <table className="table">
                <tbody>
                  <tr>
                    <th scope="col">Developer Name</th>
                    <th scope="col" className="text-center">
                      Hours Worked
                    </th>
                    <th scope="col" className="text-center">
                      Day Worked
                    </th>
                    <th scope="col" className="text-center">
                      Leave Added
                    </th>
                    <th scope="col" className="text-center">
                      LOP
                    </th>
                    <th scope="col" className="text-center">
                      Leave Balance
                    </th>
                  </tr>

                  {newLeaveData.map((report, index) => {
                    return (
                      <tr key={index}>
                        <td>{report.name}</td>
                        <td style={{ textAlign: "center" }}>
                          {report.hours_worked}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {report.days_worked}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {report.leave_added_taken}
                        </td>
                        <td style={{ textAlign: "center" }}>{report.lop}</td>
                        <td style={{ textAlign: "center" }}>
                          {report.cur_leave_balance}
                        </td>
                      </tr>
                    );
                  })}
                  {newLeaveData.length === 0 && (
                    <tr className="no-record-found">
                      <td align="center" colSpan={3}>
                        No Record Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
          </div>
        </div>
      )}

      <LoadingBar
        color="black"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
        loaderSpeed={3000}
        waitingTime={1000}
      />
    </div>
  );
};

export default SummaryReport;
