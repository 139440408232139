import { useState, useContext, useEffect } from "react";
import "./Dashboard.scss";
import useApi from "../../Component/services/api";
import { DataContext } from "../../context/DataContext";
import { ColorContext } from "../../context/ColorContext";
import LoadingBar from "react-top-loading-bar";
import { TextColorContext } from "../../context/TextColorContext";
import { TextHoverContext } from "../../context/TextHoverContext";
import { ShowEditorContext } from "../../context/ShowEditorContext";
import { useDispatch, useSelector } from "react-redux";
import { activateTimerEditor, deactivateTimerEditor, getTheme, updateTheme } from "../../Reducers/dashboardSlice";


function DashboardThemeModal({ value, color, name, showSlider }) {
  const api = useApi();
  const [primaryColor, setPrimaryColor] = useContext(DataContext);
  const [secondaryColor, setSecondaryColor] = useContext(ColorContext);
  const [PrimaryTextColor, setPrimaryTextColor] = useContext(TextColorContext);
  const [PrimaryTextHover, setPrimaryTextHover] = useContext(TextHoverContext);
  const [showEditor, setShowEditor] = useContext(ShowEditorContext);
  const [ThemeID, setThemeID] = useState(null);
  const [themeToggel, setThemeToggel] = useState(false);
  const dispatch = useDispatch()

  const [timerEditActive, setTimerEditActive] = useState(showEditor);
  const [progress, setProgress] = useState(0);
  // console.log(showEditor , "show editor")

  
  // useEffect(()=>{
  //   getColorThemeAdmin()
  // }, [])
  const themeStateData = useSelector((state)=>{
    // return state.dashboards.theme
    return state.dashboards.test[localStorage.getItem("workspace_id")]
  })
  let individualWorkspaceClient = useSelector((state)=>{
    return state.dashboards.test[localStorage.getItem("workspace_id")]
  })
  // console.log(individualWorkspaceClient, "individual")

  useEffect(()=>{
    setTimerEditActive(showEditor)
    // console.log(showEditor, "here editor")
  },[showEditor])
  
  useEffect(()=>{
    setThemeID(localStorage.getItem("themeIDApi"))
  },[localStorage.getItem("themeIDApi")])

  function getColorThemeAdmin() {
    // setProgress(80);
    
    const workspace_id = localStorage.getItem("workspace_id");
    // console.log(workspace_id, "here i am stuck")
    api.get(`https://api.trackify.ai/color/colorsetting/${workspace_id}`).then(
      (response) => {
        // console.log(response.data[0].id, "get res")
        // console.log(response)
        setThemeID(response.data[0].id)
        response.data.map((value) => {
          // value.selected === 1 &&
            window.localStorage.setItem(
              "primary_color_theme",
              value.primaryColor
            );
          setPrimaryColor(value.primaryColor);
          // value.selected === 1 &&
            window.localStorage.setItem(
              "secondary_color_theme",
              value.secondaryColor
            );
          setSecondaryColor(value.secondaryColor);
          // value.selected === 1 &&
            window.localStorage.setItem("text_color_theme", value.textColor);
          setPrimaryTextColor(value.textColor);
          // value.selected === 1 &&
            window.localStorage.setItem(
              "textHover_color_theme",
              value.textHover
            );
          setPrimaryTextHover(value.textHover);
        });
        // setProgress(100);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  

  useEffect(()=>{
    // console.log(themeStateData, "themestateData")
    if( themeStateData && themeStateData.length !== 0){
      setThemeID(themeStateData.id)
        // themeStateData.map((value) => {
          // value.selected === 1 &&
            window.localStorage.setItem(
              "primary_color_theme",
              themeStateData.primaryColor
            );
          setPrimaryColor(themeStateData.primaryColor);
          // value.selected === 1 &&
            window.localStorage.setItem(
              "secondary_color_theme",
              themeStateData.secondaryColor
            );
          setSecondaryColor(themeStateData.secondaryColor);
          // value.selected === 1 &&
            window.localStorage.setItem("text_color_theme", themeStateData.textColor);
          setPrimaryTextColor(themeStateData.textColor);
          // value.selected === 1 &&
            window.localStorage.setItem(
              "textHover_color_theme",
              themeStateData.textHover
            );
          setPrimaryTextHover(themeStateData.textHover);
        // });
      // console.log(themeStateData, "themestateData")
    }else{
      dispatch(getTheme())
    }

  }, [themeStateData])
  function handleColorThemeChange(id) {
    window.localStorage.setItem("themeIDApi", id);
    dispatch(updateTheme(id))
    const workspace_id = localStorage.getItem("workspace_id")
    api
      .put("https://api.trackify.ai/color/colorsetting", {
        id: id,
        // id: window.localStorage.getItem("themeIDApi"),
        wkspcID: workspace_id
      })
      .then(
        (response) => {
          // console.log(response, "response of theme");
          // if (response.data.status === "success") {
            getColorThemeAdmin();
          // }
        },
        (error) => {
          console.log(error.response);
        }
      );
  }

  function makeEditActive() {
    dispatch(activateTimerEditor())
    // api.post("https://api.trackify.ai/setting/activateTimeEditor",{
    //   workspaceId : localStorage.getItem("workspace_id")
    // }).then(
    //   (response) => {
    //     console.log(response, "Editor")
    //     setShowEditor(response.data.editable);
    //     setTimerEditActive(showEditor);
    //   },
    //   (error) => {
    //     console.log(error);
    //   }
    //   );
    }
    
    function makeEditdisActive() {
      dispatch(deactivateTimerEditor())
    //   api.post("https://api.trackify.ai/setting/deactivateTimeEditor",{
    //     workspaceId : localStorage.getItem("workspace_id")
    //   }).then(
    //     (response) => {
    //     console.log(response, "Editor")
    //     // setShowEditor(response.data.editable);
    //     // setTimerEditActive(showEditor);
    //     setShowEditor(false);
    //     setTimerEditActive(false);
    //   },
    //   (error) => {
    //     console.log(error);
    //   }
    // );
    // setShowEditor(false);
    // setTimerEditActive(showEditor);
  }

  // var checkedState = window.localStorage.getItem("themeIDApi");
  // if(!checkedState){
  //   checkedState = ThemeID
  // }
  var checkedId = parseInt(ThemeID, 10);

  // const handelThemeToggel  = ()=>{
  //   console.log(themeToggel)
  //   setThemeToggel(!themeToggel)
  // }

  return (
    <>
      <div className="dashboard__modal__container">
        <label
          className="radio"
          style={{ display: "flex", alignItems: "center" }}
          >
          <input
            type="radio"
            id="colorTheme"
            name="colorTheme"
            value={value}
            checked={checkedId === value}
            className="radio__input"
            // onChange={(e) => handleColorThemeChange(e.target.value)}
            onChange={(e) => dispatch(updateTheme(e.target.value))}
          />
          <div className="radio__radio"></div>

          <div style={{ display: "flex", alignItems: "center" }}>
            <input
              type="color"
              value={color}
              className="dashboard__modal__input"
              disabled
            />
            <p className="dashboard__modal__text">
              <span className="dashboard__modal__textValue">{name}</span>
            </p>
          </div>
        </label>

        <LoadingBar
          color="black"
          progress={progress}
          onLoaderFinished={() => setProgress(0)}
          loaderSpeed={3000}
          waitingTime={1000}
        />
      </div>
    {/* <List>Theme</List> */}
    
      
      {showSlider && (
        <div className="enableTimerEditContainer">
          <p>Timer Editor</p>
          <label className="switch">
            <input
              type="checkbox"
              checked={timerEditActive === true ? true : false}
              onChange={(e) => {
                // console.log(e.target.checked);
                if (e.target.checked === true) {
                  makeEditActive();
                } else if (e.target.checked === false) {
                  makeEditdisActive();
                }
              }}
            />
            <span className="slider round"></span>
          </label>
        </div>
      )}
    </>
  );
}

export default DashboardThemeModal;
