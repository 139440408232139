import React, { useState, useEffect } from "react";
import Popover from "@mui/material/Popover";
import $ from "jquery";
// import { listType, projectMenuProps } from "./ProjectMenu.types";

const ProjectMenu = ({
  ProjectList,
  getAllProjectId,
  setgetAllProjectId,
  setClient,
  client,
  ClientList,
  userList,
  setUser
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [list, setList] = useState([]);
  const [checkList, setcheckList] = useState([]);
  const [selectDeveloper, setselectDeveloper] = useState(false);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const [inputValue, setinputValue] = useState("");
  const [allChecked, setAllChecked] = useState(false)
  const [array, setArray] = useState([])
  const [userArray, setUserArray] = useState([])

  const handleClose = () => {
    setAnchorEl(null);
  };
  // console.log(allChecked)

  useEffect(() => {
    ProjectList.forEach((element) => {
      element.check = false;
    });
    setcheckList(ProjectList);
    setList(ProjectList);
    setselectDeveloper(false);
    setAllChecked(false)
  }, [ProjectList]);

  const filterData = (value) => {
    const lowercasedValue = value.toLowerCase().trim();

    const filteredData = ProjectList.filter((item) => {
      return Object.keys(item).some((key) =>
        item[key].toString().toLowerCase().includes(lowercasedValue)
      );
    });

    setList(() => {
      return [...filteredData];
    });
  };

  useEffect(() => {
    if (selectDeveloper === true) {
      // $(".developerCheck").prop("disabled", true);
      checkList.forEach((element) => {
        element.check = true;
      });
      // console.log(checkList, "checklist")
      // setcheckList(() => {
      //   // if(checkList.length === ProjectList.length){
      //   //   console.log("yes true")
      //   //   console.log(checkList, "in if")
      //   //   console.log(list, "list")
      //   // }
      //   return [...checkList];
      // });

      const newList = list.map((project) => {
        return project._id;
      });
      setgetAllProjectId(newList);
      // console.log("ALL ALL")
    } else {
      // $(".developerCheck").prop("disabled", false);
      // checkList.forEach((element) => {
      //   element.check = false;
      // });
      // console.log(ProjectList, "project list");
      let flag = true
      // console.log(checkList,"xyz")
      checkList.forEach((element)=>{
        if(element.check == false){
          flag = false
        }
      })
      if(flag){
        checkList.forEach((element) => {
            element.check = false;
          });
          setcheckList(() => {
            if(checkList.length === ProjectList.length){
              // console.log("yes true")
              // console.log(checkList, "in else")
            }
            return [...checkList];
          });
          setgetAllProjectId([]);
      }else if(getAllProjectId.length == 0 ){
        // console.log(checkList, "hkhk")
        
       const newList =  checkList.filter((el)=>{
          return el.check === true
        })
        // console.log(newList, "after")
        const data = newList.map((project) => {
          return project._id;
        });
        setgetAllProjectId(data);
        // console.log(getAllProjectId, "list in else")
      }

      // if(checkList.length !== ProjectList.length && getAllProjectId.length !== 0){
      //   console.log("This is the scenario")
      // }

    }
  }, [selectDeveloper, setgetAllProjectId, list]);

  useEffect(() => {
    if (getAllProjectId.length >= 1) {
      setinputValue(`${getAllProjectId.length} Selected`);
    } else if (getAllProjectId.length === 0) {
      filterData("");
      setinputValue("");
    }
  }, [getAllProjectId.length]);

  return (
    <div className="w-100 me-3">
      <div className="inner-Input">
        <input
          type="text"
          className="form-control"
          placeholder={"Select Project"}
          value={inputValue}
          aria-describedby={id}
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
          }}
          onChange={(event) => {
            if (getAllProjectId.length <= 0) {
              filterData(event.target.value);
              setinputValue(event.target.value);
            }
          }}
        />
        <i className="fas fa-caret-down"></i>
      </div>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        disableAutoFocus={true}
        disableEnforceFocus={true}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        className="devSearch"
      >
        <ul className="project-list">
          <li>
            <label className="container">
              {/* Select All */}
              Select All
              <input
                type="checkbox"
                onChange={() => {
                  setselectDeveloper(!selectDeveloper);
                  setAllChecked(!allChecked)
                  setClient(ClientList)
                  setUser(userList)
                }}
                checked = {allChecked}
              />
              <span className="checkmark"></span>
            </label>
          </li>
          {list.map(
            (
              listData,
              index
            ) => (
              <li key={index}>
                <label className="container m-0">
                  {listData.projectName}
                  <input
                    type="checkbox"
                    className="developerCheck"
                    checked={checkList[index].check}
                    onChange={(e) => {
                      if (e.target.checked === true) {
                        // console.log(e.target,listData, "i am")
                        // console.log(userList, "all users")
                        // let tempClients = [...ClientList]
                        // let arr = []
                          ClientList.forEach((el)=>{
                          if(el._id == listData.clientID){
                            //  arr.push(el)
                            setArray(()=>{
                              return [...array, el]
                              // return [el]
                            })
                            // console.log(client,array, "clientss")
                            setClient([...array, el])
                            // console.log(array, "array")
                            // return el._id == listData.clientID
                          }
                        })
                        const NewXyz = userArray;
                        
                        userList.forEach((el)=>{
                          if(listData.team.includes(el._id) && !NewXyz.includes(el)){
                            NewXyz.push(el);

                          setUserArray([el])
                          // console.log(el, "el")
                          // setUserArray([...userArray, el])
                          setUser(userArray)
                        }
                        // setUser(userArray)
                      })
                      // console.log(userArray,NewXyz, "UserArray2")
                      // console.log(ClientList,array, "newClients")
                        // setClient(array);
                        setgetAllProjectId((prev) => {
                          return [...prev, listData._id];
                        });
                        if(getAllProjectId.length === ProjectList.length-1){
                          setAllChecked(true)
                          setselectDeveloper(true);
                          setUser(userList)
                          setClient(ClientList)
                          setArray(ClientList)
                        }
                        checkList[index].check = !checkList[index].check;
                        setcheckList(() => {
                          // console.log(getAllProjectId, " chekcingf in singke")
                          // console.log(checkList, "also in single")
                          return [...checkList];
                        });
                      } else if (e.target.checked === false) {
                        var listArr = [...getAllProjectId];
                        var indexVal = listArr.indexOf(listData._id);
                        // console.log(listArr, "listarr")
                        // console.log(array, "aarry")
                        listArr.splice(indexVal, 1);
                        setgetAllProjectId(listArr);
                        // console.log(listArr, "haaha",ProjectList)
                        const updated = client.filter((el)=>{
                          return el._id != listData.clientID
                        })
                        // const NewXyz = userArray;

                        let userArrayAfterRemove = []
                        listArr.map((el)=>{
                          ProjectList.map((elem)=>{
                          if(elem._id === el){
                            userArrayAfterRemove.push(...elem.team)
                            // console.log("true get")
                          }
                          // console.log("hiihii", elem)
                          })
                          // console.log("yes yes", ProjectList, listArr)
                          // console.log(userArrayAfterRemove, "userArrayAfterRemove")
                      })
                      userList.forEach((el)=>{
                        if(userArrayAfterRemove.includes(el._id)){
                          // NewXyz.push(el);

                        setUserArray([el])
                        // console.log(el, "el")
                        // setUserArray([...userArray, el])
                        setUser([el])
                      }
                      // setUser(userArray)
                    })
                      // console.log(userArrayAfterRemove, "userarrayafter")
                        // console.log(updated, "updated")
                        setClient(updated)
                        setArray(updated)
                        // console.log(getAllProjectId, "i got it projects")
                        // ClientList.forEach((el)=>{
                        //   const updatedItems = client.indexOf(listData._id);
                        //   const data = client.splice(updatedItems, 1)
                        //   console.log(data, "data")
                        //   // setArray(()=>updatedItems)
                        //   console.log(client, "client")
                        //   // console.log(updatedItems, "update")
                        //   console.log(ClientList, 'cllist')
                        //   // setClient(()=>updatedItems)
                        // //   if(el._id == listData.clientID){
                        // //     //  arr.push(el)
                        // //     // setArray(()=>{
                        // //     //   return [...array, el]
                        // //     //   // return [el]
                        // //     // })
                        // //     console.log(el, "elelel")
                        // //     console.log(array, "array")
                        // //     // setClient([...array, el])
                        // //     // return el._id == listData.clientID
                        // //   }
                        // })

                        if(listArr.length === 0){
                          setAllChecked(false)
                          setselectDeveloper(false);
                          setClient(ClientList)
                        }else{
                          setAllChecked(false)
                          setselectDeveloper(false)
                        }

                        checkList[index].check = !checkList[index].check;
                        setcheckList(() => {
                          return [...checkList];
                        });
                      }
                    }}
                  />
                  <span className="checkmark"></span>
                </label>
              </li>
            )
          )}
        </ul>
      </Popover>
    </div>
  );
};

export default ProjectMenu;
